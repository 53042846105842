<template lang="">
    <div>
         <section class="sign-in-page">
            <div class="container sign-in-page-bg mt-5 p-0">
                <div class="row no-gutters">
                    <!-- descriptor slider -->
                    <div class="col-md-6 text-center">
                    <sliderComp />
                    </div>
                    <!-- descripto slider -->
                   <div class="col-md-6 position-relative">
                        <div class="sign-in-from">
                            <h1 class="mb-0">Sign Up</h1>
                            <form @submit.prevent="submitHandler" class="mt-4">
                                 <div v-if="message" class="alert alert-danger">{{ message }}</div>
                                 <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label for="exampleInputEmail1">First Name</label>
                                        <input type="text" v-model="form.firstName" class="form-control mb-0" id="exampleInputEmail1" placeholder="Your First Name">
                                    </div>
                                    <div class=" col form-group">
                                            <label for="exampleInputEmail2">Last Name</label>
                                            <input v-model="form.lastName" type="text" class="form-control mb-0" id="exampleInputEmail2" placeholder="Your Last Name">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col form-group">
                                        <label for="exampleInputEmail1">Email Address</label>
                                        <input type="email" v-model="form.email" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter Email">
                                    </div>
                                    <div class=" col form-group">
                                            <label for="exampleInputEmail2">Phone Number</label>
                                            <input v-model="form.msisdn" type="number" class="form-control mb-0" id="exampleInputEmail2" placeholder="Your Phone Number">
                                    </div>
                                </div>
                               <div class="row">
                                 <div class="col form-group">
                                    <label  for="exampleInputPassword1">Password</label>
                                    <input v-model="form.password" type="password" class="form-control mb-0" id="exampleInputPassword1" placeholder="Password">
                                </div>
                                <div class="col form-group">
                                    <label for="exampleInputPassword1">Confirm Password</label>
                                    <input v-model="form.confirmPassword" type="password" class="form-control mb-0" id="exampleInputPassword1" placeholder="Confirm Password">
                                </div>
                               </div>
                                <div class="d-inline-block w-100">
                                    <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">I accept <a href="#">Terms and Conditions</a></label>
                                    </div>
                                   
                                     <button
                                            type="submit"
                                            class="btn btn-primary float-right"
                                            :disabled="loading"
                                            >
                                            <span v-if="loading" class="spinner-border spinner-border-sm mr-2"></span>
                                            Sign Up
                                            </button>
                                </div>
                                <div class="sign-info">
                                    <span class="dark-color d-inline-block line-height-2">Already Have Account ? <router-link :to="{name : 'login'}"><a>Log In</a></router-link></span>
                                    <ul class="iq-social-media">
                                        <li><a href="#"><i class="ri-facebook-box-line"></i></a></li>
                                        <li><a href="#"><i class="ri-twitter-line"></i></a></li>
                                        <li><a href="#"><i class="ri-instagram-line"></i></a></li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import sliderComp from './components/sliderComp.vue';
import axios from "axios";
axios.defaults.baseURL = "https://api.sandbox.cugi.co.ke";
// axios.defaults.headers.common = {
//     'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
//     'X-CG-Authorization': 'Basic YW5vbnltb3VzLm1hbWFAYXBpLmN1Z2kuY28ua2U6QmFNKjkwQE5pVjFFVEhVMSpuem5sMDlvckFBZ0B2TyFOZDYk',
//     'X-CG-Version': 'CugiMama-20230318',
// };
export default {
    name: "signupPage",
    data() {
        return {
            form: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                password: "",
                confirmPassword: "",
            },
            message: "",
            successMessage: "",
            loading: false,
        };
    },
    methods: {
        submitHandler() {
            this.successMessage = null;
            this.message = null;
            //loading 
            this.loading = true

            // Validate form data
            if (
                !this.form.firstName ||
                !this.form.lastName ||
                !this.form.email ||
                !this.form.msisdn ||
                !this.form.password ||
                !this.form.confirmPassword
            ) {
                this.message = "Please fill in all fields.";
                this.loading = false
                return;
            }

            if (this.form.password !== this.form.confirmPassword) {
                this.message = "Passwords do not match.";
                this.loading = false
                return;
            }

            // Make AJAX request
            axios
                .post("/profiles", this.form, {
                    headers: {
                        'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                    }
                })
                .then((response) => {
                    console.log(response.data)
                    if (response.data && response.data.status == 'success' && response.data.code == 200) {
                        this.successMessage = "Your account has been successfully created";
                        setTimeout(() => {
                            this.$router.push({ name: 'login' })
                        }, 1200);
                    }

                })
                .catch((error) => {
                    let errors = error.response.data.data;
                    if (errors.length > 1) {
                        errors.forEach(err => {
                            this.message += ',' + err.message
                        });
                    } else {
                        errors.forEach(err => {
                            this.message = err.message
                        });
                    }
                    // if (Array.isArray(errors)) {
                    //     this.message = errors.join(', ');
                    // } else {
                    //     this.message = errors;
                    // }
                    // console.error(error.response.data.data);
                    // this.message = "An error occurred. Please try again later.";
                }).finally(() => {
                    this.loading = false
                });
        },
    },
    components: {
        sliderComp,
    },
};

</script>
<style lang="">
    
</style>