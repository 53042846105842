<template lang="">
   <transition name="fade">
    <div>
        <appComp>
            <div class="row">
                  <div class="col-sm-12">
                     <div class="row">
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-progress-bar progress-bar-vertical iq-bg-primary">
                                       <span class="bg-primary" data-percent="70"></span>
                                   </div>
                                 <span class="line-height-4">10 feb, 2020</span>
                                 <h4 class="mb-2 mt-2">Hypertensive Crisis</h4>
                                 <p class="mb-0 text-secondary line-height">Ongoing treatment</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-progress-bar progress-bar-vertical iq-bg-danger">
                                       <span class="bg-danger" data-percent="50"></span>
                                   </div>
                                 <span class="line-height-4">12 Jan, 2020</span>
                                 <h4 class="mb-2 mt-2">Osteoporosis</h4>
                                 <p class="mb-0 text-secondary line-height">Incurable</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-progress-bar progress-bar-vertical iq-bg-warning">
                                       <span class="bg-warning" data-percent="80"></span>
                                   </div>
                                 <span class="line-height-4">15 feb, 2020</span>
                                 <h4 class="mb-2 mt-2">Hypertensive Crisis</h4>
                                 <p class="mb-0 text-secondary line-height">Examination</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body P-0 rounded" style="background: url(images/page-img/38.jpg) no-repeat scroll center center; background-size: contain; min-height: 146px;">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- <div class="col-lg-4">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                        <div class="iq-card-body">
                           <div class="user-details-block">
                              <div class="user-profile text-center">
                                 <img src="images/user/11.png" alt="profile-img" class="avatar-130 img-fluid">
                              </div>
                              <div class="text-center mt-3">
                                 <h4><b>Bini Jets</b></h4>
                                 <p>Doctor</p>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p>
                                 <a href="#" class="btn btn-primary">Assign</a>
                              </div>
                              <hr>
                              <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0">
                                 <li class="text-center">
                                    <h3 class="counter">4500</h3>
                                    <span>Operations</span>
                                  </li>
                                  <li class="text-center">
                                    <h3 class="counter">3.9</h3>
                                    <span>Medical Rating</span>
                                  </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-8">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Health Curve</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div id="home-chart-06" style="height: 350px;"></div>
                        </div>
                     </div>
                  </div>                   -->
            </div> 
            <div class="row">
                <div class="col-lg-4">
                    <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                            <h4 class="card-title">Nearest Treatment</h4>
                        </div>
                    </div>
                    <div class="iq-card-body smaill-calender-home">
                        <input type="text" class="flatpicker d-none">
                    </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="iq-card iq-card-block iq-card-stretch iq-card-height-half">
                    <div class="iq-card-body">
                        <h6>APPOINTMENTS</h6>
                        <h3><b>5075</b></h3>
                    </div>
                    <div id="wave-chart-7"></div>
                    </div>
                    <div class="iq-card iq-card-block iq-card-stretch iq-card-height-half">
                    <div class="iq-card-body">
                        <h6>NEW PATIENTS</h6>
                        <h3><b>1200</b></h3>
                    </div>
                    <div id="wave-chart-8"></div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                            <h4 class="card-title">Hospital Management</h4>
                        </div>
                    </div>
                    <div class="iq-card-body hospital-mgt">
                        <div class="progress mb-3" style="height: 30px;">
                            <div class="progress-bar bg-primary" role="progressbar" style="width: 20%;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">OPD</div>
                            <div class="progress-bar bg-warning" role="progressbar" style="width: 80%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">80%</div>
                        </div>
                        <div class="progress mb-3" style="height: 30px;">
                            <div class="progress-bar bg-primary" role="progressbar" style="width: 30%;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">Treatment</div>
                            <div class="progress-bar bg-warning" role="progressbar" style="width: 70%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">70%</div>
                        </div>
                        <div class="progress mb-3" style="height: 30px;">
                            <div class="progress-bar bg-primary" role="progressbar" style="width: 60%;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">Laboratory Test</div>
                            <div class="progress-bar bg-warning" role="progressbar" style="width: 40%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">85%</div>
                        </div>
                        <div class="progress mb-3" style="height: 30px;">
                            <div class="progress-bar bg-primary" role="progressbar" style="width: 40%;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">New Patient</div>
                            <div class="progress-bar bg-warning" role="progressbar" style="width: 60%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">70%</div>
                        </div>
                        <div class="progress mb-3" style="height: 30px;">
                            <div class="progress-bar bg-primary" role="progressbar" style="width: 35%;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">Doctors</div>
                            <div class="progress-bar bg-warning" role="progressbar" style="width: 65%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">95%</div>
                        </div>
                        <div class="progress" style="height: 30px;">
                            <div class="progress-bar bg-primary" role="progressbar" style="width: 28%;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">Discharge</div>
                            <div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">35%</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </appComp>
    </div>
   </transition>
</template>
<script>
import { mapActions } from 'vuex';
import appComp from '@/components/appComp';
import axios from 'axios';
import {mapGetters} from 'vuex';

axios.defaults.baseURL = "https://api.sandbox.cugi.co.ke";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default {
   components: {
      appComp
   },
   methods: {
      ...mapActions({
         signIn: 'auth/signIn'
      })
      

   },
   computed: {
      ...mapGetters({
         user: 'auth/user'
      })
   },
   mounted() {
      console.log("dashboard", this.user);
      const options = {
         method: 'GET',
         url: 'https://api.sandbox.cugi.co.ke/lists/',
         headers: {
            'Accept-Charset': '',
            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            'X-CG-Authorization': 'Basic YW5vbnltb3VzLm1hbWFAYXBpLmN1Z2kuY28ua2U6QmFNKjkwQE5pVjFFVEhVMSpuem5sMDlvckFBZ0B2TyFOZDYk',
            'X-CG-Version': 'CugiMama-20230318',
            'Content-Type': 'application/json',

         },

      };

      axios.request(options).then(function (response) {
         console.log(response.data);
      }).catch(function (error) {
         console.error(error);
      });

   },
   async created() {
    if (
      //if user is admin or global admin
      this.user.identity.displayName === "Global Admin"
      //  ||
      // user.role === "admin" 
    ) {
      this.isAuthorized = true;
    } 
  },


}
</script>
<style lang="">
    
</style>