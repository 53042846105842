<template lang="">
   <transition name="fade">
    <div>
        <appComp>

            <div v-if="showMessage" class="iq-card">
               <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                     <h4 class="card-title">Reset Password </h4>
                  </div>
               </div>
               <div class="iq-card-body">
                  <p>You are required to change your password before the next login . Click the button below to reset.</p>
                  <!-- Button trigger modal -->
                  <button type="button" class="btn btn-primary" @click="reset()">
                  Reset password
                  </button>
               </div>
            </div> 


            <div v-else class="row">
                  <!-- <div class="col-sm-12">
                     <div class="row">
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-progress-bar progress-bar-vertical iq-bg-primary">
                                       <span class="bg-primary" data-percent="70"></span>
                                   </div>
                                 <span class="line-height-4">10 feb, 2020</span>
                                 <h4 class="mb-2 mt-2">Hypertensive Crisis</h4>
                                 <p class="mb-0 text-secondary line-height">Ongoing treatment</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-progress-bar progress-bar-vertical iq-bg-danger">
                                       <span class="bg-danger" data-percent="50"></span>
                                   </div>
                                 <span class="line-height-4">12 Jan, 2020</span>
                                 <h4 class="mb-2 mt-2">Osteoporosis</h4>
                                 <p class="mb-0 text-secondary line-height">Incurable</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-progress-bar progress-bar-vertical iq-bg-warning">
                                       <span class="bg-warning" data-percent="80"></span>
                                   </div>
                                 <span class="line-height-4">15 feb, 2020</span>
                                 <h4 class="mb-2 mt-2">Hypertensive Crisis</h4>
                                 <p class="mb-0 text-secondary line-height">Examination</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body P-0 rounded" style="background: url(images/page-img/38.jpg) no-repeat scroll center center; background-size: contain; min-height: 146px;">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> -->
                  <div class="col-lg-4">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                        <div class="iq-card-body">
                           <div class="user-details-block">
                              <div class="user-profile text-center">
                                 <img src="@/assets/images/user/11.png" alt="profile-img" class="avatar-130 img-fluid">
                              </div>
                              <div class="text-center mt-3">
                                 <h4><b>{{user.identity.displayName}}</b></h4>
                                 <p >currently signed in as </p>
                                 <!-- <p>{{ role }} Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc</p> -->
                                 <a v-for="role in profileRoles" :key="role.id" href="#" class="btn btn-primary mr-2">{{role.name}}</a>
                                 <!-- <a href="#" class="btn btn-primary"></a> -->
                                 <!-- <hr> -->
                                 <!-- <ul
                                    class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                                 > -->
                                    <!-- <li class="text-center">
                                       <a @click="navigateTO('/childvisits/' + childInfo.id + '?pregnancyId=' + (pregnancyId || ''))" class="btn btn-outline-success float-center text-success">Clinic Visits</a>
                                    </li> -->
                                    <!-- <li class="text-center">
                                       <button type="submit" @click="editProfile()" class="btn btn-primary float-center">Edit Profile</button>
                                    </li> -->
                                    <!-- <li class="text-center">
                                       <a @click="viewPreg()" class="btn btn-outline-danger float-center text-danger">Pregnancies</a>
                                    </li> -->

                                 <!-- </ul> -->
                                 <!-- <router-link to="/updateprofile/1/2/2"><a href="#" class="btn btn-primary"> Edit </a></router-link> -->
                              </div>
                              <hr>
                              <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0">
                                 <li class="text-center">
                                    <h3 v-if="(hasRole('global.admin') && hasRole('user') || hasRole('global.auditor') && hasRole('user'))" class="counter">{{pregnancies.length}}</h3>
                                    <h3 v-else class="counter">{{filteredpregnancies.length}}</h3>
                                    <span>Pregnancies</span>
                                  </li>
                                  <li class="text-center">
                                    <h3 class="counter">{{profileNo}}</h3>
                                    <span>Profiles</span>
                                  </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                   <div class="col-md-12 col-lg-8">
                     <div class="row">
                           <!--<div class="col-md-6 col-lg-12">
                              <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-info-box d-flex align-items-center p-3">
                                       <div class="info-image mr-3">
                                          <img src="@/assets/images/page-img/30.png" class="img-fluid" alt="image-box">
                                       </div>
                                       <div class="info-text">
                                          <h3>120</h3>
                                          <span>Total Appointments</span>
                                       </div>
                                 </div>
                              </div>
                              </div>
                           </div>
                           <div class="col-md-6 col-lg-12">
                              <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-info-box d-flex align-items-center p-3">
                                       <div class="info-image mr-3">
                                          <img src="@/assets/images/page-img/31.png" class="img-fluid" alt="image-box">
                                       </div>
                                       <div class="info-text">
                                          <h3>5000</h3>
                                          <span>Completed Appointments</span>
                                       </div>
                                 </div>
                              </div>
                              </div>
                           </div>
                           <div class="col-md-6 col-lg-12">
                              <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body">
                                 <div class="iq-info-box d-flex align-items-center p-3">
                                       <div class="info-image mr-3">
                                          <img src="@/assets/images/page-img/32.png" class="img-fluid" alt="image-box">
                                       </div>
                                       <div class="info-text">
                                          <h3>1500</h3>
                                          <span>Cancelled Appointments</span>
                                       </div>
                                 </div>
                              </div>
                              </div>
                           </div>-->
                           <div class="iq-card">
                              <div class="iq-card-header d-flex justify-content-between">
                                 <div class="iq-header-title">
                                    <h4 class="card-title">Personal Information</h4>
                                 </div>
                              </div>
                              <div class="iq-card-body">
                                 <div class="about-info m-0 p-0">
                                    <div class="row">
                                       <div class="col-4">User Name:</div>
                                       <div class="col-8">{{ user.identity.username ?? "N/A" }}</div>
                                       <div class="col-4">Registration Date:</div>
                                       <div class="col-8">{{ user.identity.registrationDate ?? "N/A" }}</div>
                                       <div class="col-4">Organization:</div>
                                       <div class="col-8">{{ profileOrganization ?? "N/A" }}</div>
                                       <div class="col-4">Email:</div>
                                       <div class="col-8"><a href="#"> {{ user.identity.email ?? "N/A" }} </a></div>
                                       <div class="col-4">Phone:</div>
                                       <div class="col-8"><a href="#">{{ user.identity.msisdn ?? "N/A" }}</a></div>
                                       <div class="col-4">Date of Birth:</div>
                                       <!-- Start Custom Preloader -->
                                       <div v-if="isLoading" class="text-center">
                                          <div class="spinner-border text-primary" role="status">
                                          </div>
                                       </div>
                                       <!-- End Custom Preloader --> 
                                       <div v-else class="col-8">{{ birthDate ?? "N/A" }}</div>
                                       <div class="col-4">Gender:</div>
                                       <!-- Start Custom Preloader -->
                                       <div v-if="isLoading" class="text-center">
                                          <div class="spinner-border text-primary" role="status">
                                          </div>
                                       </div>
                                       <!-- End Custom Preloader -->                                  
                                       <div v-else class="col-8">{{ gender ?? "N/A" }}</div>
                                       <div class="col-4">Language:</div>
                                       <!-- Start Custom Preloader -->
                                       <div v-if="isLoading" class="text-center">
                                          <div class="spinner-border text-primary" role="status">
                                          </div>
                                       </div>
                                       <!-- End Custom Preloader -->
                                       <div v-else class="col-8"><a href="#">{{ language ?? "N/A" }}</a></div>
                                       <div class="col-4">Region:</div>
                                       <!-- Start Custom Preloader -->
                                       <div v-if="isLoading" class="text-center">
                                          <div class="spinner-border text-primary" role="status">
                                          </div>
                                       </div>
                                       <!-- End Custom Preloader -->
                                       <div v-else class="col-8"><a href="#">{{ region ?? "N/A" }}</a></div>
                                       <div class="col-4">Town:</div>
                                       <!-- Start Custom Preloader -->
                                       <div v-if="isLoading" class="text-center">
                                          <div class="spinner-border text-primary" role="status">
                                          </div>
                                       </div>
                                       <!-- End Custom Preloader -->
                                       <div v-else class="col-8"><a href="#">{{ town ?? "N/A" }}</a></div> 
                                       <div class="col-4">Locator ID:</div>
                                       <!-- Start Custom Preloader -->
                                       <div v-if="isLoading" class="text-center">
                                          <div class="spinner-border text-primary" role="status">
                                          </div>
                                       </div>
                                       <!-- End Custom Preloader -->
                                       <div v-else class="col-8"><a href="#">{{ locatorId ?? "N/A" }}</a></div>                                                                            
                                    </div>
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Registered Pregnancies </h4>
                           </div>
                           <div class="iq-card-header-toolbar d-flex align-items-center">
                              <div class="dropdown">
                                 <span class="dropdown-toggle text-primary" id="dropdownMenuButton5" data-toggle="dropdown">
                                 <i class="ri-more-fill"></i>
                                 </span>
                                 <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                    <a class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View All</a>
                                    <!--<a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                                    <a class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>
                                    <a class="dropdown-item" href="#"><i class="ri-printer-fill mr-2"></i>Print</a>
                                    <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>-->
                                 </div>
                              </div>
                           </div>
                        </div>
                        <!-- Start Custom Preloader -->
                        <div v-if="isLoading" class="text-center mt-2">
                           <div class="spinner-border text-primary" role="status">
                           </div>
                        </div>
                        <!-- End Custom Preloader -->
                        <div v-else class="iq-card-body">
                           <div class="table-responsive">
                              <table id="pregTable" class="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th scope="col">EDD</th>
                                       <th scope="col">Registrant Type </th>
                                       <th scope="col">No. of Children</th>
                                       <th scope="col">Action</th>

                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                       <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                       <td>{{pregnancy.registrant.type['name']}}</td>
                                       <td>{{pregnancy.totalChildren}}</td>
                                       <td>
                                          <button @click="navigateTO('/viewpregnancy/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Mother Appointments</h4>
                           </div>
                        </div>
                     <!-- Start Custom Preloader -->
                     <div v-if="isLoading" class="text-center mt-2">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                     </div>
                     <!-- End Custom Preloader -->
                     <div v-else class="iq-card-body">
                        <div class="table-responsive">
                           <table id="appointTable"  class="table mb-0 table-borderless">
                              <thead>
                                 <tr>
                                    <th scope="col">EDD</th>
                                    <th scope="col">No. of Visits</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                    <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                    <td>{{pregnancy.mother.medical.clinicalVisits.length}}</td>
                                    <td>
                                       <button @click="navigateTO('/viewMotherClinicalVisits/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>   
                     </div>
                  </div> 
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Children </h4>
                           </div>
                           <div class="iq-card-header-toolbar d-flex align-items-center">
                              <div class="dropdown">
                                 <span class="dropdown-toggle text-primary" id="dropdownMenuButton5" data-toggle="dropdown">
                                 <i class="ri-more-fill"></i>
                                 </span>
                                 <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                    <a class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View All</a>
                                    <!--<a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                                    <a class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>
                                    <a class="dropdown-item" href="#"><i class="ri-printer-fill mr-2"></i>Print</a>
                                    <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>-->
                                 </div>
                              </div>
                           </div>
                        </div>
                        <!-- Start Custom Preloader -->
                        <div v-if="isLoading" class="text-center mt-2">
                           <div class="spinner-border text-primary" role="status">
                           </div>
                        </div>
                        <!-- End Custom Preloader -->
                        <div v-else class="iq-card-body">
                           <div class="table-responsive">
                              <table id="childrenTable" class="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th scope="col">EDD</th>
                                       <th scope="col">Registrant Type </th>
                                       <th scope="col">No. of Children</th>
                                       <th scope="col">Action</th>

                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                       <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                       <td>{{pregnancy.registrant.type['name']}}</td>
                                       <td>{{pregnancy.totalChildren}}</td>
                                       <td>
                                          <button @click="navigateTO('/childrenList/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Children Appointments</h4>
                           </div>
                        </div>
                     <!-- Start Custom Preloader -->
                     <div v-if="isLoading" class="text-center mt-2">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                     </div>
                     <!-- End Custom Preloader -->
                     <div v-else class="iq-card-body">
                        <div class="table-responsive">
                           <table id="childAppointTable"  class="table mb-0 table-borderless">
                              <thead>
                                 <tr>
                                    <th scope="col">EDD</th>
                                    <th scope="col">No. of Children</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                    <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                    <td>{{pregnancy.totalChildren}}</td>
                                    <td>
                                       <button @click="navigateTO('/viewChildrenClinicalVisits/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>   
                     </div>
                  </div>                                                   
            </div>

        </appComp>
    </div>
   </transition>
</template>
<script>
// import { mapActions } from 'vuex';
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';

// axios.defaults.baseURL = "https://api.sandbox.cugi.co.ke";

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default {
   data() {
      return {
         profileId: [],
         profileRoles: [],
         profileNo: [],
         profileOrganization: [],
         profileOrganizationId: [],
         profileRole: [],
         pregnancies: [],
         filterPregnancies: [],
         filteredpregnancies: [],
         roles: [],
         role: localStorage.getItem("role"),
         gender: [],
         language: [],
         region: [],
         town: [],
         location: [],
         locatorId: [],
         birthDate: [],
         isLoading: true, // Add loading state
         passwordChange: '',
         showMessage: false,
         identityId: ''

      }
   },
   components: {
      appComp
   },
   computed: {
      ...mapGetters({
         lists: "auth/lists",
         user: 'auth/user',
         role: "auth/role",
         current_role: "auth/role",
         current_profile: 'auth/profile'
      }),
      filteredPregnancies() {
         return this.pregnancies.filter(item => item.registrant.id === this.profileId);
      },
      filteredProfile(){
         return this.profiles.filter(item => item.id === this.profileId )
      }
   },
   methods: {
      // ...mapActions({
      //    signIn: 'auth/signIn'
      // }),
      hasRole(roleName) {
         return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      getRoles() {
         this.roles = this.user.profiles[this.current_profile].organization.roles;
         // this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
      },
      editProfile(){
         this.$router.push('/updateprofile/1/2/2"')
      },
      viewPreg(){
         this.$router.push('/profilepregnancies')
      },
      logout() {
         // localStorage.removeItem('token');
         // window.location.href = '/';
         delete axios.defaults.headers.common['X-CG-Authorization'];
         axios.delete('/sessions/'+this.session, {
            headers : {
               'Authorization' : "Bearer "+localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

            }
         }).then((response) => {
            if(response.data.status == 'success')
            {
               localStorage.removeItem('token');
               // window.location.href = '/';

            }
         })  .catch(() => {
                     
         });
         // this.$router.push('/');
         // Remove token or cookie
         // Clear user information from state
         //  this.$store.commit('clearUser');

         // Redirect to login page
      },      
      reset()
      {
         this.logout();
         this.$router.push('/reset')

      },
      navigateTO(location) {
         this.$router.push(location)
      },
      getData() {
         // console.log(axios);
         this.isLoading = true; // Set isLoading to true before making the API call
         delete axios.defaults.headers.common['X-CG-Authorization'];
         try
         {
            axios.get('/pregnancies', {
                  headers: {
                     'Authorization': 'Bearer ' + localStorage.getItem('token'),
                     'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                  },
            }).then((response) => {
                  this.pregnancies = response.data.data.items
                  this.filteredpregnancies = this.pregnancies.filter(item => item.registrant.id === this.profileId || item.mother.id === this.profileId || item.father.id === this.profileId);
                  this.phil = this.filteredpregnancies;
                  // console.log("phil", this.phil)
                  setTimeout(() => {
                     $("#pregTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#orgTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#appointTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#childAppointTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#childrenTable").DataTable();
                  }, 10);
                  // Set isLoading to false once data is loaded
                  this.isLoading = false;

                  // console.log("data", this.filteredpregnancies);
            });
         }
         catch (error) {
               // console.error("Error fetching data:", error);
               this.isLoading = false; // Make sure isLoading is set to false in case of an error
         }

      },
      getProfiles() {
         this.isLoading = true; // Set isLoading to true before making the API call
         delete axios.defaults.headers.common['X-CG-Authorization'];
         axios.get('/organizations/' + 1 + '/profiles', {
               headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
               },
         }).then((response) => {
               this.profiles = response.data.data.items
               this.gender = this.filteredProfile[0].gender.name
               this.language = this.filteredProfile[0].language.name
               this.region = this.filteredProfile[0].region.name
               this.town = this.filteredProfile[0].town.name
               this.location = this.filteredProfile[0].location
               this.locatorId = this.filteredProfile[0].locatorId
               this.birthDate = this.filteredProfile[0].birthDate

               // Set isLoading to false once data is loaded
               this.isLoading = false;

               // console.log("prof", this.filteredProfile)
         });
      }


   },
   mounted() {
      this.getData();
      this.getRoles();
      this.getProfiles();
      this.session = this.user.session.token
      this.profileId = this.user.profiles[this.current_profile].id;
      this.identityId = this.user.identity.id;
      this.profileRoles = this.user.profiles[this.current_profile].organization.roles;
      this.profileNo = this.user.profiles.length
      this.profileOrganizationId = this.user.profiles[this.current_profile].organization.id;
      this.profileOrganization = this.user.profiles[this.current_profile].organization.name;
      this.profileRole = this.profileRoles[0].name
      console.log(this.user)
      this.profileId = this.user.profiles[this.current_profile].id;
      this.passwordChange = this.user.identity.passwordChangeRequired;
      // console.log(this.passwordChange)
      if (this.passwordChange == true) {
      this.showMessage = true;
       } else {
         this.showMessage = false;
       }

   },



}
</script>
<style lang="">
    
</style>