<template lang="">
  <!-- Start Custom Preloader -->
  <div  v-if="isLoading" class="iq-card p-5 text-center">
            <div class="spinner-border text-primary" role="status">
            </div>
    </div>
    <!-- End Custom Preloader -->
  <div v-else class="col-sm-12">
    <form @submit.prevent>
      <ul
        class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
      >
      </ul>
      <hr />

      <div v-if="currentTab === 1"  class="row align-items-center">
        <div class="form-group col-sm-6">
          <label for="uname">Expected Delivery Date (EDD):</label>
          <input type="date" v-model="form.expectedDeliveryDate" class="form-control" id="uname" />
        </div>
        <div class="form-group col-sm-6">
          <label for="lname">Check if the pregnancy is a surrogacy:</label>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck1">
            <label class="custom-control-label" for="customCheck1">True</label>
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label for="fname">Channel Type:</label>
          <select class="form-control" v-model="form.channelTypeId" name="allergies" id="allergies">
              <option disabled value="">Select</option>
              <option v-for="channel in channels" :value="channel.id" :key="channel.id">{{ channel.name}}</option>
          
          </select>
        </div>
        <div class="form-group col-sm-6">
          <label for="uname">Status:</label>
          <select class="form-control" v-model="form.statusId" name="allergies" id="allergies">
              <option disabled value="">Select</option>
              <option v-for="status in statuses" :value="status.id" :key="status.id">{{ status.name}}</option>
          
          </select>
        </div>

        <div class="form-group col-sm-12">
          <label for="recipientSearch">Assign Father:</label>
            <input type="text" v-model="searchQuery" @input="filterProfiles" placeholder="Search Father..." class="form-control">
            <div class="form-group col-sm-12" v-if="filteredProfiles.length">
              <ul>
                <li v-for="profile in filteredProfiles" :key="profile.id" @click="selectFather(profile)">
                  {{ profile.displayName }} | {{ profile.firstName }} {{ profile.lastName }} - {{ profile.msisdn }} | Locator ID: {{ profile.locatorId }}
                </li>
              </ul>
            </div>
        </div>

        <div class="form-group col-sm-12">
          <!-- Display user details or error message -->
        <div v-if="userFatherDetails" class="alert alert-success" role="alert">
          {{ userFatherDetails }}
        </div>
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>

      <div class="form-group col-sm-12">
          <label for="recipientSearch">Assign Mother:</label>
            <input type="text" v-model="searchQuery" @input="filterProfiles" placeholder="Search Mother..." class="form-control">
            <div class="form-group col-sm-12" v-if="filteredProfiles.length">
              <ul>
                <li v-for="profile in filteredProfiles" :key="profile.id" @click="selectMother(profile)">
                  {{ profile.displayName }} | {{ profile.firstName }} {{ profile.lastName }} - {{ profile.msisdn }} | Locator ID: {{ profile.locatorId }}
                </li>
              </ul>
            </div>
        </div>

        <div class="form-group col-sm-12">
          <!-- Display user details or error message -->
        <div v-if="userMotherDetails" class="alert alert-success" role="alert">
          {{ userMotherDetails }}
        </div>
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>

<!-- 
        <div class="form-group col-sm-6">
          <label for="uname">Assign Father:</label>
          <select class="form-control" v-model="form.fatherId" name="allergies" id="allergies">
              <option disabled value="">Select</option>
              <option v-for="father in fathers" :value="father.id" :key="father.id">{{ father.firstName }} {{ father.lastName }}</option>
          
          </select>
        </div>

        <div class="form-group col-sm-6">
          <label for="uname">Assign Mother:</label>
          <select class="form-control" v-model="form.motherId" name="allergies" id="allergies">
              <option disabled value="">Select</option>
              <option v-for="mother in mothers" :value="mother.id" :key="mother.id">{{ mother.firstName }} {{ mother.lastName }}</option>
          
          </select>
        </div> -->
        
        <!-- <div class="form-group col-sm-6">
          <label for="uname">Assign Father:</label>
          <VueMultiselect
            v-model="form.fatherId"
            :options="fathers"
            :label="formattedFatherLabel"
            track-by="id"
            placeholder="Select Father"
            :allow-empty="false" 
          ></VueMultiselect>
        </div>

        <div class="form-group col-sm-6">
          <label for="uname">Assign Mother:</label>
          <VueMultiselect
            v-model="form.motherId"
            :options="mothers"
            label="firstName" 
            track-by="id"
            placeholder="Select Mother"
            :allow-empty="false" 
          ></VueMultiselect>
        </div> -->

      </div>


      <div class="row">
        <div class="col-6">
          <button @click="previous" type="reset" class="btn iq-bg-danger">cancel</button>
        </div>
        <div
          class="col-6 d-flex justify-content-end d-flex justify-content-end"
        >
          <button
            type="submit"
            @click="handleSubmit()"
            class="btn btn-primary mr-2"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>

import axios from 'axios';
import Swal from 'sweetalert2';
// import VueMultiselect from 'vue-multiselect';

// export default {
  // components: { VueMultiselect },

export default {
  components: { 

   },
  data(){
    return {
      isLoading: true, // Add loading state
      selected: null,
      currentDate: '', // Initialize the data property to store the current date
      //array for channel types
      channels: [],
      statuses: [],
      paymenttypes: [],
      commenttype: [],
      mothers: [],
      fathers: [],
      commentvisibilitytype: [],
      currentTab: 1,
      //New Search
      profiles: [],
      userDetails: null,
      errorMessage: '',
      searchQuery: '',
      filteredProfiles: [],
      userMotherDetails: '',
      userFatherDetails: '',
      //
      form: {
      totalChildren: '',
      children: '',
      expectedDeliveryDate: '',
      isChecked: false,
      channelTypeId: '',
      statusId: '',
      typeId: '',
      amount: '',
      transactionId: '',
      memo: '',
      commentTypeId: '',
      visibilityTypeId: '',
      comment: '',
      // Assigning Father and Mother
      fatherId: '',
      motherId: '',
      }
    }
  },
  computed: {
    formattedFatherLabel() {
      // Combine the required information for the father label
      if (this.form.fatherId) {
        const father = this.fathers.find((father) => father.id === this.form.fatherId);
        if (father) {
          return `${father.firstName} ${father.lastName} (${father.displayName} - ID: ${father.id})`;
        }
      }
      return '';
    },

    formattedMotherLabel() {
      // Combine the required information for the mother label
      if (this.form.motherId) {
        const mother = this.mothers.find((mother) => mother.id === this.form.motherId);
        if (mother) {
          return `${mother.firstName} ${mother.lastName} (${mother.displayName} - ID: ${mother.id})`;
        }
      }
      return '';
    },
  },
  methods: {
    setTab(tabNumber) {
      // 
      this.currentTab = tabNumber;
    },

    filterProfiles() {
  if (this.searchQuery) {
    const searchLowerCase = this.searchQuery.toLowerCase();
    this.filteredProfiles = this.profiles.filter(profile => {
      // Ensure displayName, firstName, and lastName are strings and check if they include the search query
      const nameCheck = [profile.displayName, profile.firstName, profile.lastName].some(name =>
        typeof name === 'string' && name.toLowerCase().includes(searchLowerCase)
      );

      // Convert msisdn to a string if it's not already, then check if it includes the search query
      const msisdnCheck = typeof profile.msisdn === 'string'
        ? profile.msisdn.includes(searchLowerCase)
        : typeof profile.msisdn === 'number'
        ? profile.msisdn.toString().includes(searchLowerCase)
        : false;

      // Convert wallet.id to a string and check if it includes the search query
      const locatorIdCheck = profile.locatorId.toString().includes(searchLowerCase);

      return nameCheck || msisdnCheck || locatorIdCheck;
    });
  } else {
    this.filteredProfiles = [];
  }
},
  selectFather(profile) {
    this.form.fatherId = profile.id;
    // Close the dropdown or clear the search
    this.searchQuery = '';
    this.filteredProfiles = [];
    // Optional: Display the selected profile's details
    this.userFatherDetails = `${profile.displayName} | ${profile.firstName} ${profile.lastName} - ${profile.msisdn} | Locator ID: ${profile.locatorId}`;
  },
  selectMother(profile) {
    this.form.motherId = profile.id;
    // Close the dropdown or clear the search
    this.searchQuery = '';
    this.filteredProfiles = [];
    // Optional: Display the selected profile's details
    this.userMotherDetails = `${profile.displayName} | ${profile.firstName} ${profile.lastName} - ${profile.msisdn} | Locator ID: ${profile.locatorId}`;
  },
      // Add this new method to load profiles from localStorage
      loadProfiles() {
        const profilesFromStorage = localStorage.getItem('allProfiles');
        if (profilesFromStorage) {
          try {
            this.profiles = JSON.parse(profilesFromStorage);
            // this.isProfilesLoaded = true; // Assuming you want to track when profiles are loaded
          } catch (e) {
            console.error('Error parsing profiles from localStorage', e);
            // this.errorMessage = 'Failed to load profiles. Please try again.';
          }
        } else {
          console.log('No profiles found in localStorage');
          // this.errorMessage = 'No profiles available. Please add profiles.';
        }
      },
      checkWalletId() {
        const allProfiles = JSON.parse(localStorage.getItem('allProfiles'));
        const foundProfile = allProfiles.find(profile => profile.wallet.id === this.form.creditWalletId);

        if (foundProfile) {
          // Set user details for display
          this.userDetails = `Display Name: ${foundProfile.displayName}, Locator ID: ${foundProfile.locatorId}, Phone: ${foundProfile.msisdn}`;
          this.errorMessage = ''; // Clear any previous error message
        } else {
          // Clear previous user details and set error message
          this.userDetails = null;
          this.errorMessage = 'Sorry! User with that wallet ID does not exist! Try Again.';
        }
      },


    handleSubmit() {
      //submit general information data
      this.message = []
         delete axios.defaults.headers.common['X-CG-Authorization'];
         const payload = {
            expectedDeliveryDate: this.form.expectedDeliveryDate,
            surrogacy: this.form.isChecked,
            channelTypeId: this.form.channelTypeId,
            statusId: this.form.statusId,
            typeId: this.form.typeId,
            transaction:{
              amount: this.form.amount,
              id: this.form.transactionId,
              memo: this.form.memo,
              date: this.currentDate
            },
            father: {
                id: this.form.fatherId,
            },
            mother: {
                id: this.form.motherId,
            },
         };
         axios.patch("/pregnancies/"+this.$route.params.id, payload,{
            headers : {
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
            
         }).then((response) => {
            if(response.data.status == 'success')
               {
                  Swal.fire({
            title: 'Pregnancy Updated',
            text: "pregnancy updated ",
            icon: 'success',
            })
               }
         }).catch((e) => 
         {
            console.log(e.response.data)
            let errors = e.response.data.data;
                    if (errors.length > 1) {
                        errors.forEach(err => {
                           this.message.push(err.message)
                        });
                    } else {
                        errors.forEach(err => {
                           this.message.push(err.message)
                    
                        });
                    }
         })

      // alert('submit the data then next tab')

      this.$emit('handle-submit', 2);
    },

    previous() {
      this.$emit('handle-submit', 1);
    },


    async loadLists(){
      this.isLoading = true; // Set isLoading to true before making the API call
      delete axios.defaults.headers.common['Authorization'];
      axios.get("/lists", { headers: {
         
                'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
           
      }},{
           }).then((response) => {
              this.channels = response.data.data.channeltypes
              this.statuses = response.data.data.status
              this.paymenttypes = response.data.data.paymenttypes
              this.commenttype = response.data.data.commenttype
              this.commentvisibilitytype = response.data.data.commentvisibilitytype
              console.log( response.data.data)
              // Set isLoading to false once data is loaded
            this.isLoading = false;

       });
    },
    //Get Profiles for fathers
    getFathers(){
        this.isLoading = true; // Set isLoading to true before making the API call
        delete axios.defaults.headers.common["X-CG-Authorization"];
        axios
          .get(
            // /organizations/1/profiles?filter=gender.id:eq:1
            "/organizations/1/profiles?filter=gender.id:eq:1",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
              },
            }
          )
          .then((response) => {
            this.fathers = response.data.data.items;
            // Set isLoading to false once data is loaded
            this.isLoading = false;
            console.log("fathers list",this.fathers);
          });        
    },
    //end get profiles
    //Get Mothers
    getMothers(){
        this.isLoading = true; // Set isLoading to true before making the API call
        delete axios.defaults.headers.common["X-CG-Authorization"];
        axios
          .get(
            // /organizations/1/profiles?filter=gender.id:eq:2
            "/organizations/1/profiles?filter=gender.id:eq:2",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
              },
            }
          )
          .then((response) => {
            this.mothers = response.data.data.items;
            // Set isLoading to false once data is loaded
            this.isLoading = false;
            console.log("mothers list",this.mothers);
          });        
    },
    //end get mothers
    //load pregnancy info
    getData(){
        this.isLoading = true; // Set isLoading to true before making the API call
        delete axios.defaults.headers.common["X-CG-Authorization"];
        axios
          .get(
            "/pregnancies/" + this.$route.params.id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
              },
            }
          )
          .then((response) => {
            this.pregnancy = response.data.data.items[0];
            this.form.expectedDeliveryDate = this.pregnancy.expectedDeliveryDate;

            // Set isLoading to false once data is loaded
            this.isLoading = false;
            console.log("pregnancy",this.pregnancy);
          });        
    },
    //get current date
    setCurrentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();

      // Add leading zero to single-digit months and days
      if (mm < 10) {
        mm = '0' + mm;
      }
      if (dd < 10) {
        dd = '0' + dd;
      }

      this.currentDate = `${yyyy}-${mm}-${dd}`; // Set the currentDate data property
    },

  },
  mounted() {
   this.currentTab = 1;
   this.loadLists();
   this.getData();
   this.loadProfiles();
   this.getFathers();
   this.getMothers();
   this.setCurrentDate(); // Call the method to set the current date when the component is loaded
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
