<template lang="">
    <div>
        <appComp>
           <div class="row">
                  <div class="col-lg-3">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Edit {{form.displayName}}'s Profile Information</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                            <form>
                              <div class="form-group">
                                 <div class="add-img-user profile-img-edit">
                                    <img class="profile-pic img-fluid" src="@/assets/images/user/11.png" alt="profile-pic">
                                    <div class="p-image">
                                       <!-- <a href="javascript:void();" class="upload-button btn iq-bg-primary">File Upload</a> -->
                                       <!-- <input class="file-upload" type="file" accept="image/*"> -->
                                    </div>
                                 </div>
                                 
                              </div>
                              <!-- <div v-if="this.$route.params.type == 2 && (hasRole('global.admin'))" class="form-group">
                                 <label>Organization:</label>
                                 <select class="form-control" v-model="form.organizationId" name="organizationId" id="organizationId">
                                    <option disabled>Select</option>
                                    <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{ organization.name}}</option>
                                  
                                 </select>
                              </div> -->
                              <!-- <div v-if="this.$route.params.type == 2 && (hasRole('global.admin') || hasRole('organization.admin') || hasRole('healthcare.facility.admin'))" class="form-group">
                                 <label>Roles(s):</label>
                                 <select class="form-control" multiple v-model="selectedroles" name="roles" id="roles">
                                    <option disabled>Select</option>
                                    <option v-for="role in profile_roles" :key="role.id" :value="role.id">{{role.name }}</option>
                                 
                                 </select>
                              </div> -->
                              <div v-show="hasRole('global.admin') || hasRole('organization.admin') || hasRole('healthcare.facility.admin')" class="form-group">
                                 <label>Status:</label>
                                 <select class="form-control" v-model="form.statusId" name="statusId" id="statusId">
                                    <option  disabled selected>Select</option>
                                    <option  v-for="status in statuses"  :key="status.id" :value="status.id">{{ status.name }}</option>
                                 </select>
                              </div>
                              <div v-show="typeOrg !== null && orgId == 1 && hasRole('global.admin')" class="form-group">
                                 <label>Roles(s):</label>
                                 <select class="form-control" multiple v-model="selectedroles" name="roles" id="roles">
                                    <option disabled>Select</option>
                                    <option v-for="role in global_roles" :key="role.id" :value="role.id">{{role.name }}</option>
                                 
                                 </select>
                              </div>  
                              <div v-show="orgId !== 1 && healthOrg == null  && hasRole('global.admin') || hasRole('organization.admin')" class="form-group">
                                 <label>Roles(s):</label>
                                 <select class="form-control" multiple v-model="selectedroles" name="roles" id="roles">
                                    <option disabled>Select</option>
                                    <option v-for="role in org_roles" :key="role.id" :value="role.id">{{role.name }}</option>
                                 
                                 </select>
                              </div>
                              <div v-show="healthOrg !== null && hasRole('global.admin') || hasRole('healthcare.facility.admin')" class="form-group">
                                 <label>Roles(s):</label>
                                 <select class="form-control" multiple v-model="selectedroles" name="roles" id="roles">
                                    <option disabled>Select</option>
                                    <option v-for="role in health_roles" :key="role.id" :value="role.id">{{role.name }}</option>
                                 
                                 </select>
                              </div>                                                                                         
                              <div class="form-group">
                                 <label for="dob">Date of Birth:</label>
                                 <input type="date" class="form-control" v-model="form.birthDate" name="birthDate" id="phone_no" placeholder="Enter DOB">

                              </div>                              
                              <div class="form-group">
                                 <label for="gender">Gender:</label>
                                 <select class="form-control" v-model="form.genderId" name="genderId" id="genderId">
                                    <option disabled>Select</option>
                                     <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{gender.name }}</option>
                                  
                                 </select>
                              </div>
                              <div class="form-group">
                                 <label for="language">Language:</label>
                                 <select class="form-control" v-model="form.languageId" name="languageId" id="languageId">
                                    <option disabled>Select</option>
                                     <option v-for="language in languages" :key="language.id" :value="language.id">{{language.name }}</option>
                                 </select>
                              </div>
                              <div class="form-group">
                                 <label for="countryId">Notification channel:</label>
                                 <select  class="form-control" v-model="form.channelId" name="countryId">
                                    <option v-for="channel in channels" :value="channel.id" :key="channel.id">{{ channel.name }}</option>
                                 
                                 </select>
                              </div>
                              
                            </form>  
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-9">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Edit Profile Information</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="new-user-info">
                              <div class="m-4">
                                 <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>
                                 <!-- <div v-if="message" class="alert alert-danger">{{ message }}</div> -->
                                 <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                              </div>
                              <form @submit.prevent="handleSubmit()">
                                 <div class="row">
                                    <div class="form-group col-md-6">
                                       <label for="first_name">First Name:</label>
                                       <input type="text" class="form-control" v-model="form.firstName" name="first_name" id="first_name" placeholder="Enter First Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="last_name">Last Name:</label>
                                       <input type="text" class="form-control" v-model="form.lastName" name="last_name" id="last_name" placeholder="Enter Last Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="email">Email:</label>
                                       <input type="email" class="form-control" v-model="form.email" name="email" id="email" placeholder="Enter Email Address">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="phone_no">Phone Number:</label>
                                       <input type="text" class="form-control" v-model="form.msisdn" name="phone_no" id="phone_no" placeholder="Enter Phone Number">
                                    </div>
   
                                    <div class="form-group col-md-6">
                                       <label for="displayName">Display Name:</label>
                                       <input type="text" class="form-control" v-model="form.displayName" name="displayName" id="displayName" placeholder="Enter Display Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="countryId">Country:</label>
                                       <select @change="getRegions" class="form-control" v-model="form.countryId" name="countryId" id="countryId">
                                          <option v-for="country in countries" v-bind:value="country.id" :key="country.id">{{ country.name }}</option>
                                       
                                       </select>
                                    </div>                                
                                    <div class="form-group col-md-6">
                                       <label for="regionId">Region:</label>
                                       <select  @change="getTowns" :disabled="!form.countryId" class="form-control" v-model="form.regionId" name="regionId" >
                                          <option unselected>Select</option>
                                          <option  v-for="region in regions"  :key="region.id" :value="region.id">{{ region.name }}</option>
                                          
                                       </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="townId">Town:</label>
                                       <select @change="checkOther" :disabled="!form.regionId" class="form-control" v-model="form.townId" name="townId" id="townId">
                                          <option unselected>Select</option>
                                          <option v-for="town in towns" :value="town.id" :key="town.id">{{ town.name }}</option>
 
                                       </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="countryId">Literacy level:</label>
                                       <select  class="form-control" v-model="form.literacyLevelId" name="countryId">
                                          <option v-for="literacy in literacylevels" :value="literacy.id" :key="literacy.id">{{ literacy.name }}</option>
                                       
                                       </select>
                                    </div>                                    
                                    <div v-if="hasRole('global.admin') ||  hasRole('organization.admin') ||  hasRole('healthcare.facility.admin')" class="form-group col-md-6">
                                       <label>Will this profile be able to login?</label>
                                       <select class="form-control" v-model="form.chooseLogin">
                                       <option disabled="" value="">Select</option>
                                       <option value="yes">Yes</option>
                                       <option value="no">No</option>
                                       </select>
                                    </div>                                   
                                    <div class="form-group col-md-12" :hidden="!others" >
                                       <label for="town">Town:</label>
                                       <input  type="text" class="form-control" v-model="form.location" name="town" id="town" placeholder="Enter Town Name">
                                    </div>
                                 </div>
                                 <hr>
                                 <!-- <h5 class="mb-3">ReadOnly</h5> -->
                    
                                 <button type="submit" class="btn btn-primary">Update Profile</button>
                                </form>
                           </div>
                        </div>
                     </div>                     
                  </div>                 
           </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import Swal from 'sweetalert2';
const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;

export default {
   data() {
      return {
         roles: [],
         profile_roles: [],
         org_roles: [],
         health_roles: [],
         global_roles: [],

         form: {

         },
         profile: [],

         languages: null,
         countries: null,
         genders: null,
         organization: null,
         channels: null,
         selectedroles: [],
         regions: [],
         towns: [],
         statuses: [],
         literacylevels: [],


         identities: [],
         healthOrg: '',
         typeOrg: '',
         orgId: '', 

         user_id: null,
         others: false,
         message: [],
         successMessage: "",
         passmessage: [],
         passsuccessMessage: '',
      }
   },
   watch: {
      '$route.params.id': function () {
         // This function will be called when the 'id' route parameter changes
         // this.routeParamId = newId;
         // Perform any additional actions or logic based on the new 'id'
         this.getProfile();

      }
      // user_id() {
      // }
   },
   components: {
      appComp
   },
   computed: {
      ...mapGetters({
         lists: 'auth/lists',
         user: 'auth/user',
         role: 'auth/role',
         current_profile: 'auth/profile'
      })
   },
   methods: {
      hasRole(roleName) {
         return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      getRoles() {
         // this.roles = this.user.profiles[this.current_profile].organization.roles;
         this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];

      },
      getProfile() {
         // console.log(axios);

         delete axios.defaults.headers.common['X-CG-Authorization'];
         axios.get('/organizations/' + this.$route.params.org + '/profiles/' + this.$route.params.id, {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
         }).then((response) => {
            this.profile = response.data.data.items[0]
            this.form = response.data.data.items[0]
            // console.log("proforg", this.form.organization)
            // console.log("single Profile", this.form);
         });
      },
      ...mapActions({
         getLists: 'auth/getLists'
      }),
      getOrg() {
         delete axios.defaults.headers.common['X-CG-Authorization'];
         // / get organizations
         axios.get('/organizations/'  + this.$route.params.org, {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
         }).then((response) => {
            // console.log("org", response)
            this.organization = response.data.data.items[0]
            this.healthOrg = this.organization.heathFacilityType.id;
            this.typeOrg = this.organization.type.id;
            this.orgId = this.organization.id;

            // console.log("healthOrg", this.healthOrg)

         });
      },
      getData() {

         //   get data
         axios.get("/lists", {
            headers: {

               'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            }
         }, {
         }).then((response) => {
            this.countries = response.data.data.countries
            this.organizationTypes = response.data.data.organizationtypes
            this.healthFacilities = response.data.data.healthfacilitytypes
            this.channels = response.data.data.channeltypes
            this.genders = response.data.data.genders
            this.languages = response.data.data.languages
            this.statuses = response.data.data.status
            this.literacylevels = response.data.data.literacylevels

            // console.log(response)

            // this.profil_roles = response.data.data.roles


            if (this.hasRole('organization.admin')) {
               this.profile_roles = response.data.data.roles.organization

            } else if (this.hasRole('healthcare.facility.admin')) {
               this.profile_roles = response.data.data.roles.healthcare


            } else if (this.hasRole('global.admin')) {
               this.profile_roles = response.data.data.roles.global.concat(response.data.data.roles.healthcare, response.data.data.roles.organization)

            }


            // this.roles = response.data.data.rolesv2.global.concat(response.data.data.rolesv2.organization, response.data.data.rolesv2.healthcare)
            this.org_roles = response.data.data.roles.organization
            this.health_roles = response.data.data.roles.healthcare
            this.global_roles = response.data.data.roles.global

            // console.log("roles", this.roles)
         });

      },
      async getRegions() {
         try {
            const selectedCountry = this.countries.find(country => country.id === this.form.countryId);
            this.regions = selectedCountry.regions;
            this.regionId = '';
            this.townId = '';
         } catch (error) {
            // console.error(error);
         }
      },

      async getTowns() {
         try {
            const selectedRegion = this.regions.find(region => region.id === this.form.regionId);
            this.towns = selectedRegion.towns;
            this.townId = '';
         } catch (error) {
            // console.error(error);
         }
      },

      checkOther() {
         const other = this.towns.find(town => town.id === this.form.townId);
         if (other.name == "Other") {
            this.others = true;
         } else {
            this.others = false;
         }
      },
      getIdentities() {
         // console.log(axios);
         delete axios.defaults.headers.common['X-CG-Authorization'];
         axios.get('/organizations/' + this.$route.params.id + '/identities', {
               headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
               },
         }).then((response) => {
               this.identities = response.data.data.items
               // console.log("data", this.identities);
         });           
      },      
      handleSubmit() {
         // console.log(this.form)
         this.messages = []
         let data;
         if (this.$route.params.type == 2) {
            data = {
               displayName: this.form.displayName,
               firstName: this.form.firstName,
               lastName: this.form.lastName,
               email: this.form.email,
               msisdn: this.form.msisdn,
               organizationId: this.$route.params.org,
               notificationChannelTypeId: this.form.channelId,
               roles: this.selectedroles,
               countryId: this.form.countryId,
               birthDate: this.form.birthDate,
               genderId: this.form.genderId,
               languageId: this.form.languageId,
               regionId: this.form.regionId,
               townId: this.form.townId,
               location: this.form.location,
               statusId: this.form.statusId,
               literacyLevelId: this.form.literacyLevelId
               // identity: {
               //    username: this.form.email,
               //    email: this.form.email,
               //    msisdn: this.form.msisdn
               // } 
            }

         }
         else if (this.$route.params.type == 1) 
           {
            data = {
               displayName: this.form.displayName,
               firstName: this.form.firstName,
               lastName: this.form.lastName,
               email: this.form.email,
               msisdn: this.form.msisdn,
               organizationId: this.$route.params.org,
               notificationChannelTypeId: this.form.channelId,
               roles: this.selectedroles,
               countryId: this.form.countryId,
               birthDate: this.form.birthDate,
               genderId: this.form.genderId,
               languageId: this.form.languageId,
               regionId: this.form.regionId,
               townId: this.form.townId,
               location: this.form.location,
               literacyLevelId: this.form.literacyLevelId               
            }
         }
         // console.log(data)

         delete axios.defaults.headers.common['X-CG-Authorization'];

         axios.patch("/profiles/" + this.$route.params.id, data, {
            headers: {
               "Content-Type": "application/json",
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
               'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,


            }
         }).then((response) => {
            if (response.data.status == 'success') {
               toast.fire(
               'Success!',
               'Profile updated!',
               'success'
               )
               this.$router.push('/dashboard');
            }
         }).catch((error) => {
            let errors = error.response.data.data;
            if (errors.length > 1) {
               errors.forEach(err => {
                  this.message.push(err.message)
               });
            } else {
               errors.forEach(err => {
                  this.message.push(err.message)

               });
            }

         }).finally(() => {
            this.loading = false
         });
      }
      
   },
   created() {
      this.getData();
      this.getOrg();
      this.getProfile();
   },
   mounted() {
      this.user_id = this.$route.params.id;
      this.getData();
      this.getOrg();
      this.getRoles();
      this.getProfile();
   }

}
</script>
<style lang="">
    
</style>