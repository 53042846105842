<template>
    <div class="modal fade" id="commentsModal" tabindex="-1" role="dialog" aria-labelledby="commentsModalLabel" style="display: none;" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="commentsModalLabel">Comments</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
               
                <div class="modal-body">
                   
                  <ul>
                    <li v-for="comment in selectedComments" :key="comment.id">{{ comment.comment }}</li>
                  </ul>
                   
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
           
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import "jquery/dist/jquery.min.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import $ from "jquery";
  import { mapGetters } from "vuex";
  import Swal from 'sweetalert2';

  export default {
    data() {
      return {
        isDropdownOpen: false,
      searchText: '', // Initialize to an empty string
      organizations: [],
      statuses: [],
      commenttype: [],
      commentvisibilitytype: [],
      //Form Items
      form: {
          organizationId: '',
          statusId: 1,
          scheduledDate: '',
          visitDate: '',
          commentTypeId: '',
          visibilityTypeId: '',
          comments: '',
      },
      //List Items
      clinicalVisits: [],
      roles: [],
      };
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
        role: "auth/role",
        current_profile: "auth/profile",
      }),
      filteredOrganizations() {
      if (this.searchText) {
        return this.organizations.filter(organization =>
          organization.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        return this.organizations;
      }
    },
    selectedComments() {
      return this.$store.state.selectedComments || [];
    },
    },
    //methods go here
    methods: {
      toggleDropdown(event) {
      event.preventDefault(); // Prevent the default behavior
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    filterOrganizations() {
      // No need to do anything here as computed property handles the filtering
    },
    selectOrganization(organization) {
      this.form.organizationId = organization.id;
      this.searchText = organization.name;
      this.isDropdownOpen = false;
    },
      getData() {
        const pregnancyId = this.$route.query.pregnancyId;
        const childId = this.$route.params.id;
  
        // console.log("Monyancha pregnancy id", pregnancyId, childId);
  
        delete axios.defaults.headers.common["X-CG-Authorization"];
        // / get organizations
        axios
          .get("/pregnancies/" + pregnancyId, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
            },
          })
          .then((response) => {
              const childrenArray = response.data.data?.items[0].children;
              // console.log("Children Array", childrenArray);
  
              const childIdToFind = childId;
              const foundChild = childrenArray.find(child => child.id == childIdToFind);
  
              if (foundChild) {
                  this.clinicalVisits = foundChild.medical.clinicalVisits;
              } else {
                  // console.error("Child not found with the provided childId.", childIdToFind);
              }
  
              // console.log("Monyancha Clinical Visits", this.clinicalVisits);
          });
      },

      submit() {
        const pregnancyId = this.$route.query.pregnancyId;
        const childId = this.$route.params.id;

        // Format scheduledDate to "YYYY-MM-DD HH:mm:ss"
        const formattedScheduledDate = new Date(this.form.scheduledDate + ':00').toISOString().slice(0, 19).replace('T', ' ');


        // console.log('Monyancha date', formattedScheduledDate);

        // Construct the data to be sent
        const dataToSend = {
          children: [
            {
              id: childId,
              medical: {
                clinicalVisits: [
                  {
                    id: 0, // This might need to be dynamically generated
                    comments: [
                      {
                        authorId: 40, // You may need to set the actual author ID
                        comment: this.form.comments,
                        id: 0, // This might need to be dynamically generated
                        richText: false, // You may need to adjust this based on your form input
                        typeId: this.form.commentTypeId,
                        visibilityTypeId: this.form.visibilityTypeId,
                      }
                    ],
                    organizationId: this.form.organizationId,
                    scheduledDate: formattedScheduledDate, // Use the formatted date and time
                    statusId: this.form.statusId,
                  }
                ]
              }
            }
          ]
        };

        axios
        .patch(`/pregnancies/${pregnancyId}`, dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then(() => {
          // Handle the response here if needed
          // console.log("API response:", response);
          // Load items on the main component page
          this.getData();
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Clinical visit added successfully.',
          });
          this.$router.replace(`/childvisits/${childId}?pregnancyId=${pregnancyId}`); // Refresh the route
        })
        .catch(() => {
          // Handle errors here
          // console.error("API error:", error);
          // Show error message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'An error occurred while adding the clinical visit.',
          });
        });
    },

        async loadLists(){
          delete axios.defaults.headers.common['Authorization'];
          axios.get("/lists", { headers: {
            
                  'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
          }},{
            }).then((response) => {
                this.statuses = response.data.data.status
                this.commenttype = response.data.data.commenttype
                this.commentvisibilitytype = response.data.data.commentvisibilitytype
                // console.log( response)

          });
      },
      getOrg() {
          delete axios.defaults.headers.common['X-CG-Authorization'];
          // / get organizations
          axios.get('/organizations', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
          }).then((response) => {
            // console.log("org", response)
            this.organizations = response.data.data.items
          });
      },

      hasRole(roleName) {
        return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
      },
      getRoles() {
        // this.roles = this.user.profiles[this.current_profile].organization.roles;
        this.roles =
          this.user.profiles[this.current_profile]?.organization?.roles ?? [];
      },
      navigateTO(location) {
        this.$router.push(location);
      },
    },
    mounted() {
      this.getData();
      this.getRoles();
      this.loadLists();
      this.getOrg();
      delete axios.defaults.headers.common["X-CG-Authorization"];
      axios
        .get("/organizations", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then((response) => {
          this.organizations = response.data.data.items;
          setTimeout(() => {
            $("#orgTable").DataTable();
          }, 10);
        });
      // console.log(this.organizations);
    },
  };
  </script>
  <style scoped>
  /* Your custom styles here */
  .searchable-select {
    position: relative;
    display: inline-block;
    background-color: white;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid white;
  }
  
  .search-input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid white;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 5px;
    cursor: pointer;
  }
  
  </style>
  