<template>
    <div class="row">
        <div class="form-group col-md-6">
            <label for="fname">Allergies:</label>
            <select class="form-control" multiple v-model="selectedAllergies">
                <option disabled>Select</option>
                <option v-for="allergy in allergies" :value="allergy.id" :key="allergy.id">{{ allergy.name }}</option>
            </select>
        </div>
        <div class="form-group col-md-6 pt-4">

            <ul>
                <li><strong>Select Allergies:</strong> Use the drop-down list to select allergies (hold Ctrl/Cmd to
                    select multiple).</li>
                <li><strong>Add Comments:</strong> After selecting an allergy, a comment field will appear. Add relevant
                    comments.</li>
                <li><strong>Capture Allergies:</strong> Click "Capture Allergies" to save your selections and comments.

                    <!-- Button to push data to the parent component -->
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-sm btn-warning" @click="pushDataToParent">Capture Allergies</button>
                        </div>
                    </div>
                </li>
                <!-- <li><strong>Submit the Form:</strong> Click "Submit" to send your allergy data to the appropriate
                        destination.</li> -->
            </ul>
            <p>

            </p>

        </div>
    </div>

    <!-- Display divs for selected allergies with input fields for comments -->
    <div class="row">
        <div class="form-group col-md-6" v-for="selectedAllergyId in selectedAllergies" :key="selectedAllergyId">
            <h3>{{ allergies.find(a => a.id === selectedAllergyId).name }}</h3>
            <div>
                <!-- Bind the input field for comments to the corresponding allergy -->
                <textarea class="form-control" type="text"
                    :placeholder="'Write comments here'"
                    v-model="comments[selectedAllergyId]" ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'



export default {
    data() {
        return {
            allergies: [
                { id: 1, name: 'Allergy 1' },
                { id: 2, name: 'Allergy 2' },
                { id: 3, name: 'Allergy 3' },
                { id: 4, name: 'Allergy 4' },
                // Add more allergies here as needed
            ],
            selectedAllergies: [],
            comments: {

            },
        };
    },
    methods: {
        pushDataToParent() {
            const data = this.selectedAllergies.map((allergyId) => {
                return {
                    allergyId: allergyId,
                    id: 0, // You can generate a unique ID if needed
                    comments: this.comments[allergyId],
                };
            });

            // Push the data to the parent component 


            console.log(data);
        },
        async getData() {
            delete axios.defaults.headers.common['Authorization'];
            axios.get("/lists", {
                headers: {

                    'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

                }
            }, {
            }).then((response) => {
                this.allergies = response.data.data.allergies;
                console.log('allergies', response.data.data)
                this.allergies.forEach((allergy) => {
                    this.comments[allergy.id] = ``;
                });

            });
        }
    },



    mounted() {
        this.getData();

    }
};
</script>

<style lang="">
/* Add any necessary styles here */
</style>
