<template lang="">
  <div>
      <appComp>
          <div class="d-flex justify-content-end mb-3">
            <AddImmunizationModal @submitSuccess="getData" />
              <!-- <button  type="button" class="btn btn-primary" data-toggle="modal" data-target="#addImmunizationModal"><i class="ri-add-fill"></i> Add New Immunization</button> -->
          </div>
                <div class="iq-card col-lg-12">
                  <div class="iq-card-body">
                         <p>Immunizations</p>
                         <!-- Start Custom Preloader -->
                         <div v-if="isLoading" class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                          </div>
                          <!-- End Custom Preloader -->
                         <table v-else id="orgTable" class="table table-hover">
                            <thead>
                               <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Organization</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Vaccine</th>     
                                  <th scope="col">Comment</th>  
                                  <th  scope="col">Action</th>                         
                               </tr>
                            </thead>
                            <tbody>
                              <tr v-for="visit in immunizations" :key="visit.id">
                                  <th scope="row">{{visit.id}}</th>
                                  <th scope="row">{{ visitOrganizationName(visit) }}</th>
                                  <th scope="row">{{visit.immunizationDate ?? "-"}}</th>
                                  <td><span class="badge badge-pill badge-success">Active</span></td>
                                  <th scope="row">{{ visitVaccineName(visit) }}</th>
                                  <!-- <th scope="row">{{visit.comments[0].comment ?? "-"}} <br/> <a href="#" data-toggle="modal" data-target="#commentsModal">View More</a></th> -->
                                  <th scope="row">
                                    {{ visit.comments.length > 0 ? visit.comments[0].comment : "-" }}
                                    <br />
                                    <a
                                      href="#"
                                      @click="openCommentsModal(visit.comments)"
                                      data-toggle="modal"
                                      data-target="#commentsModal"
                                    >View More</a>
                                  </th>
                                  <th>
                                    <EditImmunizationModal :visit="visit" @submitSuccess="getData" />
                                    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#updateImmunizationModal"><i class="ri-pencil-fill"></i> Update</button>                               -->
                                  </th>
                               </tr>
                            </tbody>
                         </table>
                      </div>
                </div>
      </appComp>

      <CommentsModal />

  </div>
</template> 

<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';
import AddImmunizationModal from "@/components/mother/AddImmunizationModal.vue";
import EditImmunizationModal from "@/components/mother/EditImmunizationModal.vue"
import CommentsModal from "@/components/reusable/CommentsModal.vue";


export default {
data() {
  return {
  //List Items
  vaccine: "",
  immunizations: [],
  roles: [],
  vaccinesList: [], // Initialize the vaccinesList array
  oraganizationList: [],
  isLoading: true, // Add loading state
  };
},
computed: {
  ...mapGetters({
    user: "auth/user",
    role: "auth/role",
    current_profile: "auth/profile",
  }),
},

//methods go here
methods: {
  async mounted() {
    await this.loadLists(); // Wait for loadLists to complete before getData
    this.getData();
    this.getRoles();

  },

  getData() {
    this.isLoading = true; // Set isLoading to true before making the API call
    const pregnancyId = this.$route.query.pregnancyId;

    delete axios.defaults.headers.common["X-CG-Authorization"];
    // / get organizations
    axios
      .get("/pregnancies/" + pregnancyId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
        },
      })
      .then((response) => {
          this.immunizations = response.data.data?.items[0].mother.medical.immunizations;
          // Set isLoading to false once data is loaded
          this.isLoading = false;
          // console.log("Monyancha Mother Info", this.immunizations);
      });
  },

  async loadLists() {
      this.isLoading = true;
      const storedVaccines = localStorage.getItem('vaccinesList');
      if (storedVaccines) {
        this.vaccinesList = JSON.parse(storedVaccines);
        this.isLoading = false;
      } else {
        delete axios.defaults.headers.common['Authorization'];
        axios.get("/lists", {
            headers: {
                'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            localStorage.setItem('vaccinesList', JSON.stringify(response.data.data.vaccines.child));
            this.vaccinesList = response.data.data.vaccines.child;
            this.isLoading = false;
        }).catch((error) => {
            console.error('Error loading vaccine list:', error);
            this.isLoading = false;
        });
      }
    },
    async loadOrganizations() {
      this.isLoading = true;
      const storedOrgs = localStorage.getItem('organizationList');
      if (storedOrgs) {
        this.organizationList = JSON.parse(storedOrgs);
        this.isLoading = false;
      } else {
        delete axios.defaults.headers.common['Authorization'];
        axios.get("/organizations", {
            headers: {
                'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            localStorage.setItem('organizationList', JSON.stringify(response.data.data.items));
            this.organizationList = response.data.data.items;
            this.isLoading = false;
        }).catch((error) => {
            console.error('Error loading organization list:', error);
            this.isLoading = false;
        });
      }
    },
    visitVaccineName(visit) {
      console.log("Monyancha abbv visit", visit);
      console.log("Monyancha abbv vaccine list", this.vaccinesList);
      const matchingVaccine = this.vaccinesList.find((vacc) => vacc.id == visit.vaccine.id);
      console.log("Monyancha abbv matchine vaccine", matchingVaccine);
      return matchingVaccine ? matchingVaccine?.name : '-';
    },
    visitOrganizationName(visit) {
      const matchingOrg = this.organizationList.find((org) => org.id == visit.organizationId);
      return matchingOrg ? matchingOrg.name : '-';
    },

  hasRole(roleName) {
    return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
  },
  getRoles() {
    this.roles =
      this.user.profiles[this.current_profile]?.organization?.roles ?? [];
  },
  navigateTO(location) {
    this.$router.push(location);
  },
  openCommentsModal(comments) {
  this.$store.commit("setSelectedComments", comments);
},
},
components: {
  appComp,
  AddImmunizationModal,
  EditImmunizationModal,
  CommentsModal,
},
mounted() {
  this.getRoles();
  delete axios.defaults.headers.common["X-CG-Authorization"];
  axios
    .get("/organizations", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
      },
    })
    .then((response) => {
      this.oraganizationList = response.data.data.items;
      // console.log("onyambu", this.oraganizationList);
      setTimeout(() => {
        $("#orgTable").DataTable();
      }, 10);
    });
  
    // Move the loadLists() and getData() calls here
  this.loadLists();
  this.loadOrganizations();
  this.getData();
},


};

</script>






