<template lang="">
  <div>
    <appComp>
      <div class="row">
        <div class="col-lg-4">
          <div class="iq-card">
            <div class="iq-card-body pl-0 pr-0 pt-0">
              <div class="doctor-details-block">
                <div
                  class="doc-profile-bg bg-primary"
                  style="height: 150px"
                ></div>
                <div class="doctor-profile text-center">
                  <img
                    src="@/assets/images/user/11.png"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                </div>
                <div class="text-center mt-3 pl-3 pr-3">
                  <h4>
                    <b>{{ profile.displayName }}</b>
                  </h4>
                </div>
                <hr />
                <ul 
                  class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                >
                  <li class="text-center"></li>
                  <li class="text-center">
                    <button v-if="hasRole('global.admin') || hasRole('organization.admin') || hasRole('healthcare.facility.admin')"
                      type="submit"
                      @click="editProfile"
                      class="btn btn-primary float-center"
                    >
                      Edit Profile
                    </button>
                  </li>
                  <li class="text-center"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                 <div class="iq-header-title">
                    <h4 class="card-title">Profile Information</h4>
                 </div>
              </div>
              <div class="iq-card-body">
                 <div class="about-info m-0 p-0">
                    <div class="row">
                       <div class="col-4">First Name:</div>
                       <div class="col-8">{{ profile.firstName ?? "N/A" }}</div>
                       <div class="col-4">Last Name:</div>
                       <div class="col-8">{{ profile.lastName ?? "N/A" }}</div>
                       <div class="col-4">Phone:</div>
                       <div class="col-8">{{ profile.msisdn ?? "N/A" }}</div>
                       <div class="col-4">Email:</div>
                       <div class="col-8"><a href="#"> {{ profile.email ?? "N/A" }} </a></div>
                       <div class="col-4">Display Name:</div>
                       <div class="col-8"><a href="#">{{ profile.displayName ?? "N/A" }}</a></div>
                       <div class="col-4">Language:</div>
                       <div class="col-8"><a href="#">{{ language ?? "N/A" }}</a></div>     
                       <div class="col-4">Gender:</div>
                       <div class="col-8"><a href="#">{{ gender ?? "N/A" }}</a></div> 
                       <div class="col-4">Status:</div>
                       <div class="col-8"><a href="#">{{ status ?? "N/A" }}</a></div> 
                       <div class="col-4">Organization:</div>
                       <div class="col-8"><a href="#">{{ organization ?? "N/A" }}</a></div>
                       <div class="col-4">Region:</div>
                       <div class="col-8"><a href="#">{{ region ?? "N/A" }}</a></div>
                       <div class="col-4">Town:</div>
                       <div class="col-8"><a href="#">{{ town ?? "N/A" }}</a></div>
                       <div class="col-4">Registration Date:</div>
                       <div class="col-8"><a href="#">{{ profile.registrationDate ?? "N/A" }}</a></div>
                       <div class="col-4">Roles:</div>
                       <div class="col-8"><a href="#">
                       <span v-for="roli in profile_roles" :key="roli">
                            "{{ roli.split(".role.")[1] ?? "N/A" }}"
                       </span></a></div>
                       <div class="col-4">Locator ID:</div>
                       <div class="col-8"><a href="#">{{ profile.locatorId ?? "N/A" }}</a></div>
                    </div>
                 </div>
              </div>
          </div>
        </div>
      </div>
    </appComp>
  </div>
</template>
<script>
import appComp from "@/components/appComp";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      roles: [],
      profile_roles: [],
      profile: [],
      region: "",
      town: "",
      status: "",
      gender: "",
      organization: "",
      langugage: "",

      others: false,
      message: [],
      successMessage: "",
    };
  },
  components: {
    appComp,
  },
  computed: {
    ...mapGetters({
      lists: "auth/lists",
      user: "auth/user",
      role: "auth/role",
      current_profile: "auth/profile",
    }),
  },
  methods: {
    editProfile() {
      this.$router.push(
        "/updateprofile/" +
        this.$route.params.org +
        "/" +
        this.$route.params.id +
        "/2"
      );
    },
    hasRole(roleName) {
      return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
    },
    getRoles() {
      // this.roles = this.user.profiles[this.current_profile].organization.roles;
      this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
    },
    getProfile() {
      // console.log(axios);

      delete axios.defaults.headers.common["X-CG-Authorization"];
      axios
        .get(
          "/organizations/" +
          this.$route.params.org +
          "/profiles/" +
          this.$route.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
            },
          }
        )
        .then((response) => {
          this.profile = response.data.data.items[0];
          this.region = response.data.data.items[0].region.name;
          this.town = response.data.data.items[0].town.name;
          this.status = response.data.data.items[0].status.name;
          this.profile_roles = response.data.data.items[0].roles;
          this.gender = response.data.data.items[0].gender.name;
          this.language = response.data.data.items[0].language.name;
          this.organization = response.data.data.items[0].organization.name;

          // console.log(response);
        });
    },

  },
  mounted() {
    this.getProfile();
    this.getRoles();
  },
};
</script>
<style lang="css">
.font-12 {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>
