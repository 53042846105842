<template>


    <!-- <button @click="centerDialogVisible = true" v-show="hasRole('healthcare.facility.user') " type="button" class="btn btn-primary" ><i class="ri-add-fill"></i> Add New Visit</button> -->
    <button @click="centerDialogVisible = true" v-show="hasRole('healthcare.facility.user') " type="button" class="btn btn-primary" ><i class="ri-edit-fill"></i> Update </button>


    <el-dialog
      v-model="centerDialogVisible"
      title="Update Clinical Visit"
      width="500"
    >

    <form>
      <div class="modal-body">

        <div class=" row align-items-center">
          <div class="form-group col-sm-6">
            <label for="fname">Organization:</label>
            <el-select
              v-model="form.organizationId"
              filterable
              clearable
              required
              size="large"
              placeholder="Select Organization"
            >
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="form-group col-sm-6">
            <label for="uname">Scheduled Date:</label>
            <el-input required type="datetime-local" size="large" v-model="form.scheduledDate" placeholder="Select Date" />
          </div>

        </div>

        <!-- <div class="row align-items-center"> -->

          <div class="row align-items-center" v-for="(comment, index) in form.comments" :key="index">
            
            <div class="form-group col-sm-6">
            <label for="fname">Comment Type {{ index + 1 }}</label>
            <el-select
              v-model="comment.commentTypeId"
              filterable
              clearable
              required
              size="large"
              placeholder="Select Type"
            >
              <el-option
                v-for="item in commenttype"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="form-group col-sm-6">
            <label for="uname">Visibility Type {{ index + 1 }}</label>
            <el-select
              v-model="comment.visibilityTypeId"
              filterable
              clearable
              size="large"
              placeholder="Select Type"
            >
              <el-option
                v-for="item in commentvisibilitytype"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>  
            
            <div class=" col-sm-10">
              <label for="memo">Comment {{ index + 1 }}</label>
              <el-input
                  v-model="comment.text"
                  :autosize="{ minRows: 2 }"
                  type="textarea"
                  placeholder="Enter your comment"
              />
          </div>

              <div class="mt-2 col-sm-2">
              <button @click="removeComment(index)" type="button" class="mt-1 btn btn-outline-danger"><i class="pl-2 pr-1 ri-delete-bin-2-fill pe-0"></i></button>
              </div>
              
          </div>
          
          <div class="row align-items-center">
            <div class="mt-3 col-sm-6">
              <el-button type="primary" color="#09a1b2" @click="addComment">Add Comment</el-button>
            </div>
            </div>


        <!-- </div> -->

      </div>
    </form>

      <template #footer>
        <div class="dialog-footer">
          <!-- <el-button type="danger" size="large" @click="centerDialogVisible = false">Cancel</el-button> -->
          <el-button :loading="submitLoading" type="primary" size="large" color="#09a1b2" @click="submit()">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

</template>

<script>
import axios from "axios";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { mapGetters } from "vuex";
import { ref } from 'vue'
import { ElNotification } from 'element-plus';

export default {
emits: ['submitSuccess'], // Declare custom event
components: {
//   SearchSelect
},
props: {
    visit: {
    type: Object,
    required: true
    }
},

data() {
return {
key: 0,
centerDialogVisible: ref(false),
isDropdownOpen: false,
searchText: '', // Initialize to an empty string
organizations: [],
statuses: [],
commenttype: [],
commentvisibilitytype: [],
submitLoading: ref(false),
//Form Items
form: {
organizationId: this.visit?.organizationId ?? '',
statusId: 1,
scheduledDate: this.visit?.scheduledDate ?? '',
visitDate: '',
// Initialize comments with data from this.visit
// comments: this.visit ? this.visit.comments.map(comment => ({ text: comment.comment })) : [{ text: '' }],
comments: this.visit ? this.visit.comments.map(comment => ({
    id: comment.id,
    text: comment.comment,
    commentTypeId: comment.type.id,
    visibilityTypeId: comment.visibilityType.id
})) : [{ 
    id: 0,
    text: '',
    commentTypeId: '',
    visibilityTypeId: ''
}],
},
//List Items
clinicalVisits: [],
roles: [],
};
},
computed: {
...mapGetters({
user: "auth/user",
role: "auth/role",
current_profile: "auth/profile",
}),
filteredOrganizations() {
if (this.searchText) {
return this.organizations.filter(organization =>
  organization.name.toLowerCase().includes(this.searchText.toLowerCase())
);
} else {
return this.organizations;
}
},
},
//methods go here
methods: {

addComment() {
this.form.comments.push({ text: '' }); // Add a new comment object
},
removeComment(index) {
this.form.comments.splice(index, 1); // Remove comment at the specified index
},

resetForm() {
  // Reset each field of the form to its initial value
  this.form.organizationId = '';
  this.form.statusId = 1;
  this.form.scheduledDate = '';
  this.form.visitDate = '';
  this.form.commentTypeId = '';
  this.form.visibilityTypeId = '';
  this.form.comments = [{ 
    id: '',
    text: '',
    commentTypeId: '',
    visibilityTypeId: ''
   }];
},

handleSelect(selectedId) {
// Handle the selected organization ID here
console.log("Selected Organization ID monyancha:", selectedId);
this.form.organizationId = selectedId; // Update the form with the selected ID
},
toggleDropdown(event) {
event.preventDefault(); // Prevent the default behavior
this.isDropdownOpen = !this.isDropdownOpen;
},
filterOrganizations() {
// No need to do anything here as computed property handles the filtering
},
selectOrganization(organization) {
this.form.organizationId = organization.id;
this.searchText = organization.name;
this.isDropdownOpen = false;
},

submit() {
// Console log the visit data
console.log('Parsed Visit data monyancha aiden:', this.visit);

//Set Loading State
this.submitLoading = ref(true);

const pregnancyId = this.$route.query.pregnancyId;
const childId = this.$route.params.id;

// Format scheduledDate to "YYYY-MM-DD HH:mm:ss"
const formattedScheduledDate = new Date(this.form.scheduledDate + ':00').toISOString().slice(0, 19).replace('T', ' ');


// console.log('Monyancha date', formattedScheduledDate);

// Construct the data to be sent
const dataToSend = {
children: [
  {
    id: childId,
    medical: {
      clinicalVisits: [
        {
          id: this.visit.id, // This might need to be dynamically generated
          comments: this.form.comments.map(comment => ({
              authorId: 40, // You may need to set the actual author ID
              comment: comment.text,
              id: comment.id ?? 0, // This might need to be dynamically generated
              richText: false, // You may need to adjust this based on your form input
              typeId: comment.commentTypeId,
              visibilityTypeId: comment.visibilityTypeId,
          })),
          organizationId: this.form.organizationId,
          scheduledDate: formattedScheduledDate, // Use the formatted date and time
          statusId: this.form.statusId,
        }
      ]
    }
  }
]
};

axios
.patch(`/pregnancies/${pregnancyId}`, dataToSend, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
  },
})
.then(() => {
  // Handle the response here if needed
  // console.log("API response:", response);
  // Load items on the main component page
  this.getData();
  //Send Success Notification
  this.centerDialogVisible = ref(false),

  // Show success message
  ElNotification({
      title: 'Success',
      message: 'Clinical visit updated successfully!',
      type: 'success',
      duration: 5000,
  });

  // Reset the form after successful submission
  this.resetForm();

  // Simulate API call
  setTimeout(() => {
      // Reset submitLoading to deactivate the loading state
      this.submitLoading = false;
      
      // Emit an event to notify the parent component
      this.$emit('submitSuccess'); // Emit the custom event
  }, 2000);

  // this.$router.go(0);
  // Reload the current route to update the page
  // this.$router.replace({ path: `/childvisits/${childId}?pregnancyId=${pregnancyId}` });

  //Once i submit refresh the page
  // this.$router.push(`/childvisits/${childId}?pregnancyId=${pregnancyId}`);
})
.catch((e) => {
  // Handle errors here
  // console.error("API error:", error);
  // Show error message

  let errors = e.response.data.data;

  errors.forEach((err, index) => {
     setTimeout(() => {
        // Send Notification 
        ElNotification({
           title: 'Error ',
           message: err.detail,
           type: 'error',
           duration: 7000,
        });
     }, index * 1000); // Adjust the delay time (in milliseconds) as needed
  });

  //Set Loading State
  this.submitLoading = ref(false);
});

//Set Loading State
  this.submitLoading = ref(false);
},

async loadLists() {
delete axios.defaults.headers.common['Authorization'];
axios.get("/lists", {
headers: {

  'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

}
}, {
}).then((response) => {
this.statuses = response.data.data.status
this.commenttype = response.data.data.commenttype
this.commentvisibilitytype = response.data.data.commentvisibilitytype
// console.log( response)

});
},
getOrg() {
delete axios.defaults.headers.common['X-CG-Authorization'];
// / get organizations
axios.get('/organizations', {
headers: {
  'Authorization': 'Bearer ' + localStorage.getItem('token'),
  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
},
}).then((response) => {
console.log("organixations monyancha", response.data.data.items)
this.organizations = response.data.data.items
});
},

hasRole(roleName) {
return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
},
getRoles() {
// this.roles = this.user.profiles[this.current_profile].organization.roles;
this.roles =
this.user.profiles[this.current_profile]?.organization?.roles ?? [];
},
navigateTO(location) {
this.$router.push(location);
},
},
mounted() {
this.getRoles();
this.loadLists();
this.getOrg();

// console.log(this.organizations);
},
};
</script>
<style scoped>
/* Your custom styles here */
.searchable-select {
position: relative;
display: inline-block;
background-color: white;
}

.dropdown-list {
position: absolute;
top: 100%;
left: 0;
width: 100%;
background-color: white;
border: 1px solid white;
}

.search-input {
width: 100%;
padding: 5px;
border: none;
border-bottom: 1px solid white;
}

ul {
list-style: none;
padding: 0;
margin: 0;
}

li {
padding: 5px;
cursor: pointer;
}</style>
