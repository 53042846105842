<template lang="">
  <div>
      <appComp>
         <div class="row">
                <div class="col-lg-12">
                  <div class="iq-card-body">
                         <p>Pregnancy Children</p>
                          <!-- Start Custom Preloader -->
                          <div v-if="isLoading" class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                            </div>
                            <!-- End Custom Preloader -->
                         <table v-else id="orgTable" class="table table-hover">
                            <thead>
                               <tr>
                                  <th scope="col">Display Name</th>
                                  <th scope="col">Full Name</th>
                                  <th scope="col">Date of Birth</th>
                                  <th>Action</th>
                               </tr>
                            </thead>
                            <tbody>
                              <tr v-for="child in children" :key="child.id">
                                  <td>{{child.displayName ?? 'N/A'}}</td>
                                  <td>{{child.firstName ?? ''}} {{child.lastName ?? 'N/A'}}</td>
                                  <td>{{child.birthDate ?? 'N/A'}}</td>
                                  <td>
                                      <div class="row justify-space-between">
                                      <div class="btn-group" role="group">
                                          <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Action
                                          </button>
                                          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                              <!-- <a @click="navigateTO('/viewpregnancy/'+child.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Pregnancy</a>
                                              <a @click="navigateTO('/viewchildren')" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Children</a>
                                              <a @click="navigateTO('/viewmothermedicalchart/'+child.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Mother Medical Chart</a> -->
                                              <!-- <a @click="navigateTO('/editchild/'+child.id)" class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit Child</a> -->
                                              <a @click="navigateTO('/addclinicalvisit/'+child.id+'/'+this.$route.params.id)" class="dropdown-item" href="#"><i class="ri-add-fill mr-2"></i>Add Clinical Visit</a>
                                              <a @click="navigateTO('/addimmunization/'+child.id)" class="dropdown-item" href="#"><i class="ri-add-fill mr-2"></i>Add Immunizations</a>                                    
                  
                                          
                                          </div>
                                      </div>
                                      <!-- <span></span>
                                      <button @click="deleteOrganization(organization.id)" class="btn-sm btn btn-danger ml-2">
                                          Delete
                                      </button> -->
                                      </div>
                                  </td>
                               </tr>
                            </tbody>
                         </table>
                      </div>
                </div>
         </div>
      </appComp>
  </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';

export default {
  data() {
      return {
          children: [],
          roles: [],
          isLoading: true, // Add loading state
      }
  },
  computed: {
      ...mapGetters({
          user: 'auth/user',
          role: 'auth/role',
          current_profile: 'auth/profile'
      }),
  },
  //methods go here
  methods: {
    getData(){
        this.isLoading = true; // Set isLoading to true before making the API call
        delete axios.defaults.headers.common["X-CG-Authorization"];
        axios
          .get(
            "/pregnancies/" + this.$route.params.id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
              },
            }
          )
          .then((response) => {
            this.pregnancy = response.data.data.items[0];
            this.children = this.pregnancy.children;
            // Set isLoading to false once data is loaded
            this.isLoading = false;
            // console.log("data", this.children);
          });        
      },

      hasRole(roleName) {
          return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      getRoles() {
          // this.roles = this.user.profiles[this.current_profile].organization.roles;
          this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
      },
      getOrg(){
          delete axios.defaults.headers.common['X-CG-Authorization'];
          axios.get('/organizations', {
              headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
              },
          }).then((response) => {
              this.organizations = response.data.data.items
              setTimeout(() => {
                  $("#orgTable").DataTable();
              }, 10);
          });
          // console.log(this.organizations);
      },
      navigateTO(location) {
          this.$router.push(location)
      },

  },
  components: {
      appComp
  },
  mounted() {
      this.getData();
      this.getRoles();
      this.getOrg()

      //     axios.get("/organizations", {
      //        }).then((response) => {
      //           this.organizations = response.data.data.items

      //    });
  }

}
</script>
<style lang="">
  
</style>
