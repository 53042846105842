<template lang="">
    <div>
        <appComp>
                  <div class="iq-card col-lg-12">
                    <div class="iq-card-body">
                           <p>All Profiles</p>
                           <!-- Start Custom Preloader -->
                           <div v-if="isLoading" class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                            </div>
                            <!-- End Custom Preloader -->                           
                           <table v-else id="profileTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <!-- <th scope="col">#</th> -->
                                    <th scope="col">Display Name</th>
                                    <th scope="col">msisdn</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Locator Id</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr v-for="profile in profiles" :key="profile.id">
                                    <!-- <th scope="row">{{ profile.id }}</th> -->
                                    <td>{{profile.displayName}}</td>
                                    <td>{{profile.msisdn ? profile.msisdn: "Not Assigned" }}</td>
                                    <td>{{profile.email ? profile.email: "Not Assigned" }}</td>
                                    <td>{{profile.locatorId ? profile.locatorId: "Not Assigned" }}</td>
                                    <td>{{profile.gender.name ? profile.gender.name: "Not Assigned" }}</td>
                                    <td>{{profile.status.name}}</td>
                                    <td>
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                            <a @click="navigateTo('/viewprofile/'+this.$route.params.id+'/'+profile.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View</a>                                            
                                            <a v-if="hasRole('global.admin') || hasRole('organization.admin') || hasRole('healthcare.facility.admin')" @click="navigateTo('/updateprofile/'+this.$route.params.id+'/'+profile.id+'/2')" class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>                                                   
                                            <a v-if="profile.status.name == 'Active' && hasRole('global.admin') || hasRole('organization.admin') || hasRole('healthcare.facility.admin')" @click="deleteProfile(profile.id)" class="dropdown-item" href="#"><i class="ri-close-circle-fill mr-2"></i>Deactivate </a>
                                            <a @click="navigateTo('/chronic-diseases/'+this.$route.params.id+'/'+profile.id)" class="dropdown-item" href="#"><i class="ri-hospital-fill mr-2"></i>Chronic Diseases <span class="badge badge-danger">New</span></a>
                                        </div>
                                        </div>

                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                  </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';

const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;

export default {
    data() {
        return {
            profiles: [],
            roles: [],
            isLoading: true, // Add loading state
        }
    },
    computed: {
        ...mapGetters({
            lists: "auth/lists",
            user: "auth/user",
            role: "auth/role",
            current_profile: "auth/profile",
        }),
    },
    methods: {
        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];

        },
        navigateTo(location) {
            this.$router.push(location)
        },
        deleteProfile(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, deactivate it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    //send request to the server
                    delete axios.defaults.headers.common['X-CG-Authorization'];

                    axios.delete('/organizations/' + this.$route.params.id + '/profiles/' + id, {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                        },
                    }).then(() => {
                        toast.fire(
                            'Deleted!',
                            'Profile has been deactivated.',
                            'success'
                        )
                        this.getData()
                    }).catch(() => {
                        Swal.fire(
                            'Failed!',
                            'There was something wrong.',
                            'warning'
                        )
                    });
                } else if (result.isDenied) {
                    // console.log('cancelled')
                }

            })
        },         
        getData() {
            // console.log(axios);
            this.isLoading = true; // Set isLoading to true before making the API call
            delete axios.defaults.headers.common['X-CG-Authorization'];
            try {                  
            axios.get('/organizations/' + this.$route.params.id + '/profiles', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
            }).then((response) => {
                this.profiles = response.data.data.items
                setTimeout(() => {
                    $("#profileTable").DataTable();
                }, 10);
                // Set isLoading to false once data is loaded
                this.isLoading = false;
                // console.log("data", this.profiles);
            });
            } catch (error) {
                // console.error("Error fetching data:", error);
                this.isLoading = false; // Make sure isLoading is set to false in case of an error
            }            
        }
    },
    //methods go here
    components: {
        appComp
    },
    mounted() {
        this.getData();
        this.getRoles();
    }

}
</script>
<style lang="">
    
</style>