<template lang="">
    <div>
        <appComp>
         <div class="row">
            <div class="col-sm-12 col-lg-12">
               <div class="iq-card">
                  <div class="iq-card-header d-flex justify-content-between">
                     <div class="iq-header-title">
                        <h4 class="card-title">Add New Branch</h4>
                     </div>
                  </div>
                  <div class="iq-card-body">
                     <form @submit.prevent="handleForm" id="form-wizard1" class="text-center mt-4">
                        <!-- <ul id="top-tab-list" class="p-0">
                           <li class="active" id="account">
                              <a href="javascript:void();">
                              <i class="ri-lock-unlock-line"></i><span>Account</span>
                              </a>
                           </li>
                           <li id="personal" class="">
                              <a href="javascript:void();">
                              <i class="ri-user-fill"></i><span>Personal</span>
                              </a>
                           </li>
                           <li id="payment">
                              <a href="javascript:void();">
                              <i class="ri-camera-fill"></i><span>Image</span>
                              </a>
                           </li>
                           <li id="confirm">
                              <a href="javascript:void();">
                              <i class="ri-check-fill"></i><span>Finish</span>
                              </a>
                           </li>
                        </ul> -->
                        <!-- fieldsets -->
                        <fieldset v-if="step == 1" style="position: relative; opacity: 1;">
                           <div class="form-card text-left">
                              <div class="row">
                                 <div class="col-7">
                                    <h3 class="mb-4">New Branch Information:</h3>
                                 </div>
                                 <div class="col-5">
                                    <h2 class="steps">Step 1 - 3</h2>
                                 </div>
                              </div>
                              <div class="m-4">
                              <div v-if="error" class="alert alert-danger">{{ error }}</div>
                              </div>                                    
                              <div class="row">
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Which type of an organization is this? * </label>
                                       <select class="form-control" v-model="typeId" name="parentId" id="parentId">
                                          <option selected>Select</option>
                                          <option v-for="organizationType in organizationTypes"  :value="organizationType.id" :key="organizationType.id">{{ organizationType.name }}</option>
                                       
                                       </select>
                                    </div>
                                 </div>                                        
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Is this branch a health facility? *</label>
                                       <select v-model="selectFacilityType" class="form-control">
                                       <option disabled="" value="">Select</option>
                                       <option value="yes">Yes</option>
                                       <option value="no">No</option>
                                       </select>
                                    </div>
                                 </div>
                                 <div v-if="selectFacilityType == 'yes'" class="col-md-6">
                                    <div class="form-group">
                                       <label>If yes, please select type: </label>
                                       <select class="form-control" v-model="healthfacilityId" name="parentId" id="parentId">
                                          <option unselected>Select</option>
                                          <option v-for="healthFacilitie in healthFacilities" :value="healthFacilitie.id" :key="healthFacilitie.id">{{ healthFacilitie.name}}</option>
                                       
                                       </select>
                                    </div>
                                 </div>
                                 
                              </div>
                           </div>
                           <button type="submit" :disabled="isSubmitDisabled" @click.prevent="next()" name="next" class="btn btn-primary next action-button float-right" value="Next">Next</button>
                        </fieldset>
                        <fieldset v-if="step == 2" style="position: relative; opacity: 1;">
                           <div class="form-card text-left">
                              <div class="row">
                                 <div class="col-7">
                                    <h3 class="mb-4">New Branch Information:</h3>
                                 </div>
                                 <div class="col-5">
                                    <h2 class="steps">Step 1 - 4</h2>
                                 </div>
                              </div>
                              <div class="m-4">
                              <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>
                              <!-- <div v-if="message" class="alert alert-danger">{{ message }}</div> -->
                              <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                              </div>                                    
                              <div class="row">
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Name:*</label>
                                       <input type="text" class="form-control" v-model="name" name="name" id="name" placeholder="Enter Name">
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Country: *</label>
                                       <select @change="getRegions" class="form-control" v-model="selectedCountry" name="countryId" id="countryId">
                                          <option v-for="country in countries" v-bind:value="country.id" :key="country.id">{{ country.name }}</option>
                                       
                                       </select>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Region: *</label>
                                       <select  @change="getTowns" :disabled="!selectedCountry" class="form-control" v-model="selectedRegion" name="regionId" >
                                          <option unselected>Select</option>
                                          <option  v-for="region in regions"  :key="region.id" :value="region.id">{{ region.name }}</option>
                                          
                                       </select>
                                    </div>
                                 </div> 
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Town: *</label>
                                       <select @change="checkOther" :disabled="!selectedRegion" class="form-control" v-model="selectedTown" name="townId" id="townId">
                                          <option unselected>Select</option>
                                          <option v-for="town in towns" :value="town.id" :key="town.id">{{ town.name }}</option>

                                       </select>
                                    </div>
                                 </div>  
                                 <div class="col-md-6" :hidden="!others">
                                    <div class="form-group">
                                       <label>Town: </label>
                                       <input  type="text" class="form-control" v-model="town" name="town" id="town" placeholder="Enter Town Name">

                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Address: </label>
                                       <input type="text" class="form-control" v-model="address" name="address" id="address" placeholder="Enter Address">

                                    </div>
                                 </div>  
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Contact Person: </label>
                                       <input type="text" class="form-control" v-model="contact_person" name="contact_person" id="contact_person" placeholder="Enter Contact Person">

                                    </div>
                                 </div> 
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Email: </label>
                                       <input type="email" class="form-control" v-model="email" name="email" id="email" placeholder="Enter Email Address">

                                    </div>
                                 </div> 
                                 <div class="col-md-6">
                                    <div class="form-group">
                                       <label>Phone Number: </label>
                                       <input type="text" class="form-control" v-model="phone_no" name="phone_no" id="phone_no" placeholder="Enter Phone Number">

                                    </div>
                                 </div>                                                                              
                              </div>
                           </div>
                           <button type="submit" name="next" class="btn btn-primary next action-button float-right" value="Submit">Submit</button>
                           <button type="button" @click="prev()" name="previous" class="btn btn-dark previous action-button-previous float-right mr-3" value="Previous">Previous</button>
                        </fieldset>
                        <fieldset v-if="step == 4">
                           <div class="form-card">
                              <div class="row">
                                 <div class="col-7">
                                    <h3 class="mb-4 text-left">Finish:</h3>
                                 </div>
                                 <div class="col-5">
                                    <h2 class="steps">Step 3 - 3</h2>
                                 </div>
                              </div>
                              <br><br>
                              <h2 class="text-success text-center"><strong>SUCCESS !</strong></h2>
                              <br>
                              <div class="row justify-content-center">
                                 <div class="col-3"> <img src="images/page-img/img-success.png" class="fit-image" alt="fit-image"> </div>
                              </div>
                              <br><br>
                              <div class="row justify-content-center">
                                 <div class="col-7 text-center">
                                    <h5 class="purple-text text-center">You Have Successfully Signed Up</h5>
                                 </div>
                              </div>
                           </div>
                        </fieldset>
                     </form>
                  </div>
               </div>
            </div>
         </div>
        </appComp>
    </div>
 </template>
 <script>
 import appComp from '@/components/appComp';
 import axios from 'axios';
 import Swal from 'sweetalert2'
 
 const toast = Swal.mixin({
     toast: true,
     position: 'top-end',
     showConfirmButton: false,
     timer: 3000
 });
 
 window.toast = toast;
 
 export default {
   data() {
      return {
          id: '',
          name: '',
          typeId: '',
          parentId: this.$route.params.id,
          healthfacilityId: '',         
          countryId: '',
          regionId: '',
          townId: '',
          town: '',
          address: '',
          contact_person: '',
          email: '',
          phone_no: '',
          countries: [],
          organizationTypes: [],
          healthFacilities: [],
          organizations: [],
          organization: [],
 
          selectFacilityType: '',

          // messages 
          message : [],
          successMessage : "",
 
 
          regions: [],
          towns: [],
          selectedCountry: '',
          selectedRegion: '',
          selectedTown: '',
          others : false,
 
         step: 1, 
         isSubmitDisabled: false,
         error: ''
 
 
      }
   },
 
 
   components: {
      appComp
   },
   computed: {
     
   },
   methods: {
      prev() {
      this.step--;
      },
      next() {
         // this.step++;
         // Validate the form data step 1
         if (!this.selectFacilityType) {
         this.error = 'Please fill in all fields.';
         }
         else if(this.selectFacilityType == 'yes' && !this.healthfacilityId) {
            this.error = 'Please fill in all fields.';
         } 
         else if(this.selectFacilityType == 'no' && !this.typeId){
            this.error = 'Please fill in all fields.';
         }
         else if(this.selectFacilityType == 'yes' && this.healthfacilityId !== null) {
            this.step++;
            this.error = '';
         }
         else if(this.selectFacilityType == 'no' && this.typeId !== null){
            this.healthfacilityId = null;
            this.step++;
            this.error = '';
         }       
      },     
    async getRegions() {
       try {
         const selectedCountry = this.countries.find(country => country.id === this.selectedCountry);
         this.regions = selectedCountry.regions;
         this.selectedRegion = '';
         this.selectedTown = '';
       } catch (error) {
         console.error(error);
       }
     },
 
     async getTowns() {
       try {
         const selectedRegion = this.regions.find(region => region.id === this.selectedRegion);
         this.towns = selectedRegion.towns;
         this.selectedTown = '';
       } catch (error) {
         console.error(error);
       }
     },
 
     checkOther()
     {
       const other = this.towns.find(town => town.id === this.selectedTown);
       if(other.name == "Other")
       {
          this.others = true;
       }else{
          this.others = false;
       }
       
 
     },
 
     handleForm()
     {
 
       this.message = []
 
       this.form = {
          name: this.name,
          parentId: this.parentId,
          contactPerson: this.contact_person,
          countryId: this.selectedCountry,
          date: this.date,
          address: this.address,
          typeId: this.typeId,
          healthFacilityTypeId: this.healthfacilityId,
          msisdn: this.phone_no,
          townId: this.selectedTown,
          email: this.email
          
       }
       // var instance = axios.create();
       delete axios.defaults.headers.common['X-CG-Authorization'];
       axios.post('/organizations',this.form, {
          headers : {
             'Authorization' : "Bearer "+localStorage.getItem('token'),
             'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
 
          }
       }).then((response) => {
          if(response.data.status == 'success')
          {
             this.resetForm();
             toast.fire(
                'Success!',
                'Branch created!',
                'success'
             )
             this.step = 1;
          }
       })  .catch((error) => {
                     let errors = error.response.data.data;
                     if (errors.length > 1) {
                         errors.forEach(err => {
                            this.message.push(err.message)
                         });
                     } else {
                         errors.forEach(err => {
                            this.message.push(err.message)
                     
                         });
                     }
                     
                 }).finally(() => {
                     this.loading = false
       });
      
       console.log(this.form)
     },
     resetForm(){
        this.message = [],
          this.name= '',
          this.typeId= '',
          this.parentId= '',
          this.healthfacilityId= '',
          this.selectedCountry= '',
          this.selectedRegion= '',
          this.selectedTown= '',
          this.town= '',
          this.address= '',
          this.contact_person= '',
          this.email= '',
          this.phone_no= ''
     },
     async getData(){
       delete axios.defaults.headers.common['Authorization'];
       axios.get("/lists", { headers: {
          
                 'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                 'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
       }},{
            }).then((response) => {
               this.countries = response.data.data.countries
               this.organizationTypes = response.data.data.organizationtypes
               this.healthFacilities = response.data.data.healthfacilitytypes
               this.channels = response.data.data.channeltypes
               console.log( response.data.data)
 
        });
     },
     getOrg() {
            delete axios.defaults.headers.common["Authorization"];
            axios
                .get(
                    "/organizations/" + this.$route.params.id,
                    {
                        headers: {
                            "X-CG-Authorization": `Bearer ${localStorage.getItem("token")}`,
                            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
                        },
                    },
                    {}
                )
                .then((response) => {
                    this.organization = response.data.data.items[0];
                    this.country = response.data.data.items[0].country.name;
                    this.region = response.data.data.items[0].region.name;
                    this.town = response.data.data.items[0].town.name;
                    this.type = response.data.data.items[0].type.name;
                    this.status = response.data.data.items[0].status.name;
                    this.healthfacilitytype = response.data.data.items[0].heathFacilityType.name;
                    console.log("data", this.organization.name);
                });
        },
 
 
 
 
 
   },
   mounted() {
     this.getData();
     this.getOrg();
     delete axios.defaults.headers.common['X-CG-Authorization'];
       axios.get('/organizations', {
          headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
          },
       }).then((response) => {
          this.organizations = response.data.data.items
       });
       console.log(this.organizations);
       //  axios.get('/lists').then(console.log)
   },
  
 
 }
 </script>
 <style lang="">
    
 </style>