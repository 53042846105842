<template lang="">
  <div>
    <appComp>
      <div class="row">
         <!-- start -->
        <div class="col-lg-4">
          <div class="iq-card">
            <div class="iq-card-body pl-0 pr-0 pt-0">
              <div class="doctor-details-block">
                <div
                  class="doc-profile-bg bg-primary"
                  style="height: 150px"
                ></div>
                <div class="doctor-profile text-center">
                  <img
                    src="@/assets/images/corporation.png"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                </div>
                <div class="text-center mt-3 pl-3 pr-3">
                  <h4>
                   {{organization.name ?? "N/A"}}
                  </h4>
                </div>
                <hr />
                <ul
                  class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                >
                <li class="text-center">
                      <button type="submit" @click="viewProfiles" class="btn btn-primary float-center">Profiles</button>

                  </li>
                  <li class="text-center">
                      <button type="submit" @click="viewIdentities" class="btn btn-primary float-center">Identities</button>
                   
                  </li>
                  <li class="text-center">
                     <button  v-if="hasRole('global.admin') || hasRole('organization.admin') || hasRole('healthcare.facility.admin')" type="submit" @click="editOrg" class="btn btn-primary float-center">Edit</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- start -->
    
        <div class="col-lg-8">
          <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                 <div class="iq-header-title">
                    <h4 class="card-title">Organization Information</h4>
                 </div>
              </div>
              <div class="iq-card-body">
                 <div class="about-info m-0 p-0">
                    <div class="row">
                       <div class="col-4">Name:</div>
                       <div class="col-8">{{organization.name ?? "N/A"}}</div>
                       <div class="col-4">Contact Person:</div>
                       <div class="col-8">{{organization.contactPerson ?? "N/A"}}</div>
                       <div class="col-4">Phone:</div>
                       <div class="col-8">{{organization.msisdn ?? "N/A"}}</div>
                       <div class="col-4">Email:</div>
                       <div class="col-8"><a href="#"> {{organization.email ?? "N/A"}} </a></div>
                       <div class="col-4">Country:</div>
                       <div class="col-8"><a href="#">{{country ?? "N/A"}}</a></div>
                       <div class="col-4">Location:</div>
                       <div class="col-8"><a href="#">{{location ?? "N/A"}}</a></div>     
                       <div class="col-4">Region:</div>
                       <div class="col-8"><a href="#">{{region ?? "N/A"}}</a></div> 
                       <div class="col-4">Town:</div>
                       <div class="col-8"><a href="#">{{town ?? "N/A"}}</a></div> 
                       <div v-if="healthfacilitytype !== null" class="col-4">Health Facility Type:</div>
                       <div v-if="healthfacilitytype !== null" class="col-8"><a href="#">{{healthfacilitytype ?? "N/A"}}</a></div> 
                       <div class="col-4">Status:</div>
                       <div class="col-8"><a href="#">{{status ?? "N/A"}}</a></div>
                       <div class="col-4">Type:</div>
                       <div class="col-8"><a href="#">{{type ?? "N/A"}}</a></div>
                    </div>
                 </div>
              </div>
          </div>
        </div>
      </div>

      <!-- <div  class="col-md-12">
        <div class="iq-card">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title">Action</h4>
            </div>
          </div>
          <div class="iq-card-body">
            <div class="row">
                <div class="col-3">

                </div>
                <div class="col-8 row justify-center">
                    <div class="col-3">
                        <button type="submit" @click="viewProfiles" class="btn btn-primary float-left">View Profiles</button>
                    </div>
                    <div class="col-3">
                        <button type="submit" @click="viewIdentities" class="btn btn-primary float-center">View Identities</button>
                    </div>
                    <div class="col-3">
                        <button v-if="hasRole('global.admin') || hasRole('organization.admin')" type="submit" @click="editOrg" class="btn btn-primary float-right">Edit Org</button>
                    </div>
                </div>
                <div class="col-2">
                    
                </div>
            </div>
          </div>
        </div>
      </div> -->
    </appComp>
  </div>
</template>
<script>
import appComp from "@/components/appComp";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            organization: [],
            healthfacilitytype: '',
            country: '',
            region: '',
            town: '',
            status: '',
            type: '',
            roles: [],
        };
    },

    components: {
        appComp,
    },
    computed: {
        ...mapGetters({
            lists: 'auth/lists',
            user: 'auth/user',
            role: 'auth/role',
            current_profile: 'auth/profile'
        }),
        
    },
    methods: {
        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];

        },
        viewProfiles() {
            this.$router.push("/profiles/" + this.$route.params.id)
        },
        viewIdentities() {
            this.$router.push("/users/" + this.$route.params.id)
        },
        editOrg() {
            this.$router.push("/editorganization/" + this.$route.params.id)
        },
        getOrgs(){
          delete axios.defaults.headers.common['X-CG-Authorization'];
          axios.get('/organizations', {
              headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
              },
          }).then((response) => {
            this.organizations = response.data.data.items
            // console.log("orgs", this.organizations);
          });
        },
        getData() {
            delete axios.defaults.headers.common["Authorization"];
            axios
                .get(
                    "/organizations/" + this.$route.params.id,
                    {
                        headers: {
                            "X-CG-Authorization": `Bearer ${localStorage.getItem("token")}`,
                            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
                        },
                    },
                    {}
                )
                .then((response) => {
                    this.organizationId = response.data.data.items[0].id;
                    this.organization = response.data.data.items[0];
                    this.country = response.data.data.items[0].country.name;
                    this.region = response.data.data.items[0].region.name;
                    this.town = response.data.data.items[0].town.name;
                    this.type = response.data.data.items[0].type.name;
                    this.status = response.data.data.items[0].status.name;
                    this.healthfacilitytype = response.data.data.items[0].heathFacilityType.name;
                    // console.log(this.country);
                    //   this.organization = JSON.parse(JSON.stringify(this.organization))
                    // console.log("data", this.organization);
                });
                
        },
    },
    mounted() {
      this.getData();
      this.getRoles();
      this.getOrgs();

    },
};
</script>
<style lang="css" scoped>
div.col-6 p {
    word-wrap: break-word;
}
</style>
