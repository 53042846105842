<template lang="">
    <div>

    <div class="sign-in-detail text-white">
        <a class="sign-in-logo mb-5" href="#"><img src="@/assets/images/logo-white.png" class="img-fluid" alt="logo"></a>
        <div class="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
            <div class="item">
                <img src="@/assets/images/login/1.png" class="img-fluid mb-4" alt="logo">
                <h4 class="mb-1 text-white">Manage pregnancies</h4>
                <p>A comprehensive Maternal Health platform that is capable of delivering a comprehensive automated pre and post natal health journey.</p>
            </div>
            <div class="item">
                <img src="@/assets/images/login/2.png" class="img-fluid mb-4" alt="logo">
                <h4 class="mb-1 text-white">Manage pregnancies</h4>
                <p>It provides date and time appropriate messages based on a mother’s expected date of delivery and a baby’s date of birth.</p>
            </div>
            <div class="item">
                <img src="@/assets/images/login/3.png" class="img-fluid mb-4" alt="logo">
                <h4 class="mb-1 text-white">Manage pregnancies</h4>
                <p>It provides date and time appropriate messages based on a mother’s expected date of delivery and a baby’s date of birth.</p>
            </div>
        </div>
    </div>
                    
    </div>
</template>
<script>
export default {

}
</script>
<style lang="">
    
</style>