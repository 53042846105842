<template lang="">
    <div>
        <appComp>
         <!-- Start Custom Preloader -->
         <!-- <div v-if="isLoading" class="col-lg-12 iq-card text-center p-5">
            <div class="spinner-border text-primary" role="status">
               
            </div>
            <p>Loading</p>
         </div> -->
         <!-- End Custom Preloader -->
            <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-body p-0">
                           <div class="iq-edit-list">
                              <ul class="iq-edit-profile d-flex nav nav-pills">
                                 <li class="col-md-3 p-0">
                                    <a class="nav-link active" data-toggle="pill" href="#personal-information">
                                    Maternal Journey
                                    </a>
                                 </li>
                                 
                               
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                  <fieldset v-if="step == 1">
                  <div class="iq-card">
                  <div class="iq-card-header d-flex justify-content-between">
                     <div class="iq-header-title">
                        <h4 class="card-title">Create Pregnancy</h4>
                     </div>
                  </div>
                  <div class="iq-card-body">
                     <form @submit.prevent="submit()">
                        <div class="form-group row align-items-center">
                           <div class="col-md-12">
                              <div class="profile-img-edit">
                                 <img class="profile-pic" src="@/assets/images/user/11.png" alt="profile-pic">
                              </div>
                           </div>
                        </div>
                        <div class=" row align-items-center">
                           <!-- New Questions -->
                           <div class="form-group col-sm-6">
                                 <label>Are you registering your own pregnancy?</label>
                                 <select class="form-control" v-model="form.owner" id="typeId">
                                    <option disabled value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                 </select>
                           </div>

                              <div class="form-group col-sm-6" v-if="form.owner === 'no'" :key="form.owner">
                              <label>What is your relationship with the mother?</label>
                              <select required="" class="form-control" v-model="form.registrantTypeId" id="typeId">
                                 <option disabled>Select</option>
                                 <option v-for="registrant in pregnancyregistranttypes" :value="registrant.id" :key="registrant.id">{{ registrant.name }}</option>
                              </select>
                              </div> 
                                                           

                           <!-- New Questions -->
                           <div class="form-group col-sm-6">
                              <label>Is the child born already?</label>
                              <select required="" class="form-control" v-model="form.born" id="typeId">
                                 <option disabled value="">Select</option>
                                 <option value="yes">Yes</option>
                                 <option value="no">No</option>
                              </select>
                              </div>


                              <div class="form-group col-sm-6" v-if="form.born === 'no'" :key="form.born">
                              <label>Enter Expected Delivery Date:</label>
                              <input type="date" v-model="form.edd" class="form-control" >
                              </div>
                              
                              <div class="form-group col-sm-6">
                              <label>Enter Number of Children:</label>
                              <input required="" type="number" v-model="form.totalChildren" class="form-control" id="uname">
                              </div>                                                          

                              <div class="form-group col-sm-6" v-if="form.owner === 'no'" :key="form.owner">
                              <label>Is the mother registered at Tunza Toto?</label>
                              <select class="form-control" v-model="form.registered" id="typeId">
                                    <option disabled value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                 </select>
                              </div>

                              <!-- Register New Mother if not registered -->
                              <div class="form-group row col-lg-12" v-if="form.registered === 'no' && form.owner === 'no'" :key="form.registered">
                              <div class="form-group col-sm-3">
                              <label>Enter Mother Display Name:</label>
                              <input type="text" v-model="form.dname" class="form-control">
                              </div>
                              <div class="form-group col-sm-3">
                              <label>Enter Mother First Name:</label>
                              <input type="text" v-model="form.fname" class="form-control">
                              </div>
                              <div class="form-group col-sm-3">
                              <label>Enter Mother Last Name:</label>
                              <input type="text" v-model="form.lname" class="form-control">
                              </div>
                              <div class="form-group col-sm-3">
                              <label>Enter Mother Phone No:</label>
                              <input type="text" v-model="form.phone" class="form-control">
                              </div>
                              </div>

                              <div class="form-group col-sm-12" v-if="form.registered === 'yes' && form.owner === 'no'" :key="form.owner">
                              <label for="recipientSearch">Select Mother</label>
                                <input type="text" v-model="searchQuery" @input="filterProfiles" placeholder="Search Recipient..." class="form-control">
                                <div class="form-group col-sm-12" v-if="filteredProfiles.length">
                                  <ul>
                                    <li v-for="profile in filteredProfiles" :key="profile.id" @click="selectProfile(profile)">
                                      {{ profile.displayName }} | {{ profile.firstName }} {{ profile.lastName }} - {{ profile.msisdn }} | Locator Id: {{ profile.locatorId }}
                                    </li>
                                  </ul>
                                </div>
                            </div>

                            <div class="form-group col-sm-12">
                              <!-- Display user details or error message -->
                            <div v-if="userDetails" class="alert alert-success" role="alert">
                              {{ userDetails }}
                            </div>
                            <div v-if="errorMessage" class="alert alert-danger" role="alert">
                              {{ errorMessage }}
                            </div>
                          </div>

                        </div>
                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        
                     </form>
                  </div>
                  </div>
                  </fieldset>

                  </div>
               </div>
            </div>
        </appComp>
    </div>

</template>
<script>
import appComp from '@/components/appComp';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ElNotification } from 'element-plus';


export default {
   data() {
      return {
         step: 1,
         roles: [],
         profiles: [],
         user_id: [],
         isLoading: true, // Add loading state
         gender: "",
         organizations: [],
         pregnancyregistranttypes: [],
         pregnancyId: '',
         motherId: null,
         //Search for mother
         userDetails: null,
         errorMessage: '',
         searchQuery: '',
         filteredProfiles: [],
         //
         form: {
            registrantId: this.profileId,
            registrantTypeId: null,
            organizationId: '',
            totalChildren: '',
            locatorId: '',
            edd: '',
            born: 'no',
            owner: 'yes', // Set the default value of owner to 'no' or '',
            registered: 'yes',
            dname: '',
            fname: '',
            lname: '',
            phone: '',
         },
         profileOrganizationId: '',
      }
   },
   watch: {
      'form.owner': function (newValue) {
         if (newValue === 'yes') {
            // If the user selects "Yes," reset the registrant type
            this.form.registrantTypeId = null;
         }
      },
   },
   methods: {
      filterProfiles() {
         if (this.searchQuery) {
            const searchLowerCase = this.searchQuery.toLowerCase();
            this.filteredProfiles = this.profiles.filter(profile => {
               // Ensure displayName, firstName, and lastName are strings and check if they include the search query
               const nameCheck = [profile.displayName, profile.firstName, profile.lastName].some(name =>
                  typeof name === 'string' && name.toLowerCase().includes(searchLowerCase)
               );

               // Convert msisdn to a string if it's not already, then check if it includes the search query
               const msisdnCheck = typeof profile.msisdn === 'string'
                  ? profile.msisdn.includes(searchLowerCase)
                  : typeof profile.msisdn === 'number'
                     ? profile.msisdn.toString().includes(searchLowerCase)
                     : false;

               // Convert wallet.id to a string and check if it includes the search query
               const locatorIdCheck = profile.locatorId.toString().includes(searchLowerCase);

               // Check if gender is female
               const isFemale = profile.gender.name && profile.gender.name.toLowerCase() === 'female';

               // Return true only if name, msisdn, or locatorId match the search query AND the user is female
               return (nameCheck || msisdnCheck || locatorIdCheck) && isFemale;
            });
         } else {
            // If there's no search query, simply filter female users
            this.filteredProfiles = this.profiles.filter(profile => profile.gender.name && profile.gender.name.toLowerCase() === 'female');
         }
      },

      selectProfile(profile) {
         this.motherId = profile.id;
         // Close the dropdown or clear the search
         this.searchQuery = '';
         this.filteredProfiles = [];
         // Optional: Display the selected profile's details
         this.userDetails = `${profile.displayName} | ${profile.firstName} ${profile.lastName} - ${profile.msisdn} | Locator ID: ${profile.locatorId}`;
      },
      // Add this new method to load profiles from localStorage
      loadProfiles() {
         const profilesFromStorage = localStorage.getItem('allProfiles');
         if (profilesFromStorage) {
            try {
               this.profiles = JSON.parse(profilesFromStorage);
               // this.isProfilesLoaded = true; // Assuming you want to track when profiles are loaded
            } catch (e) {
               console.error('Error parsing profiles from localStorage', e);
               // this.errorMessage = 'Failed to load profiles. Please try again.';
            }
         } else {
            console.log('No profiles found in localStorage');
            // this.errorMessage = 'No profiles available. Please add profiles.';
         }
      },
      checkWalletId() {
         const allProfiles = JSON.parse(localStorage.getItem('allProfiles'));
         const foundProfile = allProfiles.find(profile => profile.wallet.id === this.form.creditWalletId);

         if (foundProfile) {
            // Set user details for display
            this.userDetails = `Display Name: ${foundProfile.displayName}, Locator ID: ${foundProfile.locatorId}, Phone: ${foundProfile.msisdn}`;
            this.errorMessage = ''; // Clear any previous error message
         } else {
            // Clear previous user details and set error message
            this.userDetails = null;
            this.errorMessage = 'Sorry! User with that wallet ID does not exist! Try Again.';
         }
      },
      // getProfiles() {
      //    this.profiles = this.user.profiles;
      //    // console.log("user profiles here ccv", this.profiles)
      // },
      getUserData() {
         this.isLoading = true; // Set isLoading to true before making the API call
         delete axios.defaults.headers.common['X-CG-Authorization'];
         axios.get('/organizations/' + this.profileOrganizationId + '/profiles', {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
         }).then((response) => {
            this.profiles = response.data.data.items;
            // console.log(" profile id", this.profileId);
            // console.log(" was here", this.profiles);

            // Filter the profiles array to find the profile with the matching profileId
            const filteredProfile = this.profiles.find(profile => profile.id === this.profileId);

            if (filteredProfile) {
               // The filteredProfile variable now contains the profile information for this.profileId
               // console.log("Enock Filtered Profile:", filteredProfile);
               this.gender = filteredProfile?.gender?.name;
               // console.log("Enock gender 222:", this.gender);

               if (!this.gender) {
                  // Show SweetAlert popup
                  Swal.fire({
                     title: 'Please Update Your Gender',
                     text: 'Before you can proceed, please update your gender in your profile settings.',
                     icon: 'warning',
                     showCancelButton: false,
                     showConfirmButton: true,
                     confirmButtonText: 'Go Back',
                  }).then((result) => {
                     if (result.isConfirmed) {
                        this.$router.push('/dashboard');
                     }
                  });
               }

            } else {
               // console.log("Enock Profile not found for profileId:", this.profileId);
               // Show SweetAlert popup
               Swal.fire({
                  title: 'Profile not found',
                  text: 'Kindly refresh your page or logout and login and try again!',
                  icon: 'warning',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: 'Go Back',
               }).then((result) => {
                  if (result.isConfirmed) {
                     this.$router.push('/dashboard');
                  }
               });
            }

            // Set isLoading to false once data is loaded
            this.isLoading = false;
         });
      },
      getRoles() {
         // this.roles = this.user.profiles[this.current_profile].organization.roles;
         this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
         this.user_id = this.user.profiles[this.current_profile].id;
         // console.log("user roles", this.roles)

      },
      hasRole(roleName) {
         return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      async getData() {
         delete axios.defaults.headers.common['Authorization'];
         axios.get("/lists", {
            headers: {

               'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

            }
         }, {
         }).then((response) => {
            this.countries = response.data.data.countries
            this.organizationTypes = response.data.data.organizationtypes
            this.pregnancyregistranttypes = response.data.data.pregnancyregistranttypes
            this.channels = response.data.data.channeltypes
            console.log("types", this.pregnancyregistranttypes)

         });
      },
      getOrg() {
         delete axios.defaults.headers.common['X-CG-Authorization'];
         // / get organizations
         axios.get('/organizations', {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
         }).then((response) => {
            this.organizations = response.data.data.items
         });
      },

      //New submit
      submit() {
         // Create a mother profile if registered === 'no'
         if (this.form.registered === 'no') {
            const createMotherProfile = () => {
               return new Promise((resolve, reject) => {
                  const payload = {
                     displayName: this.form.dname,
                     firstName: this.form.fname,
                     lastName: this.form.lname,
                     msisdn: this.form.phone,
                     genderId: 2,
                     languageId: 1,
                     countryId: 1,
                     notificationChannelTypeId: 2,
                     roles: ['ke.co.cugi.mama.role.user'],
                     organizationId: 1,
                     literacyLevel: 6,
                  };

                  delete axios.defaults.headers.common['X-CG-Authorization'];

                  axios.post("/profiles", payload, {
                     headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                     },
                  }).then((response) => {
                     console.log("Created mother profile response", response.data.data.id);
                     // Set motherId to state
                     this.motherId = response.data.data.id;
                     console.log("Created mother id", this.motherId);

                     //Send Success Notification
                     ElNotification({
                        title: 'Success',
                        message: 'Mother profile created!',
                        type: 'success',
                        duration: 7000,
                     });

                     resolve(this.motherId); // Resolve the promise with motherId
                  }).catch((e) => {
                     let errors = e.response.data.data;

                     errors.forEach((err, index) => {
                        setTimeout(() => {
                           // Send Notification 
                           ElNotification({
                              title: 'Error ',
                              message: err.detail,
                              type: 'error',
                              duration: 7000,
                           });
                        }, index * 1000); // Adjust the delay time (in milliseconds) as needed
                     });

                     reject(e);

                  });
               });
            };

            // Introduce a delay of at least 5 seconds
            // setTimeout(() => {
            // Call createMotherProfile after the delay
            createMotherProfile().then((motherId) => {
               this.submitPregnancy(motherId); // Submit pregnancy after mother profile creation
            }).catch((error) => {
               console.error("Failed to create mother profile:", error);
               // Handle error if needed
            });
            // }, 5000); // 5 seconds delay
         } else {
            // If registered is not 'no', directly submit pregnancy
            this.submitPregnancy(this.motherId ?? this.profileId);
         }
      },


      submitPregnancy(motherId) {
         ElNotification({
            title: 'Submiting Pregnancy...',
            // message: 'Mother ID: ' + this.profileId + ' Registrant ID: ' + this.form.registrantTypeId,
            message: 'Creating your pregancy now...',
            type: 'success',
         });

         delete axios.defaults.headers.common['X-CG-Authorization'];
         const payload = {
            registrant: {
               id: this.profileId,
               typeId: this.form.registrantTypeId ?? 1,
            },
            mother: {
               id: motherId,
            },
            organizationId: this.profileOrganizationId,
            totalChildren: this.form.totalChildren
         };

         // Conditionally add expectedDeliveryDate
         if (this.form.edd) {
            payload.expectedDeliveryDate = this.form.edd;
         }

         axios.post("/pregnancies", payload, {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },

         }).then((response) => {
            this.pregnancyId = response.data.data.id;
            console.log("Created Pregnancy Data Aiden", response.data.data);

            if (response.data.status == 'success') {
               ElNotification({
                  title: 'Success',
                  message: 'Pregnancy created successfully!',
                  type: 'success',
                  duration: 7000,
               });

               setTimeout(() => {
               ElNotification({
                  title: 'Redirecting you...',
                  message: 'Redirecting you to pregnancies.',
                  type: 'warning',
                  duration: 9000,
               });
               }, 1000);

               setTimeout(() => {
               ElNotification({
                  title: 'Loading Pregnancies...',
                  message: 'Kindly wait as pregnancies load.',
                  type: 'info',
                  duration: 10000,
               });
               }, 2000);

               this.$router.push('/journeys');
            }
         }).catch((e) => {
            // console.log(e.response.data)
            let errors = e.response.data.data;

            errors.forEach((err, index) => {
               setTimeout(() => {
                  // Send Notification 
                  ElNotification({
                     title: 'Error ',
                     message: err.detail,
                     type: 'error',
                     duration: 7000,
                  });
               }, index * 1000); // Adjust the delay time (in milliseconds) as needed
            });

         });
      }

   },
   computed: {
      ...mapGetters({
         user: 'auth/user',
         current_role: 'auth/role',
         current_profile: 'auth/profile'
      }),
   },
   mounted() {
      this.profileId = this.user.profiles[this.current_profile].id;
      this.profileOrganizationId = this.user.profiles[this.current_profile].organization.id;
      // console.log(this.profileOrganizationId)
      // console.log(this.profileId)
      this.userData = this.user.profiles;
      // console.log("Monyancha User data", this.userData);
      this.loadProfiles()
      this.getRoles()
      this.getOrg()
      this.getData()
      this.getUserData()
   },
   components: {
      appComp
   },
}
</script>
