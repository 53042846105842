<template lang="">
        <div class="iq-sidebar">
              <div class="iq-sidebar-logo d-flex justify-content-between">
                 <a >
                 <!-- <img src="images/logo.png" class="img-fluid" alt=""> -->
                 <span>Tunza Toto</span>
                 </a>
                 <div class="iq-menu-bt-sidebar">
                    <div class="iq-menu-bt align-self-center">
                       <div class="wrapper-menu">
                          <div class="main-circle"><i class="ri-more-fill"></i></div>
                             <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
                       </div>
                    </div>
                 </div>
              </div>
              <div id="sidebar-scrollbar">
                 <nav class="iq-sidebar-menu">
                    <ul v-show="hasRole('user') " id="iq-sidebar-toggle" class="iq-menu">
                    <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Dashboard </span></li>
                    <router-link :to="{name : 'dashboard'}">
                    <li>
                       <a href="#" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span>Dashboard</span></a>
                    </li> 
                  </router-link>                    
                  </ul>
                    <ul v-show="hasRole('organization.admin') || hasRole('organization.user') || hasRole('healthcare.facility.admin') || hasRole('healthcare.facility.user') " id="iq-sidebar-toggle" class="iq-menu">
                    <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Dashboard </span></li>
                    <router-link :to="{name : 'profiledashboard'}">
                    <li>
                       <a href="#" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span>Dashboard</span></a>
                    </li> 
                  </router-link>                    
                  </ul>                    
                
                  <!--  admin nav items -->
                  <ul v-if="hasRole('global.admin') || hasRole('global.auditor') || hasRole('global.helpdeskAdmin')" id="iq-sidebar-toggle" class="iq-menu">
                     
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span v-if="hasRole('global.admin')">Admin</span> <span v-if="hasRole('global.auditor')">Auditor</span> <span v-if="hasRole('global.helpdeskAdmin')">Helpdesk</span></li>
                     <li>
                        <a href="#mailbox" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-hospital-fill"></i><span>Organizations</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="mailbox" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           <router-link class="iq-waves-effect" :to="{name : 'organizations'}">
                           <i class="ri-hospital-fill"></i> All Organizations
                           </router-link>
                           
                           <router-link v-if="hasRole('global.admin')" class="iq-waves-effect" :to="{name : 'createorganization'}">
                              <i class="ri-user-add-fill"></i> Add Organization
                           </router-link>
                     
                        </ul>
                     </li>
                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToAdminPayments">
                     <i class="ri-wallet-3-fill"></i> All Payments
                     </a>
                     </li> 
                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToChronicPage">
                     <i class="ri-hospital-fill"></i> Chronic Diseases
                     </a>
                     </li>                    
                     <!--<li v-if="hasRole('global.admin')">
                        <a href="#user-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-user-3-fill"></i><span>Users</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="user-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                        
                           <router-link class="iq-waves-effect" :to="{name : 'createuser'}">
                           <i class="ri-user-add-fill"></i> Add User
                           </router-link>
                        </ul>
                     </li>-->

                     <li v-if="hasRole('global.admin')">
                        <a href="#profile-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-user-3-fill"></i><span>Profiles</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="profile-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           
                           <router-link class="iq-waves-effect" :to="{name : 'createprofile'}">
                           <i class="ri-user-add-fill"></i> Add Profile
                           </router-link>
                  
                        </ul>
                     </li>
                     
                  </ul>
                  
                  <!-- auditor nav items -->
                  
                  

                  <!-- organization admin nav items -->
                  <ul v-show="hasRole('organization.admin') " id="iq-sidebar-toggle" class="iq-menu">
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Organization Admin</span></li>
                    
                     <li>
                        <a href="#" @click="goToOrg" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span>Info</span></a>
                     </li> 
                     <li>
                        <a href="#" @click="goToOrgProfiles" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span> Profiles</span></a>
                     </li> 
                                 
                  
                     <li>
                        <a @click="goToOrgIdentities" href="#" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span> Identities</span></a>
                     </li> 

                     <li>
                        <a href="#profile-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-user-3-fill"></i><span>Profiles</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="profile-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           
                           <router-link class="iq-waves-effect" :to="{name : 'createorgprofile'}">
                           <i class="ri-user-add-fill"></i> Add Profile
                           </router-link>
                  
                        </ul>
                     </li>

                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToAdminPayments">
                     <i class="ri-wallet-3-fill"></i> All Payments
                     </a>
                     </li>                     
                                   
                  </ul>

                  <!-- health facility admin nav items -->
                  <ul v-show="hasRole('healthcare.facility.admin') " id="iq-sidebar-toggle" class="iq-menu">
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Health Facility Admin</span></li>
                    
                     <li>
                        <a href="#" @click="goToOrg" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span> Info</span></a>
                     </li> 
                     <li>
                        <a href="#" @click="goToOrgProfiles" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span> Profiles</span></a>
                     </li> 
                                 
                  
                     <li>
                        <a @click="goToOrgIdentities" href="#" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span> Identities</span></a>
                     </li> 

                     <li>
                        <a href="#profile-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-user-3-fill"></i><span>Profiles</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="profile-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           
                           <router-link class="iq-waves-effect" :to="{name : 'createorgprofile'}">
                           <i class="ri-user-add-fill"></i> Add Profile
                           </router-link>
                        </ul>
                     </li>

                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToAdminPayments">
                     <i class="ri-wallet-3-fill"></i> All Payments
                     </a>
                     </li>                     
                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToChronicPage">
                     <i class="ri-hospital-fill"></i> Chronic Diseases
                     </a>
                     </li>              
                  </ul>                  

                  <!-- healthfacility user nav items -->
                  <ul v-show="hasRole('healthcare.facility.user') " id="iq-sidebar-toggle" class="iq-menu">
                     
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Healthcare Facility User</span></li>


                     <li>
                        <a href="#journey" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-hospital-fill"></i><span>Maternal Journey</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="journey" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           <router-link class="iq-waves-effect" :to="{name : 'journeys'}" >
                           <i class="ri-hospital-fill"></i> All Pregnancies
                           </router-link>
                           
                           <router-link class="iq-waves-effect" :to="{name : 'journey'}">
                              <i class="ri-user-add-fill"></i> New Pregnancy
                           </router-link>
                     
                        </ul>
                     </li>

                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToChronicPage">
                     <i class="ri-hospital-fill"></i> Chronic Diseases
                     </a>
                     </li>
                  
                     <!-- <li>

                     <a href="#" class="iq-waves-effect" @click="goToUpdateProfile">
                     <i class="ri-user-add-fill"></i> Update Profile
                     </a>
                        
                     </li> -->

                     
                  </ul>

                  <!-- wallet admin -->
                  <ul v-show="hasRole('walletAdmin') " id="iq-sidebar-toggle" class="iq-menu">
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Wallet Admin</span></li>
                    
<!--                      <li>
                        <a href="#" @click="goToWallet" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span>Wallet</span></a>
                     </li> 
                     <li>
                        <a href="#" @click="goToProfWallets" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span> Profile Wallets</span></a>
                     </li> 
                                 
                  
                     <li>
                        <a @click="goToOrgPayments" href="#" class="iq-waves-effect"><i class="ri-hospital-fill"></i><span> Payments</span></a>
                     </li> 

                     <li>
                        <a href="#profile-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-user-3-fill"></i><span>Profiles</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="profile-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           
                           <router-link class="iq-waves-effect" :to="{name : 'createorgprofile'}">
                           <i class="ri-user-add-fill"></i> Add Profile
                           </router-link>
                  
                        </ul>
                     </li> -->

                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToWalletPayments">
                     <i class="ri-wallet-3-fill"></i> All Payments
                     </a>
                     </li>                     
                                   
                  </ul>

                  <!-- org user nav items -->
                  <ul v-show="hasRole('organization.user') " id="iq-sidebar-toggle" class="iq-menu">
                     
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Organization User</span></li>


                     <li>
                        <a href="#journey" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-hospital-fill"></i><span>Maternal Journey</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="journey" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           <router-link class="iq-waves-effect" :to="{name : 'journeys'}" >
                           <i class="ri-hospital-fill"></i> All Pregnancies
                           </router-link>
                           
                           <router-link class="iq-waves-effect" :to="{name : 'journey'}">
                              <i class="ri-user-add-fill"></i> New Pregnancy
                           </router-link>
                     
                        </ul>
                     </li>
   
                  
                     <!-- <li>

                     <a href="#" class="iq-waves-effect" @click="goToUpdateProfile">
                     <i class="ri-user-add-fill"></i> Update Profile
                     </a>
                        
                     </li> -->

                     
                  </ul>

                  <!-- user nav items -->
                  <ul v-show="hasRole('user') " id="iq-sidebar-toggle" class="iq-menu">
                     
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>User</span></li>


                     <li>
                        <a href="#journey" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-hospital-fill"></i><span>Maternal Journey</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="journey" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           <router-link class="iq-waves-effect" :to="{name : 'journeys'}" >
                           <i class="ri-hospital-fill"></i> All Pregnancies
                           </router-link>
                           
                           <router-link class="iq-waves-effect" :to="{name : 'journey'}">
                              <i class="ri-user-add-fill"></i> New Pregnancy
                           </router-link>
                     
                        </ul>
                     </li>

                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToPayments">
                     <i class="ri-wallet-3-fill"></i> All Payments
                     </a>
                     </li>   
                     
                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToChronicPage">
                     <i class="ri-hospital-fill"></i> Chronic Diseases
                     </a>
                     </li>  
   
                  
                     <li>

                     <a href="#" class="iq-waves-effect" @click="goToUpdateProfile">
                     <i class="ri-user-add-fill"></i> Update Profile
                     </a>
                        
                     </li>

                     
                  </ul>

                  <!-- Payments -->
<!--                   <ul  id="iq-sidebar-toggle" class="iq-menu">
                     
                     <li class="iq-menu-title"><i class="ri-subtract-line"></i><span>Payments</span></li>

                     <li>
                     <a href="#" class="iq-waves-effect" @click="goToPayments">
                     <i class="ri-wallet-3-fill"></i> All Payments
                     </a>
                     </li>

                     
                  </ul> -->
                  
                
                 </nav>
                 <div class="p-3"></div>
              </div>
         </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
   data() {
      return {
         roles: [],
         name: '',
         currentUser: []

      }
   },
   computed: {
      ...mapGetters({
         user: 'auth/user',
         role: 'auth/role',
         current_profile: 'auth/profile'
      }),
      routeName() {
         return this.$route.name
      },
   },
   methods: {

      hasRole(roleName) {
         return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      getRoles() {
         // this.roles = this.user.profiles[this.current_profile].organization.roles;
         this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
         // console.log("rolika",this.roles)
      },
      goToWallet()
      {

      } ,
      goToProfWallets()
      {

      },
      goToOrgPayments()
      {

      },
      //Go to payments
      goToPayments() {
         this.$router.push("/payments/")
      },
      //goToChronicPage
      goToChronicPage() {
         this.$router.push("/chronic-diseases/")
      },
      goToWalletPayments() {
         this.$router.push("/adminpayments/")
      },
      goToAdminPayments() {
         this.$router.push("/adminpayments/")
      },      
      goToOrg() {
         let org_id = this.user.profiles[this.current_profile].organization.id;
         this.$router.push("/vieworganization/" + org_id)
      },
      goToUpdateProfile() {
         let user_id = this.user.profiles[this.current_profile].id;
         let org_id = this.user.profiles[this.current_profile].organization.id;
         this.$router.push("/updateprofile/" + org_id + "/" + user_id + "/1")
      },
      goToOrgProfiles() {
         let org_id = this.user.profiles[this.current_profile].organization.id;
         this.$router.push("/profiles/" + org_id)
      },
      goToOrgIdentities() {
         let org_id = this.user.profiles[this.current_profile].organization.id;
         this.$router.push("/users/" + org_id)
      },

   },
   mounted() {
      this.getRoles();
      // console.log(this.current_profile)
      // this.currentUser = this.user.profiles[this.current_profile]
      // console.log("currentUser", this.currentUser)

   }

}

</script>
<style lang="">
    
</style>