import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
require('@/store/subscriber');
//Element UI https://element-plus.org/
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/element/index.scss'


store.dispatch('auth/saveUser').then(() => {

    createApp(App)
        .use(router)
        .use(store)
        .use(ElementPlus)
        .mount('#app')
})


