<template lang="">
    <div>
        <form @submit.prevent>
            <div class=" row align-items-center">
                 <div class="form-group col-sm-6">
                     <label for="fname">Allergies:</label>
                     <select class="form-control" multiple v-model="form.selectedAllergies" name="allergies" id="allergies">
                         <option disabled value="">Select</option>
                         <option v-for="allergy in allergies" :value="allergy.id" :key="allergy.id">{{ allergy.name}}</option>
                     
                     </select>
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="uname">Medical Condition(s):</label>
                     <select class="form-control" multiple v-model="form.selectedMedicalConditions" name="allergies" id="allergies">
                         <option disabled value="">Select</option>
                         <option v-for="condition in medicalconditions" :value="condition.id" :key="condition.id">{{ condition.name}}</option>
                     
                     </select>
                 </div>
                 <!-- <div class="form-group col-sm-6">
                     <label for="cname">Status:</label>
                     <select class="form-control" v-model="form.selectedMedicalConditions" name="allergies" id="allergies">
                         <option disabled value="">Select</option>
                         <option v-for="condition in medicalconditions" :value="condition.id" :key="condition.id">{{ condition.name}}</option>
                     
                     </select>
                 </div> -->
                 
                 
                 <!-- <div class="form-group col-sm-6">
                     <label for="lname">Terminations less than 24 Weeks:</label>
                     <input type="text" class="form-control" id="lname" >
                 </div> -->
                 <!-- <div class="form-group col-md-6">
                     <label for="regionId">Region:</label>
                     <select  class="form-control"  name="regionId" >
                         <option unselected>Select</option>
                         <option >hey</option>
                         
                     </select>
                 </div> -->
                 
             </div>
             <div class="row">
                <div class="col-6">

                    <button type="reset" @click="previous" class="btn iq-bg-danger">Back</button>
                    
                </div>
                <div class="col-6  d-flex justify-content-end d-flex justify-content-end">


                    <button type="submit" @click="handleSubmit" class="btn btn-primary mr-2">Submit</button>
                </div>

               </div>
         </form>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return {
            allergies: [],
            medicalconditions: [],
            form: {
                selectedAllergies: []
            }
        }
    },
    methods: {
    handleSubmit() {


        alert('submit the data then next tab')

     this.$emit('handle-submit', 4);
    },

    previous() {
        
        this.$emit('handle-submit', 2);
    },
    async getData(){
      delete axios.defaults.headers.common['Authorization'];
      axios.get("/lists", { headers: {
         
                'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
           
      }},{
           }).then((response) => {
              this.countries = response.data.data.countries
              this.organizationTypes = response.data.data.organizationtypes
              this.healthFacilities = response.data.data.healthfacilitytypes
              this.channels = response.data.data.channeltypes
              this.allergies = response.data.data.allergies
              this.medicalconditions = response.data.data.medicalconditions
              console.log( response.data.data)

       });
    }
  },
  mounted(){
    this.getData();
  }
}
</script>
<style lang="">
    
</style>