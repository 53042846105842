<template lang="">
    <div>
        <appComp>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-12">
                  <fieldset>
                  <div class="iq-card">
                  <div class="iq-card-header d-flex justify-content-between">
                     <div class="iq-header-title">
                        <h4 class="card-title">Immunization Information</h4>
                     </div>
                  </div>
                  <div class="iq-card-body">
                     <form>
                        <div class=" row align-items-center">
                           <div class="form-group col-sm-6">
                              <label for="fname">Organization:</label>
                              <select class="form-control" v-model="form.organizationId" name="organizationId" id="organizationId">
                                 <option disabled>Select</option>
                                 <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{ organization.name}}</option>
                                 
                              </select>
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="uname">Vaccine:</label>
                              <select class="form-control" v-model="form.vaccineId" name="allergies" id="allergies">
                                 <option disabled value="">Select</option>
                                 <option v-for="vaccine in vaccines" :value="vaccine.id" :key="vaccine.id">{{ vaccine.name}}</option>
                              
                              </select>
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="uname">Immunization Date:</label>
                              <input type="date" class="form-control" id="uname" value="">
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="cname">Status:</label>
                              <select class="form-control" v-model="form.statusId" name="allergies" id="allergies">
                                 <option disabled value="">Select</option>
                                 <option v-for="status in statuses" :value="status.id" :key="status.id">{{ status.name}}</option>
                              
                              </select>
                           </div>
                           
                           <div class="form-group col-sm-6">
                              <label for="cname">Comments:</label>
                              <input type="text" class="form-control" id="cname" value="Atlanta">
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="lname">Comments:</label>
                              <input type="text" class="form-control" id="lname" value="Jets">
                           </div>

                        </div>
                        <!-- <button @click="prev()" type="reset" class="btn btn-dark mr-2">Previous</button> -->
                        <button @click="submit()" type="submit" class="btn btn-primary mr-2">Submit</button>

                     </form>
                  </div>
                  </div>
                  </fieldset>

                  </div>
               </div>
            </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';

export default{
    data(){
      return {
        vaccines: [],
        statuses: [],
        organizations: [],
        commenttype: [],
        commentvisibilitytype: [],
        form: {
         organizationId: '',
         statusId: '',
         vaccineId: '',
         immunizationDate: '',
        } 
      }
    },
    methods: {
       prev() {
        this.$router.push('/maternal');
       },
       submit(){
         this.$router.push('/comments');
       },
       getOrg() {
         delete axios.defaults.headers.common['X-CG-Authorization'];
         // / get organizations
         axios.get('/organizations', {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
         }).then((response) => {
            console.log("org", response)
            this.organizations = response.data.data.items
            

         });
      },
      async loadLists(){
         delete axios.defaults.headers.common['Authorization'];
         axios.get("/lists", { headers: {
            
                  'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
         }},{
            }).then((response) => {
               this.statuses = response.data.data.status
               this.vaccines = response.data.data.vaccines.child
               this.commenttype = response.data.data.commenttype
               this.commentvisibilitytype = response.data.data.commentvisibilitytype
               console.log( response)

         });
      },
    },
    components: {
        appComp
    },
    mounted(){
      this.getOrg();
      this.loadLists();
    }
}
</script>