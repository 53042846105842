<template lang="">
    <div>
      <appComp>
        <!-- Start Custom Preloader -->
        <div  v-if="isLoading" class="iq-card p-5 text-center">
            <div class="spinner-border text-primary" role="status">
            </div>
        </div>
        <!-- End Custom Preloader -->
        <div v-else>
        <div class="row">
          <!-- start -->
          <div class="col-lg-4">
            <div class="iq-card">
              <div class="iq-card-body pl-0 pr-0 pt-0">
                <div class="doctor-details-block">
                  <div
                    class="doc-profile-bg bg-primary"
                    style="height: 150px"
                  ></div>
                  <div class="doctor-profile text-center">
                    <img
                      src="@/assets/images/user/11.png"
                      alt="profile-img"
                      class="avatar-130 img-fluid"
                    />
                  </div>
                  <div class="text-center mt-3 pl-3 pr-3">
                    <h4>
                      <b>{{ childInfo.displayName ?? 'N/A' }}</b>
                    </h4>
                  </div>
                  <hr />
                  <ul
                    class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                  >
                    <li class="text-center">
                      <a @click="navigateTO('/childvisits/' + childInfo.id + '?pregnancyId=' + (pregnancyId || ''))" class="btn btn-outline-success float-center text-success">Clinic Visits</a>
                    </li>
                    <li v-show="hasRole('healthcare.facility.user') " class="text-center">
                      <a href="#edit" class="btn btn-primary float-center text-white">Edit</a>
                    </li>
                    <li class="text-center">
                      <a @click="navigateTO('/childimmunizations/' + childInfo.id + '?pregnancyId=' + (pregnancyId || ''))" class="btn btn-outline-danger float-center text-danger">Immunizations</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- start -->
  
          <div class="col-lg-8">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">{{ childInfo.displayName ?? 'N/A' }} Profile</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <div class="about-info m-0 p-0">
                  <div class="row">
                    <!-- first row -->
                    <div class="col-12 row">
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">First Name :</h6>
                        </div>
                        <div class="col-6">
                          <p  class="font-12">{{ childInfo.firstName ?? 'N/A'}}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Last Name :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ childInfo.lastName ?? 'N/A' }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <!-- <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Gender :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ childInfo.gender?.name ?? 'N/A' }}</p>
                        </div>
                      </div> -->
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Date of Birth :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ childInfo.birthDate ?? 'N/A'}}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Locator ID :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ childInfo.locatorId ?? 'N/A'}}</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="hasRole('healthcare.facility.user') " class="row" id="edit">
              <!-- Edit Child Info -->
              <div class="col-lg-12">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">Edit {{ childInfo.displayName ?? 'N/A' }} Information</h4>
                  </div>
              </div>
              <div class="iq-card-body">
                  <div class="new-user-info">
                    <form>
                        <div class="row">
                          <div class="form-group col-md-6">
                              <label for="first_name">First Name:</label>
                              <input type="text" class="form-control" v-model="form.firstName" name="first_name" id="first_name" placeholder="Enter First Name">
                          </div>
                          <div class="form-group col-md-6">
                              <label for="last_name">Last Name:</label>
                              <input type="text" class="form-control" v-model="form.lastName" name="last_name" id="last_name" placeholder="Enter Last Name">
                          </div>
                          <div class="form-group col-md-6">
                              <label for="displayName">Display Name:</label>
                              <input type="text" class="form-control" v-model="form.displayName" name="displayName" id="displayName" placeholder="Enter Display Name">
                          </div>
                          <!-- <div class="form-group col-md-4">
                              <label for="gender">Gender:</label>
                              <select class="form-control" v-model="form.genderId" name="genderId" id="genderId">
                                <option disabled>Select Gender</option>
                                  <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{gender.name }}</option>
                              
                              </select>
                          </div> -->
                          <div class="form-group col-md-6">
                                  <label for="uname">Date of Birth</label>
                                  <input type="date" class="form-control" id="uname" v-model="form.dateOfBirth">
                          </div>
                        </div>
                        <hr>
                        <button @click="submit()" type="button" class="btn btn-primary">Update Profile</button>
                      </form>
                  </div>
              </div>
            </div>
        </div>

        </div>

      </div>
      
      </appComp>
    </div>
  </template>

<script>
import appComp from "@/components/appComp";
import axios from "axios";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
    //List Items
    pregnancyId:  this.$route.query.pregnancyId,
    childInfo: [],
    roles: [],
    isLoading: true, // Add loading state
    genders: null,
    //Form Items
    form: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      displayName: '',
      genderId: '',
      },

    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      role: "auth/role",
      current_profile: "auth/profile",
    }),
  },
  //methods go here
  methods: {
    getData() {
      this.isLoading = true; // Set isLoading to true before making the API call
      const pregnancyId = this.$route.query.pregnancyId;
      const childId = this.$route.params.id;

      // console.log("Monyancha pregnancy id", pregnancyId, childId);

      delete axios.defaults.headers.common["X-CG-Authorization"];
      // / get organizations
      axios
        .get("/pregnancies/" + pregnancyId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then((response) => {
            const childrenArray = response.data.data?.items[0].children;
            // console.log("Children Array", childrenArray);

            const childIdToFind = childId;
            const foundChild = childrenArray.find(child => child.id == childIdToFind);

            if (foundChild) {
                this.childInfo = foundChild;
            } else {
                console.error("Child not found with the provided childId.", childIdToFind);
            }

            // Set isLoading to false once data is loaded
            this.isLoading = false;

            // console.log("Monyancha Child Info", this.childInfo);
        });

        //Lists
          //   get data
          axios.get("/lists", {
            headers: {

               'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            }
         }, {
         }).then((response) => {
            this.genders = response.data.data.genders
         });

    },

    submit() {

        const pregnancyId = this.$route.query.pregnancyId;
        const childId = this.$route.params.id;

        console.log('Birth Date', this.form.dateOfBirth)

        // Construct the data to be sent
        const dataToSend = {
          children: [
            {
              id: childId,
              birthDate: this.form.dateOfBirth,
              displayName: this.form.displayName,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              gender: this.form.genderId,              

            }
          ]
        };

        axios
        .patch(`/pregnancies/${pregnancyId}`, dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then(() => {
          // Handle the response here if needed
          // console.log("API response:", response);
          // Load items on the main component page
          this.getData();
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Child info updated successfully.',
          });
        })
        .catch((error) => {
          // Handle errors here
          console.error("API error:", error);
          // Show error message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'An error occurred while updating child info.',
          });
        });
        },

    hasRole(roleName) {
      return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
    },
    getRoles() {
      // this.roles = this.user.profiles[this.current_profile].organization.roles;
      this.roles =
        this.user.profiles[this.current_profile]?.organization?.roles ?? [];
    },
    navigateTO(location) {
      this.$router.push(location);
    },
  },
  components: {
    appComp,
  },
  mounted() {
    this.getData();
    this.getRoles();
    delete axios.defaults.headers.common["X-CG-Authorization"];
    axios
      .get("/organizations", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
        },
      })
      .then((response) => {
        this.organizations = response.data.data.items;
        setTimeout(() => {
          $("#orgTable").DataTable();
        }, 10);
      });
    // console.log(this.organizations);
  },
};
</script>