
<template lang="">
    <div>
        <appComp>
           <div class="row">
                  <div class="col-lg-3">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Add New Profile</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <form>
                              <div class="form-group">
                                 <div class="add-img-user profile-img-edit">
                                    <img class="profile-pic img-fluid" src="@/assets/images/user/11.png" alt="profile-pic">
                                    <div class="p-image">
                                       <!-- <a href="javascript:void();" class="upload-button btn iq-bg-primary">File Upload</a> -->
                                       <!-- <input class="file-upload" type="file" accept="image/*"> -->
                                    </div>
                                 </div>
                                 
                              </div>                             
                              <div class="form-group">
                                 <label>Organization:</label>
                                 <select @change="getOrgRoles" class="form-control" v-model="form.organizationId" name="organizationId" id="organizationId">
                                    <option selected disabled>Select</option>
                                    <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{ organization.name}}</option>
                                  
                                 </select>
                              </div>
                              <!-- <div class="form-group">
                                 <label>This organization is a health facility</label>
                              </div>    -->                                                         
                              <div v-if="typeOrg !== null && form.organizationId == 1" class="form-group">
                                 <label>Roles(s):</label>
                                 <select class="form-control" multiple v-model="selectedroles" name="roles" id="roles">
                                    <option selected disabled>Select</option>
                                    <option v-for="role in profile_roles" :key="role.id" :value="role.id">{{role.name }}</option>
                                 
                                 </select>
                              </div>
                              <div v-if="healthOrg == null && form.organizationId !== 1" class="form-group">
                                 <label>Roles(s):</label>
                                 <select class="form-control" multiple v-model="selectedroles" name="roles" id="roles">
                                    <option disabled>Select</option>
                                    <option v-for="role in org_roles" :key="role.id" :value="role.id">{{role.name }}</option>
                                 
                                 </select>
                              </div> 
                              <div v-if="healthOrg !== null" class="form-group">
                                 <label>Roles(s):</label>
                                 <select class="form-control" multiple v-model="selectedroles" name="roles" id="roles">
                                    <option disabled>Select</option>
                                    <option v-for="role in health_roles" :key="role.id" :value="role.id">{{role.name }}</option>
                                 
                                 </select>
                              </div>                                                            
                              <div class="form-group">
                                 <label for="dob">Date of Birth:</label>
                                 <input type="date" class="form-control" v-model="form.birthDate" name="birthDate" id="phone_no" placeholder="Enter DOB">

                              </div>                               
                              <div class="form-group">
                                 <label for="gender">Gender:</label>
                                 <select class="form-control" v-model="form.genderId" name="genderId" id="genderId">
                                    <option disabled>Select</option>
                                     <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{gender.name }}</option>
                                  
                                 </select>
                              </div>
                              <div class="form-group">
                                 <label for="language">Language:</label>
                                 <select class="form-control" v-model="form.languageId" name="languageId" id="languageId">
                                    <option disabled>Select</option>
                                     <option v-for="language in languages" :key="language.id" :value="language.id">{{language.name }}</option>
                                 </select>
                              </div>
                              <div class="form-group">
                                 <label for="countryId">Notification channel:</label>
                                 <select  class="form-control" v-model="channelId" name="countryId">
                                    <option v-for="channel in channels" v-bind:value="channel.id" :key="channel.id">{{ channel.name }}</option>
                                 
                                 </select>
                              </div>
                              
                           </form>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-9">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">New Profile Information</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="new-user-info">
                              <div class="m-4">
                                 <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>
                                 <!-- <div v-if="message" class="alert alert-danger">{{ message }}</div> -->
                                 <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                              </div>
                              <form @submit.prevent="handleSubmit()">
                                 <div class="row">
                                    <div class="form-group col-md-6">
                                       <label for="first_name">First Name:</label>
                                       <input type="text" class="form-control" v-model="form.firstName" name="first_name" id="first_name" placeholder="Enter First Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="last_name">Last Name:</label>
                                       <input type="text" class="form-control" v-model="form.lastName" name="last_name" id="last_name" placeholder="Enter Last Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="email">Email:</label>
                                       <input type="email" class="form-control" v-model="form.email" name="email" id="email" placeholder="Enter Email Address">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="phone_no">Phone Number:</label>
                                       <input type="text" class="form-control" v-model="form.msisdn" name="phone_no" id="phone_no" placeholder="Format 254XXXXXXXXX">
                                    </div>
                                    <!-- <div class="form-group col-md-6">
                                       <label for="email">Identity Email Address:</label>
                                       <input type="email" class="form-control" v-model="form.identity_email" name="identity_email" id="identity_email" placeholder="Enter Identity Email Address">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="phone_no">Identity Phone Number:</label>
                                       <input type="text" class="form-control" v-model="form.identity_phone_no" name="identity_phone_no" id="identity_phone_no" placeholder="Enter Identity Phone Number">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="identity_username">Identity Username:</label>
                                       <input type="text" class="form-control" v-model="form.identity_username" name="identity_email" id="identity_username" placeholder="Enter Identity Username">
                                    </div> -->
                                    <div class="form-group col-md-6">
                                       <label for="displayName">Display Name:</label>
                                       <input type="text" class="form-control" v-model="form.displayName" name="displayName" id="displayName" placeholder="Enter Display Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="countryId">Country:</label>
                                       <select @change="getRegions" class="form-control" v-model="form.countryId" name="countryId" id="countryId">
                                          <option v-for="country in countries" v-bind:value="country.id" :key="country.id">{{ country.name }}</option>
                                       
                                       </select>
                                    </div>
                                    <!-- <div class="col-md-6 mb-3">
                                       <label for="validationDefaultUsername">Phone Number</label>
                                       <div class="input-group">
                                          <div class="input-group-prepend">
                                             <span v-if="form.countryId == 1" class="input-group-text" id="inputGroupPrepend2">254</span>
                                          </div>
                                          <input type="number" v-model="form.msisdn" class="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" required="">
                                       </div>
                                    </div>                                     -->
                                    <div class="form-group col-md-6">
                                       <label for="regionId">Region:</label>
                                       <select  @change="getTowns" :disabled="!form.countryId" class="form-control" v-model="form.regionId" name="regionId" >
                                          <option unselected>Select</option>
                                          <option  v-for="region in regions"  :key="region.id" :value="region.id">{{ region.name }}</option>
                                          
                                       </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="townId">Town:</label>
                                       <select @change="checkOther" :disabled="!form.regionId" class="form-control" v-model="form.townId" name="townId" id="townId">
                                          <option disabled>Select</option>
                                          <option v-for="town in towns" :value="town.id" :key="town.id">{{ town.name }}</option>
 
                                       </select>
                                    </div>
                                    <div class="form-group col-sm-6">
                                       <label>Will this profile be able to login?</label>
                                       <select class="form-control" v-model="form.chooseLogin">
                                       <option disabled="" value="">Select</option>
                                       <option value="yes">Yes</option>
                                       <option value="no">No</option>
                                       </select>
                                    </div>
                                    <div class="form-group col-md-12" :hidden="!others" >
                                       <label for="town">Town:</label>
                                       <input  type="text" class="form-control" v-model="form.town" name="town" id="town" placeholder="Enter Town Name">
                                    </div>
                                 </div>
                                 <hr>
                                 <!-- <h5 class="mb-3">ReadOnly</h5> -->
                                 <!-- <div class="row">
                                    <div class="form-group col-md-6">
                                       <label for="locator">Locator:</label>
                                       <input type="text" class="form-control" id="locator" placeholder="Locator" readonly>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="status">Status:</label>
                                       <input type="text" class="form-control" id="status" placeholder="Status" readonly>
                                    </div>
                                 </div> -->
                                 <button type="submit" class="btn btn-primary">Add New Profile</button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
           </div>
        </appComp>
    </div>
</template>
<script>
// import appComp from '@/components/appComp';
import appComp from '@/components/appComp';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';
const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;

export default {
   data() {
      return {
         form: {
            id: '',
            organizationId: '',
            selectedroles: [],
            birthDate: '',
            genderId: null,
            languageId: '',
            firstName: '',
            lastName: '',
            email: '',
            msisdn: '',
            identity_email: '',
            identity_phone_no: '',
            identity_username: '',
            healthfacilityId: '',
            displayName: '',
            countryId: '',
            regionId: '',
            townId: '',
            identityId: '',
            town: ''
         },
         selectedroles: [],
         languages: null,
         countries: null,
         genders: null,
         organizations: null,
         channels: null,

         regions: [],
         towns: [],
         roles: [],
         profile_roles: [],
         org_roles: [],
         health_roles: [],

         identities: [],
         statuses: [],
         healthFacilities: [],
         healthOrg: '',
         typeOrg: '',
         selectedHealth: '',
         selectedValue: '',

         others: false,
         message: [],
         successMessage: "",
      }
   },
   components: {
      appComp
   },
   computed: {
      ...mapGetters({
         lists: 'auth/lists',
         user: 'auth/user',
         role: 'auth/role',
         current_profile: 'auth/profile'
      })
   },
   methods: {
      hasRole(roleName) {
         return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      getRoles() {
         // this.roles = this.user.profiles[this.current_profile].organization.roles;
         this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];

      },
      getIdentities() {
         // console.log(axios);
         delete axios.defaults.headers.common['X-CG-Authorization'];
         try {                  
         axios.get('/organizations/' + this.$route.params.id + '/identities', {
               headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
               },
         }).then((response) => {
               this.identities = response.data.data.items
               // console.log("data", this.identities);
         });
         } catch (error) {
               // console.error("Error fetching data:", error);
         }            
      },      
      handleSubmit() {
         this.message = []
         let data;
         if (this.$route.params.type == 2 && this.form.chooseLogin == 'yes' && this.hasRole('global.admin') || this.hasRole('organization.admin') || this.hasRole('healthcare.facility.admin')) {
            data = {
               displayName: this.form.displayName,
               firstName: this.form.firstName,
               lastName: this.form.lastName,
               email: this.form.email,
               msisdn: this.form.msisdn,
               organizationId: this.form.organizationId,
               roles: this.selectedroles,
               notificationChannelTypeId: this.channelId,
               countryId: this.form.countryId,
               birthDate: this.form.birthDate,
               genderId: this.form.genderId,
               languageId: this.form.languageId,
               regionId: this.form.regionId,
               townId: this.form.townId,
               location: this.form.location,
               identity: {
                  username: this.form.email,
                  email: this.form.email,
                  msisdn: this.form.msisdn
               }
            }

         } else if (this.$route.params.type == 2 && this.form.chooseLogin == 'no' && this.hasRole('global.admin') || this.hasRole('organization.admin') || this.hasRole('healthcare.facility.admin'))
          {
            data = {
               displayName: this.form.displayName,
               firstName: this.form.firstName,
               lastName: this.form.lastName,
               email: this.form.email,
               msisdn: this.form.msisdn,
               organizationId: this.form.organizationId,
               roles: this.selectedroles,
               countryId: this.form.countryId,
               birthDate: this.form.birthDate,
               genderId: this.form.genderId,
               languageId: this.form.languageId,
               regionId: this.form.regionId,
               townId: this.form.townId,
               location: this.form.location, 
               notificationChannelTypeId: this.channelId,

            }
         }  
                
         delete axios.defaults.headers.common['X-CG-Authorization'];
         axios.post("/profiles", data, {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },

         }).then((response) => {
            if (response.data.status == 'success') {
               // console.log(this.form)

               // this.resetForm();
               toast.fire(
               'Success!',
               'Profile created!',
               'success'
               )
            }
         }).catch((e) => {
            // console.log(e.response.data)
            let errors = e.response.data.data;
            if (errors.length > 1) {
               errors.forEach(err => {
                  this.message.push(err.message)
               });
            } else {
               errors.forEach(err => {
                  this.message.push(err.message)

               });
            }
         })

      },
      resetForm(){
         this.form.id= '',
         this.form.organizationId= '',
         this.form.selectedroles= [],
         this.form.birthDate= null,
         this.form.genderId= null,
         this.form.languageId= '',
         this.form.firstName= '',
         this.form.lastName= '',
         this.form.email= '',
         this.form.msisdn= '',
         this.form.identity_email= '',
         this.form.identity_phone_no= '',
         this.form.identity_username= '',
         this.form.healthfacilityId= '',
         this.form.displayName= '',
         this.form.countryId= '',
         this.form.regionId= '',
         this.form.townId= '',
         this.form.town= ''
      },
      ...mapActions({
         getLists: 'auth/getLists'
      }),
      getOrg() {
         delete axios.defaults.headers.common['X-CG-Authorization'];
         // / get organizations
         axios.get('/organizations', {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
         }).then((response) => {
            // console.log("org", response)
            this.organizations = response.data.data.items
         });
      },
      getData() {

         //   get data
         axios.get("/lists", {
            headers: {

               'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            }
         }, {
         }).then((response) => {
            this.countries = response.data.data.countries
            this.organizationTypes = response.data.data.organizationtypes
            this.healthFacilities = response.data.data.healthfacilitytypes
            this.channels = response.data.data.channeltypes
            this.genders = response.data.data.genders
            this.languages = response.data.data.languages
            this.statuses = response.data.data.status
            // console.log("roles", response)

            if (this.hasRole('organization.admin')) {
               this.profile_roles = response.data.data.roles.organization

            } else if (this.hasRole('healthcare.facility.admin')) {
               this.profile_roles = response.data.data.roles.healthcare


            } else if (this.hasRole('global.admin')) {
               // this.profile_roles = response.data.data.roles.global.concat(response.data.data.roles.healthcare, response.data.data.roles.organization)
               this.profile_roles = response.data.data.roles.global

            }

            // let data = response.data.data.rolesv2.global.concat(response.data.data.rolesv2.organization, response.data.data.rolesv2.healthcare)
            this.roles = response.data.data.roles.global.concat(response.data.data.roles.organization, response.data.data.roles.healthcare)
            this.org_roles = response.data.data.roles.organization
            this.health_roles = response.data.data.roles.healthcare



            // this.rolesv2 = response.data.data.rolesv2
            // console.log("roles", this.roles)
            // console.log("data", this.rolesv2)
         });

      },
      async getOrgRoles(){
         this.selectedOrg = this.organizations.find(organization => organization.id === this.form.organizationId);
         this.healthOrg = this.selectedOrg.heathFacilityType.id;
         this.typeOrg = this.selectedOrg.type.id;

         // console.log("healthOrg", this.healthOrg)
      },
      async getRegions() {
         try {
            const selectedCountry = this.countries.find(country => country.id === this.form.countryId);
            this.regions = selectedCountry.regions;
            this.regionId = '';
            this.townId = '';
         } catch (error) {
            // console.error(error);
         }
      },

      async getTowns() {
         try {
            const selectedRegion = this.regions.find(region => region.id === this.form.regionId);
            this.towns = selectedRegion.towns;
            this.townId = '';
         } catch (error) {
            // console.error(error);
         }
      },

      checkOther() {
         const other = this.towns.find(town => town.id === this.form.townId);
         if (other.name == "Other") {
            this.others = true;
         } else {
            this.others = false;
         }
      }
   },
   mounted() {
      this.getData();
      this.getRoles();
      this.getOrg();
      this.getIdentities();
      this.profileOrganization = this.user.profiles[this.current_profile].organization.id;
      // console.log(this.profileOrganization)
   }

}
</script>
<style lang="">
    
</style>