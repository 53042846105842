<template lang="">
    <div>
        <appComp>
           <div class="row">
                  <div class="col-lg-12">
                    <div class="iq-card-body">
                           <p>Clinical Visits</p>
                           <table id="orgTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Registrant</th>
                                    <th scope="col">No of Children</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                <tr v-for="pregnancy in pregnancies" :key="pregnancy.id">
                                    <th scope="row">{{pregnancy.id}}</th>
                                    <td>{{pregnancy.registrant.type['name']}}</td>
                                    <td>{{pregnancy.totalChildren}}</td>
                                    <td>
                                        <div class="row justify-space-between">
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                                <a @click="navigateTO('/viewpregnancy/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Pregnancy</a>
                                                <a @click="navigateTO('/viewchildren')" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Children</a>
                                                <a @click="navigateTO('/maternal')" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Mother Medical Chart</a>
                                                <a @click="navigateTO('/maternal')" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Father Medical Chart</a>
                                                <a @click="navigateTO('/clinicalvisits')" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Clinical Visits</a>
                                                <a @click="navigateTO('/immunization')" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Immunizations</a>                                    
                    
                                            
                                            </div>
                                        </div>
                                        <!-- <span></span>
                                        <button @click="deleteOrganization(organization.id)" class="btn-sm btn btn-danger ml-2">
                                            Delete
                                        </button> -->
                                        </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                  </div>
           </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            pregnancies: [],
            roles: [],
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            role: 'auth/role',
            current_profile: 'auth/profile'
        }),
    },
    //methods go here
    methods: {
      getData()
      {
         delete axios.defaults.headers.common['X-CG-Authorization'];
         // / get organizations
         axios.get('/pregnancies', {
            headers : {
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            this.pregnancies = response.data.data.items
            console.log("preg", this.pregnancies)
        });
      },

        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        navigateTO(location) {
            this.$router.push(location)
        },

    },
    components: {
        appComp
    },
    mounted() {
        this.getData();
        this.getRoles();
        delete axios.defaults.headers.common['X-CG-Authorization'];
        axios.get('/organizations', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            this.organizations = response.data.data.items
            setTimeout(() => {
                $("#orgTable").DataTable();
            }, 10);
        });
        console.log(this.organizations);

        //     axios.get("/organizations", {
        //        }).then((response) => {
        //           this.organizations = response.data.data.items

        //    });
    }

}
</script>
<style lang="">
    
</style>
