<template lang="">
    <div>
        <appComp>
            <div class="iq-card col-lg-12">
            <div v-if="hasRole('global.admin') || hasRole('global.auditor')" class="iq-card-body">
                    <p>Registered Pregnancies</p>
                    <!-- Start Custom Preloader -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <!-- End Custom Preloader -->
                    <table v-else id="profileTable" class="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Organization</th>
                            <th scope="col">Registrant Type</th>
                            <th scope="col">Registrant Name</th>
                            <th scope="col">Father Name</th>
                            <th scope="col">Mother Name</th>
                            <th scope="col">No of Children</th>
                            <!--<th scope="col">Region</th>
                            <th scope="col">Town</th>-->
                            <th scope="col">EDD</th>
                            <th scope="col">Status</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="pregnancy in pregnancies" :key="pregnancy.id">
                            <th scope="row">{{pregnancy.id}}</th>
                            <td>{{pregnancy.registrant.organization['name']}}</td>
                            <td>{{pregnancy.registrant.type['name']}}</td>
                            <td>{{ motherName(pregnancy) }}</td>
                            <td>
                            {{ pregnancy.father.firstName || pregnancy.father.lastName
                                ? (pregnancy.father.firstName + " " + pregnancy.father.lastName)
                                : '-' }}
                            </td>
                            <td>
                            {{ pregnancy.mother.firstName || pregnancy.mother.lastName
                                ? (pregnancy.mother.firstName + " " + pregnancy.mother.lastName)
                                : '-' }}
                            </td>
                            <td>{{pregnancy.totalChildren}}</td>
                            <!--<td>{{pregnancy.mother?.region.name ?? '-'}}</td>
                            <td>{{ pregnancy.mother?.town.name ?? '-'}}</td>-->
                            <td>{{pregnancy.expectedDeliveryDate ?? "-"}}</td>
                            <td><span class="badge badge-pill badge-success">Progressing</span></td>
                            <td>
                                <div class="row justify-space-between">
                                <div class="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                        <a @click="navigateTO('/viewpregnancy/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Pregnancy</a>
                                        <a @click="navigateTO('/childrenList/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Children</a>
                                        <a v-if="pregnancy.mother && pregnancy.mother.id > 1" @click="navigateTO('/viewmothermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Mother Medical Chart</a>
                                        <a v-if="pregnancy.father && pregnancy.father.id > 1" @click="navigateTO('/viewfathermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Father Medical Chart</a>
                                        <!-- <a @click="navigateTO('/payments/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-wallet-3-fill mr-2"></i>Payments</a> -->
                                    </div>
                                </div>
                                </div>
                            </td>
                            </tr>
                        </tbody>                             
                    </table>
            </div>
            <div v-if="hasRole('organization.admin') || hasRole('healthcare.facility.admin') || hasRole('healthcare.facility.user') || hasRole('organization.user')" class="iq-card-body mt-4">
                    <p>Registered Pregnancies</p>
                    <!-- Start Custom Preloader -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <!-- End Custom Preloader -->
                    <table v-else id="profileTable" class="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Organization</th>
                            <th scope="col">Registrant Type</th>
                            <th scope="col">Registrant Name</th>
                            <th scope="col">Father Name</th>
                            <th scope="col">Mother Name</th>
                            <th scope="col">No of Children</th>
                            <!--<th scope="col">Region</th>
                            <th scope="col">Town</th>-->
                            <th scope="col">EDD</th>
                            <th scope="col">Status</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="pregnancy in orgpregnancies" :key="pregnancy.id">
                            <th scope="row">{{pregnancy.id}}</th>
                            <td>{{pregnancy.registrant.organization['name']}}</td>
                            <td>{{pregnancy.registrant.type['name']}}</td>
                            <td>{{ motherName(pregnancy) }}</td>
                            <td>
                            {{ pregnancy.father.firstName || pregnancy.father.lastName
                                ? (pregnancy.father.firstName + " " + pregnancy.father.lastName)
                                : '-' }}
                            </td>
                            <td>
                            {{ pregnancy.mother.firstName || pregnancy.mother.lastName
                                ? (pregnancy.mother.firstName + " " + pregnancy.mother.lastName)
                                : '-' }}
                            </td>
                            <td>{{pregnancy.totalChildren}}</td>
                            <!--<td>{{pregnancy.mother?.region.name ?? '-'}}</td>
                            <td>{{ pregnancy.mother?.town.name ?? '-'}}</td>-->
                            <td>{{pregnancy.expectedDeliveryDate ?? "-"}}</td>
                            <td><span class="badge badge-pill badge-success">Progressing</span></td>
                            <td>
                                <div class="row justify-space-between">
                                <div class="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                        <a @click="navigateTO('/viewpregnancy/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Pregnancy</a>
                                        <a @click="navigateTO('/childrenList/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Children</a>
                                        <a v-if="pregnancy.mother && pregnancy.mother.id > 1" @click="navigateTO('/viewmothermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Mother Medical Chart</a>
                                        <a v-if="pregnancy.father && pregnancy.father.id > 1" @click="navigateTO('/viewfathermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Father Medical Chart</a>
                                        <a @click="navigateTO('/payments/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-wallet-3-fill mr-2"></i>Payments</a>
                                    </div>
                                </div>
                                </div>
                            </td>
                            </tr>
                        </tbody>                             
                    </table>
            </div>
            <div v-if="hasRole('user')" class="iq-card-body">
                    <p>Registered Pregnancies <strong v-if="hasRole('global.admin') || hasRole('global.auditor') || hasRole('organization.admin') || hasRole('healthcare.facility.admin') || hasRole('healthcare.facility.user') || hasRole('organization.user')">- Registered By Me</strong></p>
                    <!-- Start Custom Preloader -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <!-- End Custom Preloader -->
                    <table v-else id="mypregTable" class="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Organization</th>
                            <th scope="col">Registrant Type</th>
                            <th scope="col">Registrant Name</th>
                            <th scope="col">Father Name</th>
                            <th scope="col">Mother Name</th>
                            <th scope="col">No of Children</th>
                            <!--<th scope="col">Region</th>
                            <th scope="col">Town</th>-->
                            <th scope="col">EDD</th>
                            <th scope="col">Status</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="pregnancy in mypregnancies" :key="pregnancy.id">
                            <th scope="row">{{pregnancy.id}}</th>
                            <td>{{pregnancy.registrant.organization['name']}}</td>
                            <td>{{pregnancy.registrant.type['name']}}</td>
                            <td>{{ motherName(pregnancy) }}</td>
                            <td>
                            {{ pregnancy.father.firstName || pregnancy.father.lastName
                                ? (pregnancy.father.firstName + " " + pregnancy.father.lastName)
                                : '-' }}
                            </td>
                            <td>
                            {{ pregnancy.mother.firstName || pregnancy.mother.lastName
                                ? (pregnancy.mother.firstName + " " + pregnancy.mother.lastName)
                                : '-' }}
                            </td>
                            <td>{{pregnancy.totalChildren}}</td>
                            <!--<td>{{pregnancy.mother?.region.name ?? '-'}}</td>
                            <td>{{ pregnancy.mother?.town.name ?? '-'}}</td>-->
                            <td>{{pregnancy.expectedDeliveryDate ?? "-"}}</td>
                            <td><span class="badge badge-pill badge-success">Progressing</span></td>
                            <td>
                                <div class="row justify-space-between">
                                <div class="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                        <a @click="navigateTO('/viewpregnancy/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Pregnancy</a>
                                        <a @click="navigateTO('/childrenList/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Children</a>
                                        <a v-if="pregnancy.mother && pregnancy.mother.id > 1" @click="navigateTO('/viewmothermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Mother Medical Chart</a>
                                        <a v-if="pregnancy.father && pregnancy.father.id > 1" @click="navigateTO('/viewfathermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Father Medical Chart</a>
                                    </div>
                                </div>
                                </div>
                            </td>
                            </tr>
                        </tbody>                             
                    </table>
            </div>
            </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            pregnancies: [],
            mypregnancies: [],
            orgpregnancies: [],
            roles: [],
            motherInfo: [],
            isLoading: true, // Add loading state
        }
    },
    computed: {
        ...mapGetters({
            lists: "auth/lists",
            user: "auth/user",
            role: "auth/role",
            current_profile: "auth/profile",
        }),
    },
    methods: {
        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        getOrg(){
            try{
            delete axios.defaults.headers.common['X-CG-Authorization'];
            axios.get('/organizations', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
            }).then((response) => {
                this.organizations = response.data.data.items
                
            });
            // console.log(this.organizations);
            } catch (error) {
                // console.error("Error fetching data:", error);
                this.isLoading = false; // Make sure isLoading is set to false in case of an error
                this.$router.push('/error'); // Redirect to the error page.

            }
        },

        async loadMotherInfo() {
            try {
                delete axios.defaults.headers.common['X-CG-Authorization'];
                axios.get('/organizations/' + 1 + '/profiles', {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                    },
                }).then((response) => {
                    this.motherInfo = response.data.data.items
                    // console.log("data", this.motherInfo);
                });
            } catch (error) {
                // console.error("Error fetching data:", error);
                this.isLoading = false; // Make sure isLoading is set to false in case of an error
                this.$router.push('/error'); // Redirect to the error page.

            }
        },

        motherName(pregnancy) {
            // console.log("Nyaranda 33", this.motherInfo);
            const motherProfile = this.motherInfo.find((mom) => mom.id == pregnancy.registrant.id);
            return motherProfile ? motherProfile.firstName + " " + motherProfile.lastName : '-';
            // return motherProfile.firstName || motherProfile.lastName
            //                             ? (motherProfile.firstName + " " + motherProfile.lastName)
            //                             : '-';
        },
        
        navigateTO(location) {
            this.$router.push(location)
        },
        navigateTo(location) {
            this.$router.push(location)
        },

        async getData() {
            this.isLoading = true; // Set isLoading to true before making the API call
            delete axios.defaults.headers.common['X-CG-Authorization'];

            try {
                axios.get('/pregnancies', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
                }).then((response) => {
                this.pregnancies = response.data.data.items;
                this.orgpregnancies = this.pregnancies.filter(item => item.registrant.organization.id === this.organizationId)
                this.mypregnancies = this.pregnancies.filter(item => item.registrant.id === this.profileId || item.mother.id === this.profileId || item.father.id === this.profileId)
                // console.log("mabho", this.orgpregnancies)

                setTimeout(() => {
                    $("#profileTable").DataTable();
                }, 10);

                setTimeout(() => {
                    $("#mypregTable").DataTable();
                }, 10);

                // Set isLoading to false once data is loaded
                this.isLoading = false;

                // console.log("data", this.pregnancies);
                });
            } catch (error) {
                // console.error("Error fetching data:", error.message);
                this.isLoading = false; // Make sure isLoading is set to false in case of an error
               // this.$router.go(-1);
               // this.$router.push('/error'); // Redirect to the error page.

            }
        },
        async checkInternetConnection() {
            delete axios.defaults.headers.common['X-CG-Authorization'];

            axios.get('/pregnancies', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
                })
                .then(response => {
            // Resource fetched successfully, meaning there is an internet connection.
            console.log(response)
            })
            .catch(error => {
            // Failed to fetch the resource, indicating no internet connection.
            this.$router.push('/error'); // Redirect to the error page.
            console.log(error)
            });
        }

    },
    //methods go here
    components: {
        appComp
    },
    mounted() {
        this.getData();
        this.getRoles();
        this.loadMotherInfo();
        // this.checkInternetConnection();
        this.profileId = this.user.profiles[this.current_profile].id;
        this.organizationId = this.user.profiles[this.current_profile].organization.id;
        this.profileRoles = this.user.profiles[this.current_profile].organization.roles;
        this.profileRole = this.profileRoles[0].name
        // console.log(this.profileRole)
    }

}
</script>
<style lang="">
    
</style>