<template>
    <div class="modal fade" id="newPaymentModal" tabindex="-1" role="dialog" aria-labelledby="newPaymentModalLabel" style="display: none;" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="newPaymentModalLabel">New Payment</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">

                        <div class="row align-items-center">
                              <div class="form-group col-sm-6">
                                 <label for="fname">Payment Type:</label>
                                 <select class="form-control" v-model="form.paymentTypeId" name="payments" id="payments">
                                    <option disabled value="">Select</option>
                                    <option value="paymenttype1.id">{{paymenttype1.name}}</option>
                                    <option value="paymenttype2.id">{{paymenttype2.name}}</option>
                                    <option value="paymenttype3.id">{{paymenttype3.name}}</option>

                                    <option  value="paymenttype4.id">{{paymenttype4.name}}</option>
                                    <option  value="paymenttype5.id">{{paymenttype5.name}}</option>
                                    <option  value="paymenttype6.id">{{paymenttype6.name}}</option>
                                    <option  value="paymenttype7.id">{{paymenttype7.name}}</option>
                                    <option  value="paymenttype8.id">{{paymenttype8.name}}</option>
                                    <option  value="paymenttype9.id">{{paymenttype9.name}}</option>
                                    <option  value="paymenttype10.id">{{paymenttype10.name}}</option>

                                    <option value="paymenttype11.id">{{paymenttype11.name}}</option>
                                 
                                 </select>
                              </div>                              
                              <div class="form-group col-sm-6">
                                 <label for="uname">Transaction Type:</label>
                                 <select class="form-control" v-model="form.transactionTypeId" name="payments" id="payments">
                                    <option disabled value="">Select</option>
                                    <option v-for="transaction in transactiontype" :value="transaction.id" :key="transaction.id">{{ transaction.name}}</option>
                                 
                                 </select>
                              </div>
                              <div class="form-group col-sm-12">
                              <label for="recipientSearch">Select Recipient</label>
                                <input type="text" v-model="searchQuery" @input="filterProfiles" placeholder="Search Recipient..." class="form-control">
                                <div class="form-group col-sm-12" v-if="filteredProfiles.length">
                                  <ul>
                                    <li v-for="profile in filteredProfiles" :key="profile.id" @click="selectProfile(profile)">
                                      {{ profile.displayName }} | {{ profile.firstName }} {{ profile.lastName }} - {{ profile.msisdn }} | Wallet ID: {{ profile.wallet.id }}
                                    </li>
                                  </ul>
                                </div>
                            </div>
                              <!-- <div class="form-group col-sm-12">
                                 <label for="fname">Select Recipient</label>
                                 <select class="form-control" v-model="form.creditWalletId" name="payments" id="payments">
                                    <option disabled value="">Select</option>
                                    <option v-for="profile in profiles" :value="profile.wallet.id" :key="profile.id">{{ profile.displayName }} | {{ profile.firstName }} {{ profile.lastName }} - {{ profile.msisdn }} | Wallet ID: {{ profile.wallet.id }}  </option>
                                 
                                 </select>
                              </div> -->
                              <div class="form-group col-sm-12">
                                    <label for="memo">Recipient Wallet ID<span class="text-danger"> (Be sure before submitting)</span></label>
                                    <input type="number" @change="checkWalletId" v-model="form.creditWalletId" class="form-control" placeholder="Recipient Wallet ID Eg. 3255" id="uname">
                              </div>
                              <div class="form-group col-sm-12">
                              <!-- Display user details or error message -->
                            <div v-if="userDetails" class="alert alert-success" role="alert">
                              {{ userDetails }}
                            </div>
                            <div v-if="errorMessage" class="alert alert-danger" role="alert">
                              {{ errorMessage }}
                            </div>
                          </div>
                          <!-- Initiate M-Pesa STK -->

                          <div v-if="form.paymentTypeId === 1 && form.transactionTypeId === 7" class="form-group col-sm-6">
                                <label for="memo">Your Phone Number</label>
                                <input type="number" placeholder="Enter phone number Eg. 254799117020" v-model="form.msisdn" class="form-control" id="msisdn">
                          </div>

                          <div v-if="form.paymentTypeId === 1 && form.transactionTypeId === 7" class="form-group col-sm-6">
                                 <label for="fname">Recipient Paybill</label>
                                 <select class="form-control" v-model="form.paybillId" name="paybills" id="paybills">
                                    <option disabled value="">Select</option>
                                    <option v-for="paybill in paybills" :value="paybill.id" :key="paybill.id">{{ paybill.name}}</option>
                                 
                                 </select>
                              </div>



                          <!-- End Initiate M-Pesa STK -->
                            <div class="form-group col-sm-6">
                                    <label for="memo">Amount(KES)</label>
                                    <input type="number" placeholder="Enter amount Eg. 5000" v-model="form.amount" class="form-control" id="uname">
                              </div>
                              <div class="form-group col-sm-6">
                                    <label for="memo">Memo</label>
                                    <input type="text" placeholder="Enter memo Eg. Support women in Narok" v-model="form.memo" class="form-control" id="uname">
                              </div>

                           </div>
                           
                          
                     
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button @click="submit()" type="button" class="btn btn-primary">Submit</button>
                </div>
            </form>
                </div>
            </div>
        </div>
  </template>
  
  <script>
  import axios from "axios";
  import "jquery/dist/jquery.min.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import $ from "jquery";
  import { mapGetters } from "vuex";
  import Swal from 'sweetalert2';

  export default {
    data() {
      return {
      roles: [],
      paybills: [],
      paymenttype: [],
      paymenttype1: [],
      paymenttype2: [],
      paymenttype3: [],
      paymenttype4: [],
      paymenttype5: [],
      paymenttype6: [],
      paymenttype7: [],
      paymenttype8: [],
      paymenttype9: [],
      paymenttype10: [],
      paymenttype11: [],
      transactiontype: [],
      organizations: [],
      profiles: [],
      // Existing data properties...
      userDetails: null,
      errorMessage: '',
      searchQuery: '',
      filteredProfiles: [],
      //Form Items
      form: {
          amount: '',
          paymentTypeId: '',
          transactionTypeId: '',
          memo: '',
          creditWalletId: '',
          msisdn: '',
          paybillId: '',
      },
      isProfilesLoaded: false,
      };
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
        role: "auth/role",
        current_profile: "auth/profile",
      }),
    },
    //methods go here
    methods: {
      filterProfiles() {
  if (this.searchQuery) {
    const searchLowerCase = this.searchQuery.toLowerCase();
    this.filteredProfiles = this.profiles.filter(profile => {
      // Ensure displayName, firstName, and lastName are strings and check if they include the search query
      const nameCheck = [profile.displayName, profile.firstName, profile.lastName].some(name =>
        typeof name === 'string' && name.toLowerCase().includes(searchLowerCase)
      );

      // Convert msisdn to a string if it's not already, then check if it includes the search query
      const msisdnCheck = typeof profile.msisdn === 'string'
        ? profile.msisdn.includes(searchLowerCase)
        : typeof profile.msisdn === 'number'
        ? profile.msisdn.toString().includes(searchLowerCase)
        : false;

      // Convert wallet.id to a string and check if it includes the search query
      const walletIdCheck = profile.wallet.id.toString().includes(searchLowerCase);

      return nameCheck || msisdnCheck || walletIdCheck;
    });
  } else {
    this.filteredProfiles = [];
  }
},
  selectProfile(profile) {
    this.form.creditWalletId = profile.wallet.id;
    // Close the dropdown or clear the search
    this.searchQuery = '';
    this.filteredProfiles = [];
    // Optional: Display the selected profile's details
    this.userDetails = `${profile.displayName} | ${profile.firstName} ${profile.lastName} - ${profile.msisdn} | Wallet ID: ${profile.wallet.id}`;
  },
      // Add this new method to load profiles from localStorage
      loadProfiles() {
        const profilesFromStorage = localStorage.getItem('allProfiles');
        if (profilesFromStorage) {
          try {
            this.profiles = JSON.parse(profilesFromStorage);
            // this.isProfilesLoaded = true; // Assuming you want to track when profiles are loaded
          } catch (e) {
            console.error('Error parsing profiles from localStorage', e);
            // this.errorMessage = 'Failed to load profiles. Please try again.';
          }
        } else {
          console.log('No profiles found in localStorage');
          // this.errorMessage = 'No profiles available. Please add profiles.';
        }
      },
      checkWalletId() {
        const allProfiles = JSON.parse(localStorage.getItem('allProfiles'));
        const foundProfile = allProfiles.find(profile => profile.wallet.id === this.form.creditWalletId);

        if (foundProfile) {
          // Set user details for display
          this.userDetails = `Display Name: ${foundProfile.displayName}, Locator ID: ${foundProfile.locatorId}, Phone: ${foundProfile.msisdn}`;
          this.errorMessage = ''; // Clear any previous error message
        } else {
          // Clear previous user details and set error message
          this.userDetails = null;
          this.errorMessage = 'Sorry! User with that wallet ID does not exist! Try Again.';
        }
      },
      toggleDropdown(event) {
      event.preventDefault(); // Prevent the default behavior
      this.isDropdownOpen = !this.isDropdownOpen;
    },
      getData() {

        const pregnancyId = this.$route.query.pregnancyId;
        const childId = this.$route.params.id;
  
        // console.log("Monyancha pregnancy id", pregnancyId, childId);
  
        delete axios.defaults.headers.common["X-CG-Authorization"];
        // / get organizations
        axios
          .get("/pregnancies/" + pregnancyId, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
            },
          })
          .then((response) => {
              const childrenArray = response.data.data?.items[0].children;
              // console.log("Children Array", childrenArray);
  
              const childIdToFind = childId;
              const foundChild = childrenArray.find(child => child.id == childIdToFind);
  
              if (foundChild) {
                  this.clinicalVisits = foundChild.medical.clinicalVisits;
              } else {
                  // console.error("Child not found with the provided childId.", childIdToFind);
              }
  
              // console.log("Monyancha Clinical Visits", this.clinicalVisits);
          });
      },

      submit() {

        // Format scheduledDate to "YYYY-MM-DD HH:mm:ss"
        const date = new Date(Date.now() - 50000).toISOString().slice(0, 19).replace('T', ' ');


        // console.log('Monyancha date', formatconstedScheduledDate);

        //Get Wallet Id from local storage
        const walletId = localStorage.getItem('walletId');

        // console.log("Monyancha wallet id", walletId)

        // Construct the data to be sent

        // Construct the data to be sent
        let dataToSend = {
          creditWalletId: this.form.creditWalletId || walletId,
          gateway: this.form.msisdn ? "safaricomMpesa" : "wallet",
          transaction: {
            amount: this.form.amount,
            date: date,
            id: Date.now(), // Replace with your transaction ID generation logic
            memo: this.form.memo,
            typeId: this.form.msisdn ? 1 : this.form.transactionTypeId,
          },
          typeId: this.form.msisdn ? 7 : this.form.paymentTypeId
        };

        // If creditWalletId is not provided in the form, add debitWalletId
        if (this.form.creditWalletId) {
          dataToSend.debitWalletId = walletId;
        }

        if(this.form.msisdn){
          dataToSend.msisdn = this.form.msisdn;
          dataToSend.paybillId = this.form.paybillId;
        }

        axios
        .post(`/payments`, dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then(() => {
          // Handle the response here if needed
          // console.log("API response:", response);
          // Load items on the main component page
          this.getData();
          // Show success message
          if(this.form.msisdn){
            Swal.fire({
            icon: 'success',
            title: 'STK Push Sent!',
            text: 'M-Pesa Topup initiated successfully! Kindly check your phone and enter your M-Pesa pin to complete the transaction.',
          });
        
          }else{
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Payment Successful.',
          });
        }
        //   this.$router.replace(`/childvisits/${childId}?pregnancyId=${pregnancyId}`); // Refresh the route
        })
        .catch((error) => {
          // Handle errors here
          console.error("API error:", error);
          // Show error message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error?.response?.data?.data[0]?.detail,
          });
        });
    },

        async loadLists(){
          delete axios.defaults.headers.common['Authorization'];
          axios.get("/lists", { headers: {
                  'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
          }},{
            }).then((response) => {
                this.paymenttype = response.data.data.paymenttypes
                this.paymenttype1 = response.data.data.paymenttypes[0]
                this.paymenttype2 = response.data.data.paymenttypes[1]
                this.paymenttype3 = response.data.data.paymenttypes[2]
                this.paymenttype4 = response.data.data.paymenttypes[3]
                this.paymenttype5 = response.data.data.paymenttypes[4]
                this.paymenttype6 = response.data.data.paymenttypes[5]
                this.paymenttype7 = response.data.data.paymenttypes[6]
                this.paymenttype8 = response.data.data.paymenttypes[7]
                this.paymenttype9 = response.data.data.paymenttypes[8]
                this.paymenttype10 = response.data.data.paymenttypes[9]
                this.paymenttype11 = response.data.data.paymenttypes[10]
                this.transactiontype = response.data.data.transactiontypes
                this.paybills = response.data.data.safaricompaybill 
                // console.log( "payments", this.paymenttype)

          });
      },
      getOrg() {
          delete axios.defaults.headers.common['X-CG-Authorization'];
          // / get organizations
          axios.get('/organizations', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
          }).then((response) => {
            // console.log("organization monyancha", response)
            this.organizations = response.data.data.items
          });
      },
      hasRole(roleName) {
        return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
      },
      getRoles() {
        // this.roles = this.user.profiles[this.current_profile].organization.roles;
        this.roles =
          this.user.profiles[this.current_profile]?.organization?.roles ?? [];
      },
      navigateTO(location) {
        this.$router.push(location);
      },
    },
    mounted() {
      this.loadProfiles();
      this.getRoles();
      this.loadLists();
      this.getOrg();
      this.getData();
      //TODO:: Get profiles from localstorag item - allProfiles
      delete axios.defaults.headers.common["X-CG-Authorization"];
      axios
        .get("/organizations", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then((response) => {
          this.organizations = response.data.data.items;
          setTimeout(() => {
            $("#orgTable").DataTable();
          }, 10);
        });
      // console.log(this.organizations);
    },
  };
  </script>
  <style scoped>
  /* Your custom styles here */
  .searchable-select {
    position: relative;
    display: inline-block;
    background-color: white;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid white;
  }
  
  .search-input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid white;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 5px;
    cursor: pointer;
  }

  /* filter css */
  /* Custom styles adjusted */
.searchable-select {
  position: relative;
  display: inline-block;
  background-color: white;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white; /* Ensure background is white */
  border: 1px solid #ddd; /* Slight adjustment for visibility */
  z-index: 1050; /* Increase z-index to ensure it's on top of other modal contents */
  max-height: 300px; /* Optional: limit the height of the dropdown */
  overflow-y: auto; /* Optional: add scroll to the dropdown */
}

.search-input {
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid #ccc;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 5px;
  cursor: pointer;
  background-color: white; /* Ensure each item has a white background */
}

li:hover {
  background-color: #f2f2f2; /* Optional: change background on hover for better UI/UX */
}

  
  </style>
  