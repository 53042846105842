<template>
    <!-- Wrapper Start -->
    <div class="wrapper">
        <!-- Sidebar  -->
        <sideBar />
        <!-- sidebar -->
        <!-- Page Content  -->
        <div id="content-page" class="content-page">
            <!-- TOP Nav Bar -->
            <topBar />
            <!-- TOP Nav Bar END -->
            <div class="container-fluid">
                <slot>

                </slot>
            </div>
            <!-- Footer -->
            <footerComp />
            <!-- Footer END -->
        </div>
    </div>
    <!-- Wrapper END -->
</template>

<script>
import FooterComp from './navigation/footer/footerComp.vue';
import SideBar from './navigation/sidebar/sideBar.vue';
import TopBar from './navigation/topbar/topBar.vue';




export default {
    name: 'appComp',

    components: {
        FooterComp,
        SideBar,
        TopBar
    },
    methods :{
        

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

