<template lang="">
    <div>
        <appComp>
           <div class="row">
                  <div class="col-lg-3">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Edit User</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <form>
                              <!-- <div class="form-group">
                                 <div class="add-img-user profile-img-edit">
                                    <img class="profile-pic img-fluid" src="@/assets/images/user/11.png" alt="profile-pic">
                                    <div class="p-image">
                                       <a href="javascript:void();" class="upload-button btn iq-bg-primary">File Upload</a>
                                       <input class="file-upload" type="file" accept="image/*">
                                    </div>
                                 </div>
                                 <div class="img-extension mt-3">
                                    <div class="d-inline-block align-items-center">
                                       <span>Only</span>
                                       <a href="javascript:void();">.jpg</a>
                                       <a href="javascript:void();">.png</a>
                                       <a href="javascript:void();">.jpeg</a>
                                       <span>allowed</span>
                                    </div>
                                 </div>
                              </div> -->
                              <div class="form-group">
                                 <label>Status:</label>
                                 <select class="form-control" v-model="form.statusId" name="statusId" id="statusId">
                                    <option disabled>Select</option>
                                    <option v-for="status in statuses" :value="status.id" :key="status.id">{{ status.name}}</option>
                                 </select>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-9">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Edit User Information</h4>
                           </div>
                        </div>
                        <div class="m-4">
                        <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>
                        <!-- <div v-if="message" class="alert alert-danger">{{ message }}</div> -->
                        <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                        </div>
                        <div class="iq-card-body">
                           <div class="new-user-info">
                              <form @submit.prevent="onSubmit()">
                                 <div class="row">
                                    <div class="form-group col-md-6">
                                       <label for="username">Username:</label>
                                       <input type="text" class="form-control" v-model="form.username" name="username" id="username" placeholder="Enter Username">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="displayName">Display Name:</label>
                                       <input type="text" class="form-control" v-model="form.displayName" name="displayName" id="displayName" placeholder="Enter Display Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="email">Email:</label>
                                       <input type="email" class="form-control" v-model="form.email" name="email" id="email" placeholder="Enter Email Address">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="phone_no">Phone Number:</label>
                                       <input type="text" class="form-control" v-model="form.msisdn" name="phone_no" id="phone_no" placeholder="Enter Phone Number">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="password">Check if password change is required at next login</label> 
                                       <input v-model="form.passwordChangeRequired" 
                                             type="checkbox" 
                                             value="true" 
                                             name="al" /> 
                                       <label for="al">Yes</label><br /> 
                                    </div>
                                    <!-- <div class="form-group col-md-12">
                                       <label for="expiryDate">Expiry Date:</label>
                                       <input type="datetime-local" class="form-control" v-model="form.expiryDate" name="expiryDate" id="expiryDate" placeholder="Enter Expiry Date">
                                    </div> -->
                                 </div>
                                 <hr>
                                 <!-- <h5 class="mb-3">ReadOnly</h5> -->
                                 <!-- <div class="row">
                                    <div class="form-group col-md-6">
                                       <label for="date">Date:</label>
                                       <input type="text" class="form-control" id="date" placeholder="Date" readonly>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="password_date">Password Date:</label>
                                       <input type="text" class="form-control" id="password_date" placeholder="Password Date" readonly>
                                    </div>
                                 </div> -->
                                 <button type="submit" class="btn btn-primary">Update User</button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
           </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import Swal from 'sweetalert2';

const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;
export default {
    data() {
      return{
         statuses: [],
         form: {
            statusId: '',
            username:'',
            displayName: '',
            email: '',
            msisdn: '',
            password: '',
            // expiryDate: '',
         },
         // messages 
         message : [],
         successMessage : "",
      }
    },
    components: {
        appComp
    },
    methods: {
      async loadLists(){
         delete axios.defaults.headers.common['Authorization'];
         axios.get("/lists", { headers: {
            
                  'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
         }},{
            }).then((response) => {
               this.channels = response.data.data.channeltypes
               this.statuses = response.data.data.status
               // console.log( response.data.data)

         });
      },
      getData() {
         // console.log(axios);

         delete axios.defaults.headers.common["X-CG-Authorization"];
         axios
               .get(
                  "/organizations/" +
                  this.$route.params.org +
                  "/identities/" +
                  this.$route.params.id,
                  {
                     headers: {
                           Authorization: "Bearer " + localStorage.getItem("token"),
                           "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
                     },
                  }
               )
               .then((response) => {
                  this.form = response.data.data.items[0];
                  this.status = response.data.data.items[0].status.name;
                  // console.log("data", this.form);
               });
      },
      onSubmit(){
         let data;
         data = {
            displayName: this.form.displayName,
            organizationId: 1,
            statusId: this.form.statusId,
            email: this.form.email,
            msisdn: this.form.msisdn,
            passwordChangeRequired: this.form.passwordChangeRequired
         },
         // console.log("form", data)
         axios.patch("/identities/"+this.$route.params.id, data,{
            headers : {
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
            
         }).then((response) => {
            if(response.data.status == 'success')
               {
                  toast.fire({
                  title: 'User Updated',
                  text: "user updated ",
                  icon: 'success',
                  })
               }
         }).catch((e) => 
         {
            // console.log(e.response.data)
            let errors = e.response.data.data;
                    if (errors.length > 1) {
                        errors.forEach(err => {
                           this.message.push(err.message)
                        });
                    } else {
                        errors.forEach(err => {
                           this.message.push(err.message)
                    
                        });
                    }
         })
      }
    },
    mounted(){
      this.loadLists();
      this.getData();
    }

}
</script>
<style lang="">
    
</style>