<template lang="">
  <div>
    <appComp>
      <div class="row">
        <!-- start -->
        <div class="col-lg-4">
          <div class="iq-card">
            <div class="iq-card-body pl-0 pr-0 pt-0">
              <div class="doctor-details-block">
                <div
                  class="doc-profile-bg bg-primary"
                  style="height: 150px"
                ></div>
                <div class="doctor-profile text-center">
                  <img
                    src="@/assets/images/user/11.png"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                </div>
                <div class="text-center mt-3 pl-3 pr-3">
                  <h4>
                    <b>{{ identity.displayName ?? 'N/A' }}</b>
                  </h4>
                </div>
                <hr />
                <ul
                  class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                >                
                  <li class="text-center"></li>
                  <li class="text-center">
                    <button v-if=" hasRole('global.admin') || ( hasRole('organization.admin'))" type="submit"  @click="editUser" class="btn btn-primary float-center">
                      Edit User
                    </button>
                  </li>
                  <li class="text-center"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- start -->

        <div class="col-lg-8">
          <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                 <div class="iq-header-title">
                    <h4 class="card-title">User Information</h4>
                 </div>
              </div>
              <div class="iq-card-body">
                 <div class="about-info m-0 p-0">
                    <div class="row">
                       <div class="col-4">Username:</div>
                       <div class="col-8">{{ identity.username ?? 'N/A'}}</div>
                       <div class="col-4">Display Name:</div>
                       <div class="col-8">{{ identity.displayName ?? 'N/A' }}</div>
                       <div class="col-4">Phone:</div>
                       <div class="col-8">{{ identity.msisdn ?? 'N/A' }}</div>
                       <div class="col-4">Email:</div>
                       <div class="col-8"><a href="#"> {{ identity.email ?? 'N/A' }} </a></div>
                       <div class="col-4">Expiry Date:</div>
                       <div class="col-8"><a href="#"> {{ identity.expiryDate ?? 'N/A'}} </a></div>
                       <div class="col-4">Status:</div>
                       <div class="col-8"><a href="#"> {{ status ?? "N/A" }} </a></div>
                    </div>
                 </div>
              </div>
          </div>          
        </div>
      </div>
    
    </appComp>
  </div>
</template>
<script>
import appComp from "@/components/appComp";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            roles: [],
            identity: [],
            status: "",
        };
    },
    components: {
        appComp,
    },
    computed: {
        ...mapGetters({
            lists: "auth/lists",
            user: "auth/user",
            role: "auth/role",
            current_profile: "auth/profile",
        }),
    },
    methods: {
        hasRole(roleName) {
            return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        editUser() {
            this.$router.push(
                "/edituser/" + this.$route.params.org + "/" + this.$route.params.id
            );
        },
        navigateTo(location) {
            this.$router.push(location);
        },
        getData() {
            // console.log(axios);

            delete axios.defaults.headers.common["X-CG-Authorization"];
            axios
                .get(
                    "/organizations/" +
                    this.$route.params.org +
                    "/identities/" +
                    this.$route.params.id,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
                        },
                    }
                )
                .then((response) => {
                    this.identity = response.data.data.items[0];
                    this.status = response.data.data.items[0].status.name;
                    // console.log("data", this.identity);
                });
        },
    },
    mounted() {
        this.getData();
        this.getRoles()
    },
};
</script>
<style lang="css">
.font-12 {
    overflow-wrap: break-word;
    word-wrap: break-word;
}
</style>
