<template lang="">
    <div>
         <section class="sign-in-page">
            <div class="container sign-in-page-bg mt-5 p-0">
                <div class="row no-gutters">
                    <!-- descriptor slider -->
                    <div class="col-md-6 text-center">
                    <sliderComp />
                    </div>
                    <!-- descripto slider -->
                       <div class="col-md-6 position-relative">
                        <div class="sign-in-from">
                            <h1 class="mb-0">Reset Password</h1>
                            <p>Enter username provided during OTP initiation and the OTP received via the communication channel chosen to reset your password.</p>
                             <div v-if="message" class="alert alert-danger">{{ message }}</div>
                                 <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                            <form @submit.prevent="submitHandler" class="mt-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Username</label>
                                    <input 
                                    v-model="username" type="text" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter account username/email/phone">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">OTP</label>
                                    <input 
                                    v-model="otp" type="text" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter OTP">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">New Password</label>
                                    <input 
                                    v-model="password" @input="validatePassword" type="password" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter new password">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Confirm Password</label>
                                    <input 
                                    v-model="confirm_password" @input="validatePassword" type="password" class="form-control mb-0" id="exampleInputEmail1" placeholder="Confirm password">
                                </div>

                                <div class="d-inline-block w-100">
                                      <button
                                        type="submit"
                                        class="btn btn-primary float-right"
                                        :disabled="loading || passwordsDoNotMatch"
                                        >
                                        <span v-if="loading" class="spinner-border spinner-border-sm mr-2"></span>
                                        Submit
                                        </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import sliderComp from './components/sliderComp.vue';
// import {mapActions, mapGetters} from "vuex"
import axios from 'axios'


export default {
    name: "resetPage",
    data(){
        return {
            channels : [],
            otp : null,
            password: null,
            confirm_password: null,
            username : null,
            message: '',
            successMessage: null,
            loading: false,

        }

    },
    methods : {
        validatePassword() {
        if (this.password !== this.confirm_password) {
            this.message = "Passwords do not match";
        } else {
            this.message = null;
        }
        },
        submitHandler()
        {
            this.successMessage = null;
            this.message = null;
            //loading 
            this.loading = true

            // Validate form data
            if (
                !this.username || !this.otp || !this.password
            ) {
                this.message = "Please fill in all fields.";
                this.loading = false
                return;
            }

            // delete axios.defaults.headers.common['X-CG-Authorization'];
            axios.patch('/identities',{
                username : this.username,
                otp : this.otp,
                password : this.password
            }, {
                headers : {
                    // 'Authorization' : "Bearer "+localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

                }
            }).then((response) => {
                console.log(response.data)
                    if (response.data && response.data.status == 'success' && response.data.code == 200) {
                        this.successMessage = "Your password has been reset succesfully";
                        setTimeout(() => {
                            this.$router.push({ name: 'login' })
                        }, 1200);
                    }
            })  .catch((error) => {
                let errors = error.response.data.data;
                console.log(error.response.data.data)
                if (errors.length > 2) {
                    errors.forEach(err => {
                        if(err.message != "null")
                        {
                            this.message += err.message + ", "
                        }
                    });
                } else {
                    errors.forEach(err => {
                        this.message = err.message
                    });
                }
                            
            }).finally(() => {
                this.loading = false
            });
  

            // axios.post('/otp',
            // {
            //     username : this.username,
            //     channelTypeId : this.channelType
            // }).then((response) => {
            //         console.log(response.data)
            //         if (response.data && response.data.status == 'success' && response.data.code == 200) {
            //             this.successMessage = "Your OTP has been sent succesfully to "+ this.username;
            //             // setTimeout(() => {
            //             //     this.$router.push({ name: 'login' })
            //             // }, 1200);
            //         }

            // })
            // .catch((error) => {
            //     let errors = error.response.data.data;
            //     console.log(error.response.data.data)
            //     if (errors.length > 2) {
            //         errors.forEach(err => {
            //             if(err.message != "null")
            //             {
            //                 this.message += err.message + ", "
            //             }
            //         });
            //     } else {
            //         errors.forEach(err => {
            //             this.message = err.message
            //         });
            //     }
            
            // }).finally(() => {
            //     this.loading = false
            // });
    




        }
    },
  
    computed: {
        passwordsDoNotMatch() {
        return this.password !== this.confirm_password;
        },
    },
    components: {
        sliderComp
    },
    
   mounted () {
        // delete axios.defaults.headers.common['Authorization'];
        // axios.get("/lists", { headers: {
            
        //             // 'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
        //             'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
        // }},{
        //     }).then((response) => {
        //         this.channels = response.data.data.channeltypes

        // });
   }
}
</script>
