<template lang="">
    <div>
        <appComp>
                  <div class="iq-card col-lg-12">
                    <div class="iq-card-body">
                           <p>All Pregnancies</p>
                           <table id="profileTable" class="table table-hover">
                              <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Organization</th>
                                    <th scope="col">Registrant Type</th>
                                    <th scope="col">No of Children</th>
                                    <th scope="col">Region</th>
                                    <th scope="col">Town</th>
                                    <th scope="col">EDD</th>
                                    <th scope="col">Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr v-for="pregnancy in pregnancies" :key="pregnancy.id">
                                    <th scope="row">{{pregnancy.id}}</th>
                                    <td>{{pregnancy.registrant.organization['name']}}</td>
                                    <td>{{pregnancy.registrant.type['name']}}</td>
                                    <td>{{pregnancy.totalChildren}}</td>
                                    <td>{{pregnancy.mother?.region.name ?? '-'}}</td>
                                    <td>{{ pregnancy.mother?.town.name ?? '-'}}</td>
                                    <td>{{pregnancy.expectedDeliveryDate ?? "-"}}</td>
                                    <td><span class="badge badge-pill badge-success">Progressing</span></td>
                                    <td>
                                        <div class="row justify-space-between">
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                                <a @click="navigateTO('/viewpregnancy/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Pregnancy</a>
                                                <a @click="navigateTO('/childrenList/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Children</a>
                                                <a v-if="pregnancy.mother && pregnancy.mother.id > 1" @click="navigateTO('/viewmothermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Mother Medical Chart</a>
                                                <a v-if="pregnancy.father && pregnancy.father.id > 1" @click="navigateTO('/viewfathermedicalchart/'+pregnancy.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Father Medical Chart</a>
                                            </div>
                                        </div>
                                        </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                  </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            pregnancies: [],
            roles: []
        }
    },
    computed: {
        ...mapGetters({
            lists: "auth/lists",
            user: "auth/user",
            role: "auth/role",
            current_profile: "auth/profile",
        }),
    },
    methods: {
        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];

        },
        getOrg(){
            delete axios.defaults.headers.common['X-CG-Authorization'];
            axios.get('/organizations', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
            }).then((response) => {
                this.organizations = response.data.data.items
                
            });
            console.log(this.organizations);
        },
        navigateTO(location) {
            this.$router.push(location)
        },
        navigateTo(location) {
            this.$router.push(location)
        },

        getData() {
            console.log(axios);

            delete axios.defaults.headers.common['X-CG-Authorization'];
            axios.get('/pregnancies', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
            }).then((response) => {
                this.pregnancies = response.data.data.items
                setTimeout(() => {
                    $("#profileTable").DataTable();
                }, 10);
                console.log("data", this.pregnancies);
            });
        }
    },
    //methods go here
    components: {
        appComp
    },
    mounted() {
        this.getData();
        this.getRoles();
    }

}
</script>
<style lang="">
    
</style>