<template lang="">
    <div>
        <!-- <div class="row col-lg-12">

            <div class="col-lg-3 d-flex justify-content-end">
                <button @click="setTab(1)" class="btn btn-primary"> General</button>
            </div>
            <div class="col-lg-2 d-flex justify-content-end">
                <button @click="setTab(2)" class="btn btn-secondary"> Allergies</button>
            </div>
            <div class="col-lg-2 d-flex justify-content-end">
                <button  @click="setTab(2)" class="btn btn-secondary"> Lifestyles</button>
            </div>
           
            <div class="col-lg-2 d-flex justify-content-end">
                <button class="btn btn-secondary"> Medications</button>
            </div>
            <div class="col-lg-2 d-flex justify-content-end">
                <button class="btn btn-secondary"> History</button>
            </div>
        </div> -->

        <form @submit.prevent>


            <div v-if="currentTab === 1" class="pt-3 row align-items-center">
                 <div class="form-group col-sm-12">
                     <AllergiesComp />
                    
                 </div>
                 <!-- <div class="form-group col-sm-6">
                     <label for="uname">Clinical Visits:</label>
                     <input type="text" class="form-control" id="uname" >
                 </div> -->
                 <!-- <div class="form-group col-sm-6">
                     <label for="uname">Family History:</label>
                     <input type="date" class="form-control" id="uname" >
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="cname">History:</label>
                     <input type="date" class="form-control" id="cname" >
                 </div>
                  -->
                 <!-- <div class="form-group col-sm-6">
                     <label for="cname">Immunizations:</label>
                     <input type="text" class="form-control" id="cname" >
                 </div> -->
                 <!-- <div class="form-group col-sm-6">
                     <label for="lname">Lifestyles:</label>
                     <select class="form-control" multiple  name="lifestyles" id="lifestyles">
                         <option disabled>Select</option>
                        
                     
                     </select>
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="lname">Medications:</label>
                     <input type="text" class="form-control" id="lname" >
                 </div> -->
                 <div class="form-group col-sm-6">
                     <label for="lname">Pregnancies Beyond 6 Months:</label>
                     <input type="text" class="form-control" id="lname" >
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="lname">Terminations less than 24 Weeks:</label>
                     <input type="text" class="form-control" id="lname" >
                 </div>
                 <!-- <div class="form-group col-md-6">
                     <label for="regionId">Region:</label>
                     <select  class="form-control"  name="regionId" >
                         <option unselected>Select</option>
                         <option >hey</option>
                         
                     </select>
                 </div>
                 <div class="form-group col-md-6">
                     <label for="townId">Town:</label>
                    
                 </div> -->
             </div>
            <div v-if="currentTab === 2" class="pt-3 row align-items-center">
                 
                <!-- allergies -->
                <div class="col-lg-12">


                    <AllergiesComp />


                </div>




                 
             </div>





            <div v-if="currentTab === 3" class="pt-3 row align-items-center">
                 
                 <!-- <div class="form-group col-sm-6">
                     <label for="uname">Family History:</label>
                     <input type="date" class="form-control" id="uname" >
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="cname">History:</label>
                     <input type="date" class="form-control" id="cname" >
                 </div> -->
                 
                 <div class="form-group col-sm-6">
                     <label for="cname">Immunizations:</label>
                     <input type="text" class="form-control" id="cname" >
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="lname">Lifestyles:</label>
                     <select class="form-control" multiple  name="lifestyles" id="lifestyles">
                         <option disabled>Select</option>
                        
                     
                     </select>
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="lname">Medications:</label>
                     <input type="text" class="form-control" id="lname" >
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="lname">Pregnancies Beyond 6 Months:</label>
                     <input type="text" class="form-control" id="lname" >
                 </div>
                 <div class="form-group col-sm-6">
                     <label for="lname">Terminations less than 24 Weeks:</label>
                     <input type="text" class="form-control" id="lname" >
                 </div>
                 <!-- <div class="form-group col-md-6">
                     <label for="regionId">Region:</label>
                     <select  class="form-control"  name="regionId" >
                         <option unselected>Select</option>
                         <option >hey</option>
                         
                     </select>
                 </div>
                 <div class="form-group col-md-6">
                     <label for="townId">Town:</label>
                    
                 </div> -->
             </div>



             <div class="row">
                <div class="col-6">

                    <button type="reset" @click="previous" class="btn iq-bg-danger">Back</button>
                    
                </div>
                <div class="col-6  d-flex justify-content-end d-flex justify-content-end">


                    <button type="submit" @click="handleSubmit" class="btn btn-primary mr-2">Submit</button>
                </div>

               </div>
         </form>



        
    </div>
</template>
<script>

import AllergiesComp from './components/AllergiesComp.vue'
export default {
    data() {
        return {
            currentTab: 1,
        }
    },
    methods: {
        setTab(id) {
            this.currentTab = id;

        },
        handleSubmit() {

            alert('submit the data then next tab')

            this.$emit('handle-submit', 3);
        },
        previous() {
            this.$emit('handle-submit', 1);
        }
    },

    mounted() {
        this.currentTab = 1;
    },

    components: {

        AllergiesComp
    }
}
</script>
<style lang="">
    
</style>