<template lang="">
    <div class="row no-gutters">
        <div class="col-sm-12 text-center">
            <div class="iq-error">
              <h1 class="text-primary">404</h1>
                <!-- <img src="images/error/01.png" class="img-fluid iq-error-img" alt=""> -->
                <h2 class="mb-0">Oops! This Page is Not Found.</h2>
                <p>The requested page dose not exist.</p>
                <a class="btn btn-primary mt-3" href="#" @click="goBack"><i class="ri-home-4-line"></i>Back to Home</a>                            
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {

        }
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        }
    },
    mounted() {
        
    }

}
</script>
<style lang="">
    
</style>