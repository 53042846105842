<template lang="">
    <div>
        <appComp>
            <div>
            <!-- <button @click="openModal">Open Modal</button> -->
    
    <ModalComponent :is-open="isModalOpen" @update:is-open="isModalOpen = $event">
      <template v-slot:header>
        <h2>Modal Header</h2>
      </template>
      <template v-slot:body>
        <p>This is the modal body.</p>
      </template>
      <template v-slot:footer>
        <button @click="closeModal">Close</button>
      </template>
    </ModalComponent>
  </div>
                  <div class="iq-card col-lg-12">
                    <div class="iq-card-body">
                           <p>Children List</p>
                           <!-- Start Custom Preloader -->

                           <div v-if="isLoading" class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                            </div>
                            <!-- End Custom Preloader -->
                           <table v-else id="childrenTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Locator ID</th>
                                    <th scope="col">Display Name</th>
                                    <th scope="col">First Name</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Date of Birth</th>                                    
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>

                                <tr v-for="child in children" :key="child.id">
                                    <th scope="row">{{child.id}}</th>
                                    <td>{{child.locatorId}}</td>
                                    <td>{{child.displayName ?? "-"}}</td>
                                    <td>{{child.firstName ?? "-"}}</td>
                                    <td>{{child.LastName ?? "-"}}</td>
                                    <td>{{child.gender['name'] ?? "-"}}</td>
                                    <td>{{child.birthDate ?? "-"}}</td>
                                    <td>
                                        <div class="row justify-space-between">
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                                
                                                <a @click="navigateTO('/viewchildinfo/' + child.id + '?pregnancyId=' + (pregnancyId || ''))" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View</a>   
                                                <a v-if="(hasRole('organization.admin') || hasRole('healthcare.facility.admin') || hasRole('healthcare.facility.user')) " @click="navigateTO('/childvisits/' + child.id + '?pregnancyId=' + (pregnancyId || ''))" class="dropdown-item" href="#"><i class="ri-add-fill mr-2"></i>Add Clinical Visit</a>
                                                <a v-if="(hasRole('organization.admin') || hasRole('healthcare.facility.admin') || hasRole('healthcare.facility.user')) " @click="navigateTO('/childimmunizations/'+ child.id + '?pregnancyId=' + (pregnancyId || ''))" class="dropdown-item" href="#"><i class="ri-add-fill mr-2"></i>Add Immunization</a>                                    
                    
                                            
                                            </div>
                                        </div>
                                        </div>
                                    </td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                  </div>
        </appComp>
    </div>
</template>



<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';
import ModalComponent from "@/components/reusable/ModalComponent.vue";

export default {
    data() {
        return {
            //
            isModalOpen: false,
            //
            children: [],
            roles: [],
            isLoading: true, // Add loading state
            pregnancyId: null, // Initialize pregnancyId in the data
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            role: 'auth/role',
            current_profile: 'auth/profile'
        }),
    },
    //methods go here
    methods: {
        openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
      getData()
      {
        this.pregnancyId = this.$route.params.id;

        // console.log("Monyancha Children", this.children)
        this.isLoading = true; // Set isLoading to true before making the API call

        delete axios.defaults.headers.common['X-CG-Authorization'];
        //  get organizations
        try {
         axios.get('/pregnancies/' + this.pregnancyId , {
            headers : {
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            this.children = response.data.data?.items[0].children

            setTimeout(() => {
                $("#childrenTable").DataTable();
            }, 10);
            // Set isLoading to false once data is loaded
            this.isLoading = false;


            // console.log("Monyancha Children", this.children)

        });
        } catch (error) {
            // console.error("Error fetching data:", error);
            this.isLoading = false; // Make sure isLoading is set to false in case of an error
        }
      },

        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        navigateTO(location) {
            this.$router.push(location)
        },

    },
    components: {
        appComp,
        ModalComponent
    },
    mounted() {
        this.getData();
        this.getRoles();
        delete axios.defaults.headers.common['X-CG-Authorization'];
        axios.get('/organizations', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            this.organizations = response.data.data.items
            setTimeout(() => {
                $("#orgTable").DataTable();
            }, 10);
        });
        // console.log(this.organizations);

    }

}
</script>

