<template lang="">
    <div>
        <appComp>
                  <div class="iq-card col-lg-12">
                    <div class="iq-card-body">
                           <p>Upcoming Mother Appointments</p>
                           <table id="orgTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <th scope="col">Scheduled Visit</th>
                                    <th scope="col">Status</th>
                                 </tr>
                              </thead>
                              <tbody>

                                <tr v-for="child in pregnancy" :key="child.id">
                                    <td>{{child.scheduledVisit ?? "N/A"}}</td>
                                    <td v-if="statusId == 1">Active</td>
                                    <td v-else >Inactive</td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                  </div>
        </appComp>
    </div>
</template>



<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            children: [],
            roles: [],
            statuses: [],
            pregnancy: [], // Initialize pregnancyId in the data
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            role: 'auth/role',
            current_profile: 'auth/profile'
        }),
    },
    //methods go here
    methods: {
        getData(){
            delete axios.defaults.headers.common["X-CG-Authorization"];
            axios
            .get(
                "/pregnancies/" + this.$route.params.id,
                {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
                },
                }
            )
            .then((response) => {
                this.pregnancy = response.data.data.items[0].mother.medical.clinicalVisits;
                setTimeout(() => {
                    $("#orgTable").DataTable();
                }, 10);

                this.allergies = response.data.data.items[0].mother.medical.allergies;
    
                // console.log("data",this.pregnancy);
            });        
        },
        async loadLists(){
            delete axios.defaults.headers.common['Authorization'];
            axios.get("/lists", { headers: {
                
                    'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                
            }},{
                }).then((response) => {
                this.statuses = response.data.data.status
                // console.log(this.statuses)

            });
        },

        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        navigateTO(location) {
            this.$router.push(location)
        },

    },
    components: {
        appComp
    },
    mounted() {
        this.getData();
        this.getRoles();
        this.loadLists();

    }

}
</script>

