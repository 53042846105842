<template lang="">
   <div>
       <appComp>
          <div class="row">
                 <div class="col-lg-3">
                    <div class="iq-card">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                             <h4 class="card-title">Edit Organization</h4>
                          </div>
                       </div>
                       <div class="iq-card-body">
                          <form>
                             <div class="form-group">
                                <div class="add-img-user profile-img-edit">
                                   <img class="ps-2 img-fluid" src="@/assets/images/corporation.png" alt="organization pic">
                                   <div class="p-image">
                                      <!-- <a href="javascript:void();" class="upload-button btn iq-bg-primary">File Upload</a> -->
                                      <!-- <input class="file-upload" type="file" accept="image/*"> -->
                                   </div>
                                </div>
                                
                             </div>
                             <div v-if="hasRole('global.admin')" class="form-group">
                                 <label>Status:</label>
                                 <select class="form-control" v-model="form.statusId" name="statusId" id="statusId">
                                    <option selected disabled>Select</option>
                                    <option v-for="status in statuses" :value="status.id" :key="status.id">{{ status.name}}</option>
                                 </select>
                              </div>                             
                             <div class="form-group">
                                <label>Organization Type:</label>
                                <select class="form-control" v-model="form.typeId" name="typeId" id="typeId">
                                   <option disabled selected>Select</option>
                                   <option v-for="organizationType in organizationTypes" :value="organizationType.id" :key="organizationType.id">{{ organizationType.name }}</option>
                                 
                                </select>
                             </div>
                             <!-- <div class="form-group">
                                <label for="furl">Parent Organization:</label>
                                <select class="form-control" v-model="form.parentId" name="parentId" id="parentId">
                                   <option disabled  selected>Select</option>
                                   <option v-for="organization in organizations" :key="organization.id">{{ organization.name }}</option>                                 
                                </select>
                             </div> -->
                             <div v-if="healthFacility !== null" class="form-group">
                                <label for="turl">Health Facility Type:</label>
                                <select class="form-control" v-model="form.healthFacilityId" name="parentId" id="parentId">
                                   <option disabled selected>Select</option>
                                   <option v-for="healthFacilitie in healthFacilities" :key="healthFacilitie.id">{{ healthFacilitie.name}}</option>
                                 
                                </select>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>
                 <div class="col-lg-9">
                    <div class="iq-card">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                             <h4 class="card-title">Edit Organization Information</h4>
                          </div>
                       </div>
                       <div class="m-4">
                        <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>
                        <!-- <div v-if="message" class="alert alert-danger">{{ message }}</div> -->
                        <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                       </div>                       
                       <div class="iq-card-body">
                          <div class="new-user-info">
                             <form @submit.prevent="updateOrg">
                                <div class="row">
                                   <div class="form-group col-md-6">
                                      <label for="name">Name:</label>
                                      <input type="text" class="form-control" v-model="form.name" name="name" id="name" placeholder="Enter Name">
                                   </div>
                                    <div class="form-group col-md-6">
                                       <label for="countryId">Country:</label>
                                       <select @change="getRegions" class="form-control" v-model="form.countryId" name="countryId" id="countryId">
                                          <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}</option>
                                       
                                       </select>
                                    </div>                                
                                    <div class="form-group col-md-6">
                                       <label for="regionId">Region:</label>
                                       <select  @change="getTowns" :disabled="!form.countryId" class="form-control" v-model="form.regionId" name="regionId" >
                                          <option unselected>Select</option>
                                          <option  v-for="region in regions"  :key="region.id" :value="region.id">{{ region.name }}</option>
                                          
                                       </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="townId">Town:</label>
                                       <select @change="checkOther" :disabled="!form.regionId" class="form-control" v-model="form.townId" name="townId" id="townId">
                                          <option unselected>Select</option>
                                          <option v-for="town in towns" :value="town.id" :key="town.id">{{ town.name }}</option>
 
                                       </select>
                                    </div>
                                   <!-- <div class="form-group col-md-12">
                                      <label for="town">Town:</label>
                                      <input type="text" class="form-control" v-model="town" name="town" id="town" placeholder="Enter Town Name">
                                   </div> -->
                                   <div class="form-group col-md-6">
                                      <label for="address">Address:</label>
                                      <input type="text" class="form-control" v-model="form.address" name="address" id="address" placeholder="Enter Address">
                                   </div>
                                   <div class="form-group col-md-6">
                                      <label for="contact_person">Contact Person:</label>
                                      <input type="text" class="form-control" v-model="form.contactPerson" name="contact_person" id="contact_person" placeholder="Enter Contact Person">
                                   </div>
                                   <div class="form-group col-md-6">
                                      <label for="email">Email:</label>
                                      <input type="email" class="form-control" v-model="form.email" name="email" id="email" placeholder="Enter Email Address">
                                   </div>
                                   <div class="form-group col-md-6">
                                      <label for="phone_no">Phone Number:</label>
                                      <input type="text" class="form-control" v-model="form.msisdn" name="phone_no" id="phone_no" placeholder="Enter Phone Number">
                                   </div>
                                </div>
                                <hr>
                                <!-- <h5 class="mb-3">ReadOnly</h5> -->

                                <button type="submit" class="btn btn-primary">Update Organization</button>
                             </form>
                          </div>
                       </div>
                    </div>
                 </div>
          </div>
       </appComp>
   </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import { mapGetters } from 'vuex';

import Swal from 'sweetalert2'

const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;
export default {
  data() {
     return {
         form: [],
          
         countries: [],
         regions: [],
         towns: [],         
         organizations: [],
         statuses: [],
         roles: [],
         organizationTypes: [],
         healthFacilities: [],
         healthFacility: '',
         parentOrg: '',

         // messages 
         message : [],
         successMessage : "",
     }
  },


  components: {
     appComp
  },
  computed: {
      ...mapGetters({
         user: 'auth/user',
         role: 'auth/role',
         current_profile: 'auth/profile'
      }),    
  },
  methods: {
   hasRole(roleName) {
      return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
   },
   getRoles() {
      // this.roles = this.user.profiles[this.current_profile].organization.roles;
      this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
   }, 
   async getRegions() {
      try {
         const selectedCountry = this.countries.find(country => country.id === this.form.countryId);
         this.regions = selectedCountry.regions;
         this.regionId = '';
         this.townId = '';
      } catch (error) {
         // console.error(error);
      }
   },

   async getTowns() {
      try {
         const selectedRegion = this.regions.find(region => region.id === this.form.regionId);
         this.towns = selectedRegion.towns;
         this.townId = '';
      } catch (error) {
         // console.error(error);
      }
   },

   checkOther() {
      const other = this.towns.find(town => town.id === this.form.townId);
      if (other.name == "Other") {
         this.others = true;
      } else {
         this.others = false;
      }
   },     
   async getData(){
      delete axios.defaults.headers.common['Authorization'];
      axios.get("/lists", { headers: {
                'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
      }},{
           }).then((response) => {
              this.countries = response.data.data.countries
              this.organizationTypes = response.data.data.organizationtypes
              this.healthFacilities = response.data.data.healthfacilitytypes
              this.statuses = response.data.data.status

              // console.log( this.healthFacilities)

       });
    },
    async getOrg() {
    
      delete axios.defaults.headers.common['X-CG-Authorization'];
      axios.get("/organizations/"+this.$route.params.id, { headers: {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`,
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
      }},{
           }).then((response) => {
              //   console.log(response.data.data.items[0])
              this.form = response.data.data.items[0]
              this.healthFacility = this.form.heathFacilityType.id;
              this.parentOrg = this.form.parentid;
              // console.log("form",response)

       }); 
    },
    updateOrg(){
      this.message = []


      // var instance = axios.create();
      // console.log("fomr", this.form)
      delete axios.defaults.headers.common['X-CG-Authorization'];
       let data = {
          name: this.form.name,
          parentId: this.form.parentId,
          contactPerson: this.form.contactPerson,
          countryId: this.form.countryId,
          address: this.form.address,
          typeId: this.form.typeId,
          healthFacilityTypeId: this.form.healthfacilityId,
          msisdn: this.form.msisdn,
          townId: this.form.townId,
          email: this.form.email,
          statusId: this.form.statusId
         
       }      
      axios.patch('/organizations/'+ this.$route.params.id,data, {
         headers : {
            'Authorization' : "Bearer "+localStorage.getItem('token'),
            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

         }
      }).then((response) => {
         if(response.data.status == 'success')
         {
            toast.fire(
               'Success!',
               'Organization updated!',
               'success'
            )
            // this.$router.push('/createorganization')
         }
      })  .catch((error) => {
                 // console.log(error)
                  let errors = error.response.data.data;
                  if (errors.length > 1) {
                        errors.forEach(err => {
                           this.message.push(err.message)
                        });
                  } else {
                        errors.forEach(err => {
                           this.message.push(err.message)
                  
                        });
                  }
                  
               }).finally(() => {
                  this.loading = false
      });

    }
    
  },
  mounted() {
   //   axios.get("/lists", {
   //         }).then((response) => {
   //            this.countries = response.data.data.countries
   //            this.organizationTypes = response.data.data.organizationtypes
   //            this.healthFacilities = response.data.data.healthFacilitytypes
   //            this.channels = response.data.data.channeltypes

   //     });
   delete axios.defaults.headers.common['X-CG-Authorization'];
      axios.get('/organizations', {
         headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
         },
      }).then((response) => {
         this.organizations = response.data.data.items
         this.parentorganizations = this.organizations.filter(item => item.parentid === null);
         // console.log("parentOrgs", this.parentorganizations)
      });
      // console.log(this.organizations);
      this.getOrg()
      this.getRoles();
      this.getData()
      
  }

}
</script>
<style lang="">
   
</style>