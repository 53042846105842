<template lang="">
    <div>
      <appComp>
        <!-- Start Custom Preloader -->
        <div  v-if="isLoading" class="iq-card p-5 text-center">
            <div class="spinner-border text-primary" role="status">
            </div>
        </div>
        <!-- End Custom Preloader -->
        <div v-else >
        <div class="row">
          <div class="col-lg-4">
            <div class="iq-card">
              <div class="iq-card-body pl-0 pr-0 pt-0">
                <div class="doctor-details-block">
                  <div
                    class="doc-profile-bg bg-primary"
                    style="height: 150px"
                  ></div>
                  <div class="doctor-profile text-center">
                    <img
                      src="@/assets/images/user/11.png"
                      alt="profile-img"
                      class="avatar-130 img-fluid"
                    />
                  </div>
                  <div class="text-center mt-3 pl-3 pr-3">
                    <h4>
                      <b>{{ profile.displayName }}</b>
                    </h4>
                  </div>
                  <hr />
                  <hr />
                <ul
                  class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                >
                  <li class="text-center">
                      <a @click="navigateTO('/childrenList/'+pregnancy.id)" class="btn btn-outline-success float-center text-success">View Children</a>
                    </li>
                  <li class="text-center">
                      <!-- <button v-show="hasRole('healthcare.facility.user') " type="submit" @click="editPreg" class="btn btn-primary float-center">Edit</button> -->
                      <button  type="submit" @click="editPreg" class="btn btn-primary float-center">Edit</button>
                    </li>
                    <li class="text-center">
                      <a @click="navigateTO('/viewmothermedicalchart/'+pregnancy.id)" class="btn btn-outline-danger float-center text-danger">View Mother</a>
                    </li>
                 
                </ul>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-lg-8">
            <!--<div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Pregnancy Information</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <div class="about-info m-0 p-0">
                  <div class="row">
                    <div class="col-12 row">
                      <div class="col-12 row">
                        <div class="col-6">
                          <h6 class="font-bold">Total Children :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.totalChildren ?? "N/A" }}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Children :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.children ?? "N/A" }}</p>
                        </div>
                      </div> 
                    </div>
                    <div class="col-12 row">
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Registration Date :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.date ?? "N/A" }}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">EDD :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.expectedDeliveryDate ?? "N/A" }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Comments:</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.comments ?? "N/A" }}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Payments :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.payments ?? "N/A" }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Surrogacy :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.surrogacy ?? "N/A" }}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Status :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.status?.name ?? "N/A" }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Expected Delivery Date (EDD): </h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.expectedDeliveryDate ?? "N/A" }}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Channel Type :</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.messagePackageIds ?? "N/A" }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Pregnancies Beyond 6 Months:</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.pregnanciesBeyond6Months ?? "N/A" }}</p>
                        </div>
                      </div>
                      <div class="col-6 row">
                        <div class="col-6">
                          <h6 class="font-bold">Terminations less than 24 Weeks:</h6>
                        </div>
                        <div class="col-6 text-start">
                          <p class="font-12">{{ pregnancy.terminationsLessThan24Weeks ?? "N/A" }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
             <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                   <div class="iq-header-title">
                      <h4 class="card-title">Pregnancy Information</h4>
                   </div>
                </div>
                <div class="iq-card-body">
                   <div class="about-info m-0 p-0">
                      <div class="row">
                         <div class="col-4">Total Children:</div>
                         <div class="col-8">{{ pregnancy.totalChildren ?? "N/A" }}</div>
                         <!--<div class="col-4">Children:</div>
                         <div class="col-8">{{ pregnancy.children ?? "N/A" }}</div>-->
                         <div class="col-4">Registration Date:</div>
                         <div class="col-8">{{ pregnancy.date ?? "N/A" }}</div>
                         <div class="col-4">Expected Delivery Date (EDD):</div>
                         <div class="col-8"><a href="#"> {{ pregnancy.expectedDeliveryDate ?? "N/A" }} </a></div>
                         <div class="col-4">Comments:</div>
                         <div class="col-8"><a href="#">{{ pregnancy.comments ?? "N/A" }}</a></div>
                         <div class="col-4">Payments:</div>
                         <div class="col-8"><a href="#">{{ pregnancy.payments ?? "N/A" }}</a></div>
                         <div class="col-4">Surrogacy:</div>
                         <div class="col-8"><a href="#">{{ pregnancy.surrogacy ?? "N/A" }}</a></div>
                         <div class="col-4">Status:</div>
                         <div class="col-8"><a href="#">{{ pregnancy.status?.name ?? "N/A" }}</a></div>
                         <div class="col-4">Channel Type:</div>
                         <div class="col-8"><a href="#">{{ pregnancy.messagePackageIds ?? "N/A" }}</a></div>  
                                                                                                                                                              
                      </div>
                   </div>
                </div>
             </div>
          </div>
        </div>

        <div class="iq-card col-lg-12">
            <div class="iq-card-body">
                    <p>Mother Allergies List</p>
                    <!-- Start Custom Preloader -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <!-- End Custom Preloader -->                    
                    <table v-else id="allergyTable" class="table table-hover">
                      <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Allergy</th>
                            <th scope="col">Comments</th>
                            <th scope="col">Status</th> 
                          </tr>
                      </thead>
                      <tbody>
                        <tr v-for="allergy in allergies" :key="allergy.id">
                            <th scope="row">{{allergy.id}}</th>
                            <th scope="row">{{allergy.allergyId}}</th>
                            <th scope="row">{{allergy.comments[0].comment ?? "-"}} </th>
                            <td><span class="badge badge-pill badge-success">Active</span></td>
                            
                          </tr>
                      </tbody>
                    </table>
                </div>
          </div>

          <div class="iq-card col-lg-12">
            <div class="iq-card-body">
                    <p>Father Allergies List</p>
                    <!-- Start Custom Preloader -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <!-- End Custom Preloader --> 
                    <table v-else id="fatherAllergyTable" class="table table-hover">
                      <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Allergy</th>
                            <th scope="col">Comments</th>
                            <th scope="col">Status</th> 
                          </tr>
                      </thead>
                      <tbody>
                        <tr v-for="allergy in allergies" :key="allergy.id">
                            <th scope="row">{{allergy.id}}</th>
                            <th scope="row">{{allergy.allergyId}}</th>
                            <th scope="row">{{allergy.comments[0].comment ?? "-"}} </th>
                            <td><span class="badge badge-pill badge-success">Active</span></td>
                            
                          </tr>
                      </tbody>
                    </table>
                </div>
          </div>
        </div>

      </appComp>
    </div>
  </template>
  <script>
  import appComp from "@/components/appComp";
  import axios from "axios";
  import "jquery/dist/jquery.min.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import $ from "jquery";
  import { mapGetters } from "vuex";
  
  export default {
    data() {
      return {
        roles: [],
        profile_roles: [],
        profile: [],
        pregnancy: [],
  
        others: false,
        message: [],
        successMessage: "",
        allergies: [],
      };
    },
    components: {
      appComp,
    },
    computed: {
      ...mapGetters({
        lists: "auth/lists",
        user: "auth/user",
        role: "auth/role",
        current_profile: "auth/profile",
      }),
    },
    methods: {
      hasRole(roleName) {
        return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
      },
      getRoles() {
        // this.roles = this.user.profiles[this.current_profile].organization.roles;
        this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
      },
      
      
      getProfile() {
        // console.log(axios);
  
        delete axios.defaults.headers.common["X-CG-Authorization"];
        axios
          .get(
            "/organizations/" +
            this.$route.params.org +
            "/profiles/" +
            this.$route.params.id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
              },
            }
          )
          .then((response) => {
            this.profile = response.data.data.items[0];
            this.profile_roles = response.data.data.items[0].roles;
  
            // console.log(response);
          });
      },
      getData(){
        this.isLoading = true; // Set isLoading to true before making the API call
        delete axios.defaults.headers.common["X-CG-Authorization"];
        try {
        axios
          .get(
            "/pregnancies/" + this.$route.params.id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
              },
            }
          )
          .then((response) => {
            this.pregnancy = response.data.data.items[0];

            this.allergies = response.data.data.items[0].mother.medical.allergies;


            // Set isLoading to false once data is loaded
            this.isLoading = false;
  

            setTimeout(() => {
                $("#allergyTable").DataTable();
            }, 10);
            setTimeout(() => {
                $("#fatherAllergyTable").DataTable();
            }, 10);

            // Set isLoading to false once data is loaded
            this.isLoading = false;
            // console.log("data",this.pregnancy);

          }); 
          } catch (error) {
              // console.error("Error fetching data:", error);
              this.isLoading = false; // Make sure isLoading is set to false in case of an error
          }                 
      },
      getFather(){

      },
      getMother(){

      },
      editPreg(){
        this.$router.push('/viewpregnancywizard/'+ this.$route.params.id)
      },
      navigateTO(location) {
      this.$router.push(location);
    },
  
    },
    mounted() {
    //   this.getProfile();
    //   this.getRoles();
      this.getData();
    },
  };
  </script>
  <style lang="css">
  .font-12 {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  </style>
  