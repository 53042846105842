<template lang="">
    <div>
         <section class="sign-in-page">
            <div class="container sign-in-page-bg mt-5 p-0">
                <div class="row no-gutters">
                    <!-- descriptor slider -->
                    <div class="col-md-6 text-center">
                    <sliderComp />
                    </div>
                    <!-- descripto slider -->
                    <div class="col-md-6 position-relative">
                        <div class="sign-in-from">
                            <h1 class="mb-0">Sign in</h1>
                            <p>Enter your email address and password to access admin panel.</p>
                              <div v-if="message" class="alert alert-danger">{{ message }}</div>
                            <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                            <form @submit.prevent="submitHandler" class="mt-4">
                                <div class="form-group">
                                    <label for="email">Username</label>
                                    <input type="text" class="form-control mb-0" v-model="form.email" id="email" placeholder="Enter email">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Password</label>
                                    <router-link :to="{name : 'forgot'}"><a class="float-right">Forgot password?</a></router-link>
                                    
                                    <input type="password" class="form-control mb-0" v-model="form.password" id="password" placeholder="Password">
                                </div>
                                <div class="d-inline-block w-100">
                                    <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Remember Me</label>
                                    </div>
                                    <!-- <router-link :to="{ name :'dashboard'}"> -->

                                        <button
                                            type="submit"
                                            class="btn btn-primary text-white float-right"
                                            :disabled="loading"
                                            >
                                            <span v-if="loading" class="spinner-border spinner-border-sm mr-2"></span>
                                            Sign in
                                            </button>
                                        <!-- </router-link> -->
                                </div>
                                
                                <div class="sign-info">
                                    <span class="dark-color d-inline-block line-height-2">Don't have an account? <router-link :to="{name : 'signup'}"><a>Sign Up</a></router-link></span>
                                    <!-- <ul class="iq-social-media">
                                        <li><a href="#"><i class="ri-facebook-box-line"></i></a></li>
                                        <li><a href="#"><i class="ri-twitter-line"></i></a></li>
                                        <li><a href="#"><i class="ri-instagram-line"></i></a></li>
                                    </ul> -->
                                </div>
<!--                                 <p class="float-center pt-2"> Quick Login</p>
                                <div class="col pt-2 row">
                                    <div class="col-2">
                                        <button @click="logInAs('user1')" class="btn btn-primary float-center">user1</button>
                                    </div>
                                    <div class="col-2">
                                        <button @click="logInAs('user2')" class="btn btn-primary float-center">user2</button>
                                    </div>
                                    <div class="col-2">
                                        <button @click="logInAs('user3')" class="btn btn-secondary float-center">user3</button>
                                    </div>
                                    <div class="col-3">
                                        <button @click="logInAs('orgadmin')" class="btn btn-warning float-center">OrgAdmin</button>
                                    </div>
                                    <div class="col-3">
                                        <button @click="logInAs('auditor')" class="btn btn-success float-center">Auditor</button>
                                    </div>
                                </div>
                                <div class="col pt-2 row">
                                    <div class="col-2">
                                        <button @click="logInAs('admin')" class="btn btn-primary float-center">admin</button>
                                    </div>
                                    <div class="col-2">
                                        <button @click="logInAs('orguser')" class="btn btn-primary float-center">orguser</button>
                                    </div>
                                    <div class="col-2">
                                        <button @click="logInAs('healthadmin')" class="btn btn-primary float-center">HealthAdmin</button>
                                    </div>
                                    <div class="col-2">
                                        <button @click="logInAs('healthuser')" class="btn btn-secondary float-center">HealthUser</button>
                                    </div>
                                    <div class="col-2">
                                        <button @click="logInAs('wallet')" class="btn btn-secondary float-center">Wallet</button>
                                    </div>                                    
                                    <div class="col-3">
                                        <button @click="logInAs('helpdesk')" class="btn btn-secondary float-center">HelpDesk</button>
                                    </div>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import sliderComp from './components/sliderComp.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "loginPage",
    data() {
        return {
            form: {
                errors: {},
                id: '',
                email: 'root.mama@api.cugi.co.ke',
                password: 'PhhX9Qr9ST@G8Kd7',
            },
            message: '',
            successMessage: '',
            loading: false,
        }
    },
    components: {
        sliderComp
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
            me: 'auth/me'
        }),

        logInAs(who) {
            switch (who) {
                case 'admin':
                    this.form.email = "root.mama@api.cugi.co.ke"

                    break;
                case 'user1':
                    this.form.email = "camillamakira@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"

                    break;
                case 'user2':
                    this.form.email = "bxy@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"

                    break;
                case 'user3':
                this.form.email = "bro@gmail.com"
                this.form.password = "@2H1bghbdbutz7#!!"

                break;
                case 'orguser':
                this.form.email = "jim.comey@api.cugi.co.ke"
                this.form.password = "@2H1bghbdbutz7#!!"

                break;                
                case 'orgadmin':
                    this.form.email = "jamal.abdu@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;
                case 'auditor':
                    this.form.email = "charlene.lagat@gmail.com"
                    break;
                case 'healthadmin':
                    this.form.email = "wayne@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;
                case 'healthuser':
                    this.form.email = "jeff.sessions@api.cugi.co.ke"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;
                case 'wallet':
                    this.form.email = "robin.kipseret@cugi.co.ke"
                    this.form.password = "@Puvewt5n#!!"
                    break;                       
                case 'helpdesk':
                    this.form.email = "mike.perry@api.cugi.co.ke"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;                  

            }
            // this.submitHandler();
        },


        submitHandler() {
            this.loading = true;
            this.message = ''
            this.successMessage = ''

            this.signIn({
                username: this.form.email,
                password: this.form.password
            }).then((result) => {
                // console.log(result)
                if (result.authenticated) {
                    this.successMessage = 'Successfully signed in';
                    setTimeout(() => {
                        localStorage.setItem("role", "User")
                        this.$router.push({ name: 'dashboard' })
                    }, 700);
                } else {
                    this.message = 'Credentials did not match our records';
                }
            }).catch((error) => {
                this.message = error.response.data.message || 'An error occurred';
            }).finally(() => {
                this.loading = false;

            });
        }


    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user'
        }),
    },
    mounted() {
        this.me()
    }

}
</script>
<style lang="">
    
</style>