<template lang="">
    <div>
         <div class="iq-top-navbar">
                 <div class="iq-navbar-custom">
                    <div class="iq-sidebar-logo">
                       <div class="top-logo">
                          <a href="index-2.html" class="logo">
                          <img src="@/assets/images/logo.png" class="img-fluid" alt="">
                          <span>Tunza Toto</span>
                          </a>
                       </div>
                    </div>
                    <nav class="navbar navbar-expand-lg navbar-light p-0">
                       <div class="iq-search-bar">
                          <!--<form action="#"  class="searchbox">
                             <input type="text" class="text search-input" placeholder="Type here to search...">
                             <a class="search-link" href="#"><i class="ri-search-line"></i></a>
                          </form>-->
                       </div>
                       <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                       <i class="ri-menu-3-line"></i>
                       </button>
                       <div class="iq-menu-bt align-self-center">
                          <div class="wrapper-menu">
                             <div class="main-circle"><i class="ri-more-fill"></i></div>
                             <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
                          </div>
                       </div>
                       <div class="collapse navbar-collapse" id="navbarSupportedContent">
                          <ul class="navbar-nav ml-auto navbar-list">
                             
                             <li class="nav-item iq-full-screen">
                                <a href="#" class="iq-waves-effect" id="btnFullscreen"><i class="ri-fullscreen-line"></i></a>
                             </li>
                             <li class="nav-item">
                                <a href="#" class="search-toggle iq-waves-effect">
                                   <i class="ri-notification-3-fill"></i>
                                   <span class="bg-danger dots"></span>
                                </a>
                                <div class="iq-sub-dropdown">
                                   <div class="iq-card shadow-none m-0">
                                      <div class="iq-card-body p-0 ">
                                         <div class="bg-primary p-3">
                                            <h5 class="mb-0 text-white">All Notifications<small class="badge  badge-light float-right pt-1">4</small></h5>
                                         </div>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/01.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">Emma Watson Bini</h6>
                                                  <small class="float-right font-size-12">Just Now</small>
                                                  <p class="mb-0">95 MB</p>
                                               </div>
                                            </div>
                                         </a>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/02.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">New customer is join</h6>
                                                  <small class="float-right font-size-12">5 days ago</small>
                                                  <p class="mb-0">Jond Bini</p>
                                               </div>
                                            </div>
                                         </a>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/03.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">Two customer is left</h6>
                                                  <small class="float-right font-size-12">2 days ago</small>
                                                  <p class="mb-0">Jond Bini</p>
                                               </div>
                                            </div>
                                         </a>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/04.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">New Mail from Fenny</h6>
                                                  <small class="float-right font-size-12">3 days ago</small>
                                                  <p class="mb-0">Jond Bini</p>
                                               </div>
                                            </div>
                                         </a>
                                      </div>
                                   </div>
                                </div>
                             </li>
                             <li class="nav-item dropdown">
                                <a href="#" class="search-toggle iq-waves-effect">
                                <i class="ri-mail-open-fill"></i>
                                <span class="bg-primary count-mail"></span>
                             </a>
                                <div class="iq-sub-dropdown">
                                   <div class="iq-card shadow-none m-0">
                                      <div class="iq-card-body p-0 ">
                                         <div class="bg-primary p-3">
                                            <h5 class="mb-0 text-white">All Messages<small class="badge  badge-light float-right pt-1">5</small></h5>
                                         </div>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/01.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">Bini Emma Watson</h6>
                                                  <small class="float-left font-size-12">13 Jun</small>
                                               </div>
                                            </div>
                                         </a>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/02.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">Lorem Ipsum Watson</h6>
                                                  <small class="float-left font-size-12">20 Apr</small>
                                               </div>
                                            </div>
                                         </a>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/03.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">Why do we use it?</h6>
                                                  <small class="float-left font-size-12">30 Jun</small>
                                               </div>
                                            </div>
                                         </a>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/04.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">Variations Passages</h6>
                                                  <small class="float-left font-size-12">12 Sep</small>
                                               </div>
                                            </div>
                                         </a>
                                         <a href="#" class="iq-sub-card" >
                                            <div class="media align-items-center">
                                               <div class="">
                                                  <img class="avatar-40 rounded" src="@/assets/images/user/05.jpg" alt="">
                                               </div>
                                               <div class="media-body ml-3">
                                                  <h6 class="mb-0 ">Lorem Ipsum generators</h6>
                                                  <small class="float-left font-size-12">5 Dec</small>
                                               </div>
                                            </div>
                                         </a>
                                      </div>
                                   </div>
                                </div>
                             </li>
                          </ul>
                       </div>
                       <ul class="navbar-list">
                          <li>
                             <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                                <img src="@/assets/images/user/1.jpg" class="img-fluid rounded mr-3" alt="user">
                                <div class="caption">
                                   <h6 class="mb-0 line-height">{{profileInfo.displayName}}</h6>
                                   <!-- <span class="font-size-12">Available</span> -->
                                </div>
                             </a>
                             <div class="iq-sub-dropdown iq-user-dropdown">
                                <div class="iq-card shadow-none m-0">
                                   <div class="iq-card-body p-0 ">
                                      <div class="bg-primary p-3">
                                         <h5 class="mb-0 text-white line-height">Hello {{profileInfo.displayName}}</h5>
                                         <!-- <span class="text-white font-size-12">Available</span> -->
                                      </div>
                                      <a v-if="profiles.length > 1" class="iq-sub-card iq-bg-primary-hover">
                                       <div class="media align-items-center">
                                          
                                          <div class="media-body">
                                             <h6 class="mb-0 "><span class="badge badge-success">Switch Profiles</span></h6>
                                            
                                          </div>
                                       </div>
                                      </a>
                                      <div class="ml-2"  >

                                         <a v-for="(profile,index) in profiles" :key="profile.id" @click="changeProfile(index)" href="#" class="iq-sub-card iq-bg-primary-hover">
                                            <div class="media align-items-end">
                                               <!-- <div class="rounded iq-card-icon iq-bg-primary">
                                                  <i class="ri-file-user-line"></i>
                                               </div> -->
                                               <div class="media-body">
                                                  <h6 class="mb-0">{{profile.displayName}} @ {{profile.organization.name}} <span v-if="index == current_profile" class="badge badge-primary">Current Profile</span> </h6>                                              
                                                  <p v-if="profiles.length > 1" class="mb-0 font-size-12">Switch profile to {{profile.DisplayName}}.
                                                  </p>
                                               </div>
                                            </div>
                                         </a>

                                      </div>
                                      <!-- <a v-if="roles.length > 1" class="iq-sub-card iq-bg-primary-hover">
                                       <div class="media align-items-center">
                                          
                                          <div class="media-body">
                                             <h6 class="mb-0 badge badge-success">Switch Roles</h6>
                                            
                                          </div>
                                       </div>
                                      </a> -->
                                      <!-- <div class="ml-2" v-if="roles.length > 1" >

                                         <a v-for="role in roles" :key="role.id"  @click="changeRole(role.id.split('.role.')[1])" href="#" class="iq-sub-card iq-bg-primary-hover">
                                            <div class="media align-items-end">
                                         
                                              
                                               <div class="media-body ">
                                                  <h6 class="mb-0">{{role.name}} <span v-if="role.id.split('.role.')[1] == current_role" class="badge badge-primary">Current Role</span> </h6>                                              
                                                  <p class="mb-0 font-size-12">Switch Role to {{role.name}}.
                                                  </p>
                                               </div>
                                            </div>
                                         </a>

                                      </div> -->
                                      
                                      <a v-if="hasRole('user')" @click="goToUpdateProfile()" class="iq-sub-card iq-bg-primary-hover">
                                         <div class="media align-items-center">
                                            <div class="rounded iq-card-icon iq-bg-primary">
                                               <i class="ri-profile-line"></i>
                                            </div>
                                            <div class="media-body ml-3">
                                               <h6 class="mb-0 ">Edit Profile</h6>
                                               <p class="mb-0 font-size-12">Modify your personal details.</p>
                                            </div>
                                         </div>
                                      </a>
                                    
                                      <div @click="logout" class="d-inline-block w-100 text-center p-3">
                                         <a class="bg-primary iq-sign-btn" href="#" role="button">Sign out<i class="ri-login-box-line ml-2"></i></a>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </li>
                       </ul>
                    </nav>
                 </div>
              </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
// import sjcl from 'sjcl';
import axios from 'axios';

export default {
   data() {
      return {
         roles: [],
         profiles: [],
         profileInfo: []
      }
   },
   methods: {
      hasRole(roleName) {
         return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      ...mapActions({
         updateProfile: 'auth/updateProfile', updateRole: 'auth/updateRole'
      }),
      goToUpdateProfile() {
         let user_id = this.user.profiles[this.current_profile].id;
         let org_id = this.user.profiles[this.current_profile].organization.id;
         this.$router.push("/updateprofile/" + org_id + "/" + user_id + "/1")
      },
      changeProfile(id) {
         this.goToUpdateProfile()
         // localStorage.setItem("plbs",CryptoJS.AES.encrypt(id, "12345"))
         this.updateProfile(id)
         this.getRoles()
         this.profileInfo = this.user.profiles[this.current_profile];
         // console.log("hi",this.roles, this.current_profile)
         if(id == 0){
            this.$router.push('/dashboard')
         }else
         this.$router.push('/profiledashboard')
      },
      changeRole(id) {
         // console.log(id.split('.role.')[1])
         this.updateRole(id);
         this.$router.push('dashboard')
      },
      logout() {
         // localStorage.removeItem('token');
         // window.location.href = '/';
         delete axios.defaults.headers.common['X-CG-Authorization'];
         axios.delete('/sessions/'+this.session, {
            headers : {
               'Authorization' : "Bearer "+localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

            }
         }).then((response) => {
            if(response.data.status == 'success')
            {
               localStorage.removeItem('token');
               window.location.href = '/';

            }
         })  .catch(() => {
                     
         });
         // this.$router.push('/');
         // Remove token or cookie
         // Clear user information from state
         //  this.$store.commit('clearUser');

         // Redirect to login page
      },

      getProfiles() {
         this.profiles = this.user.profiles;
         // console.log("user profiles", this.profiles)
      },
      getRoles() {
         // this.roles = this.user.profiles[this.current_profile].organization.roles;
         this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];

      }

   },
   computed: {
      ...mapGetters({
         user: 'auth/user',
         current_role: 'auth/role',
         current_profile: 'auth/profile'
      })
   },
   mounted() {
      this.getProfiles()
      this.getRoles()
      this.session = this.user.session.token
      // console.log("user-info", this.session)

   }

}
</script>
<style></style>