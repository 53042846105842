<template lang="">
    <div>
        <appComp>
                  <div class="iq-card col-lg-12">
                    <div class="iq-card-body">
                           <p>Upcoming Children Appointments</p>
                           <table id="orgTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <th scope="col">Child Name</th>
                                    <th scope="col">Birth Date</th>
                                    <th scope="col">No. of Visits</th>
                                    <th scope="col">Action</th>
                                 </tr>
                              </thead>
                              <tbody>

                                <tr v-for="child in pregnancy" :key="child.id">
                                    <td>{{child.firstName ?? "N/A"}} {{child.lastName ?? "N/A"}}</td>
                                    <td>{{child.birthDate}}</td>
                                    <td>{{child.medical.clinicalVisits.length}}</td>
                                    <td>
                                        <div class="row justify-space-between">
                                        <div class="btn-group" role="group">
                                            <button type="button" @click="navigateTO('/viewchildinfo/' + child.id + '?pregnancyId=' + (pregnancyId || ''))" class="btn mb-2 btn-primary rounded-pill">More</button>
                                        </div>
                                        </div>
                                    </td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                  </div>
        </appComp>
    </div>
</template>



<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            children: [],
            roles: [],
            statuses: [],
            pregnancyId: null,
            pregnancy: [], // Initialize pregnancyId in the data
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            role: 'auth/role',
            current_profile: 'auth/profile'
        }),
    },
    //methods go here
    methods: {
        getData(){
            delete axios.defaults.headers.common["X-CG-Authorization"];
            axios
            .get(
                "/pregnancies/" + this.$route.params.id,
                {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
                },
                }
            )
            .then((response) => {
                this.pregnancy = response.data.data.items[0].children;
                setTimeout(() => {
                    $("#orgTable").DataTable();
                }, 10);

                this.allergies = response.data.data.items[0].mother.medical.allergies;
    
                console.log("data",this.pregnancy);
            });        
        },
        async loadLists(){
            delete axios.defaults.headers.common['Authorization'];
            axios.get("/lists", { headers: {
                
                    'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                
            }},{
                }).then((response) => {
                this.statuses = response.data.data.status
                console.log(this.statuses)

            });
        },

        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        navigateTO(location) {
            this.$router.push(location)
        },

    },
    components: {
        appComp
    },
    mounted() {
        this.getData();
        this.getRoles();
        this.loadLists();
        this.pregnancyId = this.$route.params.id;

    }

}
</script>

