<template lang="">
    <div>
         <footer class="bg-white iq-footer">
                 <div class="container-fluid">
                    <div class="row">
                       <div class="col-lg-6">
                          <ul class="list-inline mb-0">
                             <li class="list-inline-item"><a href="#">Visit Our Website</a></li>
                             <!-- <li class="list-inline-item"><a href="terms-of-service.html">Terms of Use</a></li> -->
                          </ul>
                       </div>
                       <div class="col-lg-6 text-right">
                          Copyright {{ new Date().getFullYear() }} ©<a>Tunza Toto</a> All Rights Reserved.
                       </div>
                    </div>
                 </div>
        </footer>
    </div>
</template>
<script>

export default {
   data(){
      return {
         currentYear: ''
      }
   }
}
</script>
<style lang="">
    
</style>