import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

// pages
import loginPage from "@/pages/authentication/loginPage";
import forgotPage from "@/pages/authentication/forgotPage";
import resetPage from "@/pages/authentication/resetPage";
import signupPage from "@/pages/authentication/signupPage";
import adminPage from "@/pages/dashboard/adminPage";
import errorPage from "@/pages/errorPage";
// import notFoundErrorPage from "@/pages/notFoundErrorPage";
import notFoundPage from "@/pages/notFoundPage";
import userPageTest from "@/pages/dashboard/userPageTest";
import userProfilePage from "@/pages/dashboard/userProfilePage";
import createOrganizationPage from "@/pages/organizations/createOrganizationPage";
import createOrganizationBranchPage from "@/pages/organizations/createOrganizationBranchPage";
import editOrganizationPage from "@/pages/organizations/editOrganizationPage";
import viewOrganizationPage from "@/pages/organizations/viewOrganizationPage";
import createUserPage from "@/pages/users/createUserPage";
import updateUserPage from "@/pages/users/updateUserPage";
import viewUserPage from "@/pages/users/viewUserPage";
import createProfilePage from "@/pages/profiles/createProfilePage";
import createOrgProfilePage from "@/pages/profiles/createOrgProfilePage";
import viewProfilePage from "@/pages/profiles/viewProfilePage";
import allProfilesPage from "@/pages/profiles/allProfilesPage";
import allUsersPage from "@/pages/users/allUsersPage";
import updateProfilePageTest from "@/pages/profiles/updateProfilePageTest";
import allOrganizationsPage from "@/pages/organizations/allOrganizationsPage";
import maternalPage from "@/pages/maternal/maternalPage";
import clinicalVisitsPage from "@/pages/maternal/clinicalVisitsPage";
import createClinicalVisitPage from "@/pages/maternal/createClinicalVisitPage";
import commentsPage from "@/pages/maternal/commentsPage";
import allJourneyPage from "@/pages/maternal/allJourneyPage";
import createJourneyPage from "@/pages/maternal/createJourneyPage";
import viewChildrenPage from "@/pages/children/viewChildrenPage";
import createMedicationPage from "@/pages/maternal/createMedicationPage";
import createImmunizationPage from "@/pages/maternal/createImmunizationPage";
import viewPregnancyPage from "@/pages/pregnancies/viewPregnancyPage";
import allProfilePregnanciesPage from "@/pages/pregnancies/allProfilePregnanciesPage";
import viewMotherMedicalChartPage from "@/pages/maternal/viewMotherMedicalChartPage";
import viewFatherMedicalChartPage from "@/pages/maternal/viewFatherMedicalChartPage";
import viewPregnancyWizard from "@/pages/pregnancies/viewPregnancyWizard";
import listChildrenPage from "@/pages/children/listChildrenPage.vue";
import childClinicalVisitsPage from "@/pages/children/childClinicalVisitsPage";
import childImmunizationsPage from "@/pages/children/childImmunizationsPage";
import viewChildInfo from "@/pages/children/viewChildInfo";
import motherClinicalVisitsPage from "@/pages/mother/motherClinicalVisitsPage";
import motherImmunizationsPage from "@/pages/mother/motherImmunizationsPage";
import viewMotherClinicalVisitsPage from "@/pages/mother/viewMotherClinicalVisitsPage";
import viewChildrenClinicalVisitsPage from "@/pages/mother/viewChildrenClinicalVisitsPage";
import paymentsPage from "@/pages/payments/paymentsPage";
import adminPaymentsPage from "@/pages/payments/adminPaymentsPage";
import selfResetPasswordPage from "@/pages/dashboard/selfResetPasswordPage";
import chronicDiseasesPage from "@/pages/profiles/chronicDiseasesPage";
import chronicPage from "@/pages/chronic-diseases/chronicDiseasesPage";

/* eslint-disable */
const routes = [
    {
        path: '/',
        name: 'login',
        component: loginPage,
        meta: {
            authRequired: 'false',
        },

    },
    {
        path: '/signup',
        name: 'signup',
        component: signupPage,
        meta: {
            authRequired: 'false',
        },

    },
    {
        path: '/forgot',
        name: 'forgot',
        component: forgotPage,
    },
    {
        path: '/reset',
        name: 'reset',
        component: resetPage,
    },
    {
        path: '/error',
        name: 'error',
        component: errorPage,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: notFoundPage,
    },    
    // {
    //     path: '/:pathMatch(.*)*',
    //     name: 'notfound',
    //     component: notFoundErrorPage,
    //     meta: {
    //         authRequired: 'false',
    //     },
    // },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: userPageTest,
        meta: { requiresAuth: true },

    },
    {
        path: '/profiledashboard',
        name: 'profiledashboard',
        component: userProfilePage,
        meta: { requiresAuth: true },

    },
    {
        path: '/maindashboard',
        name: 'maindashboard',
        component: adminPage,
        meta: { requiresAuth: true },

    },
    {
        path: '/selfreset/:id',
        name: 'selfreset',
        component: selfResetPasswordPage,
        meta: { requiresAuth: true },

    },    

    {
        path: '/createorganization',
        name: 'createorganization',
        component: createOrganizationPage,
        meta: { requiresAuth: true },

    },

    {
        path: '/createorganizationbranch/:id',
        name: 'createorganizationbranch',
        component: createOrganizationBranchPage,
        meta: { requiresAuth: true },

    },

    {
        path: '/editorganization/:id',
        name: 'editorganization',
        component: editOrganizationPage,
        meta: { requiresAuth: true },

    },
    {
        path: '/vieworganization/:id',
        name: 'vieworganization',
        component: viewOrganizationPage,
        meta: { requiresAuth: true },

    },
    {
        path: '/createuser',
        name: 'createuser',
        component: createUserPage,

    },

    {
        path: '/createprofile',
        name: 'createprofile',
        component: createProfilePage,
        meta: { requiresAuth: true },

    },
    {
        path: '/createorgprofile',
        name: 'createorgprofile',
        component: createOrgProfilePage,
        meta: { requiresAuth: true },

    },

    {
        path: '/updateprofile/:org/:id/:type',
        name: 'updateprofile',
        component: updateProfilePageTest,
        meta: { requiresAuth: true },

    },

    {
        path: '/profiles/:id',
        name: 'profiles',
        component: allProfilesPage,
        meta: { requiresAuth: true },

    },
    {
        path: '/viewprofile/:org/:id',
        name: 'viewprofile',
        component: viewProfilePage,
        meta: { requiresAuth: true },

    },
    {
        path: '/chronic-diseases/:org/:id',
        name: 'chronic-diseases',
        component: chronicDiseasesPage,
        meta: { requiresAuth: true },

    },
    {
        path: '/chronic-diseases',
        name: 'chronic-diseases',
        component: chronicPage,
        meta: { requiresAuth: true },

    },
    {
        path: '/organizations',
        name: 'organizations',
        component: allOrganizationsPage,
        meta: { requiresAuth: true },


    },

    {
        path: '/users/:id',
        name: 'users',
        component: allUsersPage,

    },
    {
        path: '/viewuser/:org/:id',
        name: 'viewuser',
        component: viewUserPage,
        meta: { requiresAuth: true },

    },

    {
        path: '/edituser/:org/:id',
        name: 'edituser',
        component: updateUserPage,
        meta: { requiresAuth: true },

    },

    {
        path: '/maternal',
        name: 'maternal',
        component: maternalPage,

    },
    {
        path: '/clinicalvisits/:id',
        name: 'clinicalvisits',
        component: clinicalVisitsPage,

    },
    {
        path: '/addclinicalvisit/:journ/:id',
        name: 'add-clinicalvisit',
        component: createClinicalVisitPage,

    },
    {
        path: '/childvisits/:id',
        name: 'childvisits',
        component: childClinicalVisitsPage,

    },
    {
        path: '/viewchildinfo/:id',
        name: 'viewchildinfo',
        component: viewChildInfo,

    },
    {
        path: '/childimmunizations/:id',
        name: 'childimmunizations',
        component: childImmunizationsPage,

    },
    //Mother Clinic and Immunizations
    {
        path: '/motherimmunizations/:id',
        name: 'motherimmunizations',
        component: motherImmunizationsPage,

    },
    {
        path: '/mothervisits/:id',
        name: 'mothervisits',
        component: motherClinicalVisitsPage,

    },

    {
        path: '/addclinicalvisit/:id',
        name: 'add-clinicalvisit',
        component: createClinicalVisitPage,

    },
    {
        path: '/comments',
        name: 'comments',
        component: commentsPage,

    },
    {
        path: '/journey',
        name: 'journey',
        component: createJourneyPage,

    },
    {
        path: '/journeys',
        name: 'journeys',
        component: allJourneyPage,

    },       
    {
        path: '/viewchildren/:id',
        name: 'viewchildren',
        component: viewChildrenPage,

    },
    {
        path: '/childrenlist/:id',
        name: 'childrenList',
        component: listChildrenPage,

    },
    
    {
        path: '/viewpregnancy/:id',
        name: 'viewpregnancy',
        component: viewPregnancyPage,

    },
    {
        path: '/viewpregnancywizard/:id',
        name: 'pregnancywizard',
        component: viewPregnancyWizard,

    },

    {
        path: '/viewmothermedicalchart/:id',
        name: 'viewmothermedicalchart',
        component: viewMotherMedicalChartPage,

    },
    {
        path: '/viewfathermedicalchart/:id',
        name: 'viewfathermedicalchart',
        component: viewFatherMedicalChartPage,

    },
    {
        path: '/addimmunization/:id',
        name: 'immunization',
        component: createImmunizationPage,

    },
    {
        path: '/medication',
        name: 'medication',
        component: createMedicationPage,

    },
    {
        path: '/profilepregnancies',
        name: 'profilepregnancies',
        component: allProfilePregnanciesPage,

    },
    {
        path: '/viewChildrenClinicalVisits/:id',
        name: 'viewChildrenClinicalVisits',
        component: viewChildrenClinicalVisitsPage,

    },
    {
        path: '/viewMotherClinicalVisits/:id',
        name: 'viewMotherClinicalVisits',
        component: viewMotherClinicalVisitsPage,

    },
    {
        path: '/payments',
        name: 'payments',
        component: paymentsPage,

    },
    {
        path: '/adminpayments',
        name: 'adminpayments',
        component: adminPaymentsPage,

    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log()
        if (!store.state.auth.token) {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        next()
    }

})

export default router