<template lang="">
    <div>
      <appComp>
        <!-- Start Custom Preloader -->
        <div  v-if="isLoading" class="iq-card p-5 text-center">
            <div class="spinner-border text-primary" role="status">
            </div>
        </div>
        <!-- End Custom Preloader -->
        <div v-else class="row">
          <!-- start -->
          <div class="col-lg-4">
            <div class="iq-card">
              <div class="iq-card-body pl-0 pr-0 pt-0">
                <div class="doctor-details-block">
                  <div
                    class="doc-profile-bg bg-primary"
                    style="height: 150px"
                  ></div>
                  <div class="doctor-profile text-center">
                    <img
                      src="@/assets/images/user/11.png"
                      alt="profile-img"
                      class="avatar-130 img-fluid"
                    />
                  </div>
                  <div class="text-center mt-3 pl-3 pr-3">
                    <h4>
                      <!-- <b>{{ identity.displayName ?? 'N/A' }}</b> -->
                    </h4>
                  </div>
                  <hr />
                  <ul
                    class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                  >
                    <li class="text-center">
                      <a @click="navigateTO('/mothervisits/' + pregnancyId + '?motherId=' + (identity.mother.id || ''))" class="btn btn-outline-success float-center text-success">Clinic Visits</a>
                    </li>
                 
                    <li class="text-center">
                        <button v-show="hasRole('healthcare.facility.user') " type="submit"  class="btn btn-primary float-center">
                        Edit
                      </button>
                    </li>
                    <li class="text-center">
                      <a @click="navigateTO('/motherimmunizations/' + pregnancyId + '?motherId=' + (identity.mother.id || ''))" class="btn btn-outline-danger float-center text-danger">Immunizations</a>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- start -->
  
          <div class="col-lg-8">
            <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                   <div class="iq-header-title">
                      <h4 class="card-title">Mother Medical Chart Information</h4>
                   </div>
                </div>
                <div class="iq-card-body">
                   <div class="about-info m-0 p-0">
                      <div class="row">
                         <div class="col-4">Region:</div>
                         <div class="col-8">{{ region ?? 'N/A'}}</div>
                         <div class="col-4">Town:</div>
                         <div class="col-8">{{ town ?? 'N/A' }}</div>
                         <div class="col-4">Current Medical Trials:</div>
                         <div class="col-8">{{ currentMedicalTrials ?? 'N/A' }}</div>
                         <div class="col-4">Pregnancies Beyond 6 Months:</div>
                         <div class="col-8"><a href="#"> {{ pregnanciesBeyond6Months ?? 'N/A'}} </a></div>
                         <div class="col-4">Terminations Less Than 24 Weeks:</div>
                         <div class="col-8"><a href="#">{{ terminationsLessThan24Weeks ?? 'N/A'}}</a></div>
                         <div class="col-4">Status:</div>
                         <div class="col-8"><a href="#">{{ status ?? 'N/A'}}</a></div>
                                                                                                                                                              
                      </div>
                   </div>
                </div>
           </div>            
          </div>
        </div>
      
      </appComp>
    </div>
  </template>

<script>
import appComp from "@/components/appComp";
import axios from "axios";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
    //List Items
    pregnancyId:  this.$route.params.id,
    identity: [],
    roles: [],
    genders: null,
    isLoading: true, // Add loading state
    //Form Items
    form: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      displayName: '',
      genderId: '',
      },

    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      role: "auth/role",
      current_profile: "auth/profile",
    }),
  },
  //methods go here
  methods: {
    getData() {
      this.isLoading = true; // Set isLoading to true before making the API call
      const pregnancyId = this.$route.params.id;

      delete axios.defaults.headers.common["X-CG-Authorization"];
      // / get organizations
      axios
        .get("/pregnancies/" + pregnancyId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then((response) => {
            this.identity = response.data.data?.items[0];

            // Set isLoading to false once data is loaded
            this.isLoading = false;
            
            // console.log('Mother info', this.identity);
        });

        //Lists
          //   get data
          axios.get("/lists", {
            headers: {

               'X-CG-Authorization': `Bearer ${localStorage.getItem('token')}`,
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            }
         }, {
         }).then((response) => {
            this.genders = response.data.data.genders
         });

    },

    submit() {

        const pregnancyId = this.$route.query.pregnancyId;
        const childId = this.$route.params.id;

        // console.log('Birth Date', this.form.dateOfBirth)

        // Construct the data to be sent
        const dataToSend = {
          children: [
            {
              id: childId,
              birthDate: this.form.dateOfBirth,
              displayName: this.form.displayName,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              gender: {
                  id: this.form.genderId,
              },              

            }
          ]
        };

        axios
        .patch(`/pregnancies/${pregnancyId}`, dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then((response) => {
          // Handle the response here if needed
          console.log("API response:", response);
          // Load items on the main component page
          this.getData();
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Child info updated successfully.',
          });
        })
        .catch((error) => {
          // Handle errors here
          console.error("API error:", error);
          // Show error message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'An error occurred while updating child info.',
          });
        });
        },

    hasRole(roleName) {
      return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
    },
    getRoles() {
      // this.roles = this.user.profiles[this.current_profile].organization.roles;
      this.roles =
        this.user.profiles[this.current_profile]?.organization?.roles ?? [];
    },
    navigateTO(location) {
      this.$router.push(location);
    },
  },
  components: {
    appComp,
  },
  mounted() {
    this.getData();
    this.getRoles();
    delete axios.defaults.headers.common["X-CG-Authorization"];
    axios
      .get("/organizations", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
        },
      })
      .then((response) => {
        this.organizations = response.data.data.items;
        setTimeout(() => {
          $("#orgTable").DataTable();
        }, 10);
      });
    // console.log(this.organizations);
  },
};
</script>