<template lang="">
    <div class="row no-gutters">
        <div class="col-sm-12 text-center">
            <div class="iq-error error-500">
                <h1 class="text-primary">500</h1>
                <!-- <img src="images/error/03.png" class="img-fluid iq-error-img" alt=""> -->
                <h2 class="mb-0">Oops! Network Error.</h2>
                <p>There is no active internet connection.</p>
                <a class="btn btn-primary mt-3" href="#" @click="goToLogin"><i  class="ri-home-4-line"></i>Back </a>
                
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "loginPage",
    data() {
        return {
            form: {
                errors: {},
                id: '',
                email: 'root.mama@api.cugi.co.ke',
                password: 'PhhX9Qr9ST@G8Kd7',
            },
            message: '',
            successMessage: '',
            loading: false,
        }
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
            me: 'auth/me'
        }),

        logInAs(who) {
            switch (who) {
                case 'admin':
                    this.form.email = "root.mama@api.cugi.co.ke"

                    break;
                case 'user1':
                    this.form.email = "camillamakira@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"

                    break;
                case 'user2':
                    this.form.email = "bxy@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"

                    break;
                case 'user3':
                this.form.email = "bro@gmail.com"
                this.form.password = "@2H1bghbdbutz7#!!"

                break;
                case 'orguser':
                this.form.email = "jim.comey@api.cugi.co.ke"
                this.form.password = "@2H1bghbdbutz7#!!"

                break;                
                case 'orgadmin':
                    this.form.email = "jamal.abdu@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;
                case 'auditor':
                    this.form.email = "charlene.lagat@gmail.com"
                    break;
                case 'healthadmin':
                    this.form.email = "wayne@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;
                case 'healthuser':
                    this.form.email = "jeff.sessions@api.cugi.co.ke"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;   
                case 'helpdesk':
                    this.form.email = "johndoe@gmail.com"
                    this.form.password = "@2H1bghbdbutz7#!!"
                    break;                  

            }
            // this.submitHandler();
        },

        goToLogin() {
            this.$router.go(-1);
        },
        submitHandler() {
            this.loading = true;
            this.message = ''
            this.successMessage = ''

            this.signIn({
                username: this.form.email,
                password: this.form.password
            }).then((result) => {
                console.log(result)
                if (result.authenticated) {
                    this.successMessage = 'Successfully signed in';
                    setTimeout(() => {
                        localStorage.setItem("role", "User")
                        this.$router.push({ name: 'dashboard' })
                    }, 700);
                } else {
                    this.message = 'Credentials did not match our records';
                }
            }).catch((error) => {
                this.message = error.response.data.message || 'An error occurred';
            }).finally(() => {
                this.loading = false;

            });
        }


    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user'
        }),
    },
    mounted() {
        this.me()
    }

}
</script>
<style lang="">
    
</style>