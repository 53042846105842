<template lang="">
    <div>
        <appComp>
           <div class="row">
                  <div class="col-lg-3">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Add New User</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <form>
                              <!-- <div class="form-group">
                                 <div class="add-img-user profile-img-edit">
                                    <img class="profile-pic img-fluid" src="@/assets/images/user/11.png" alt="profile-pic">
                                    <div class="p-image">
                                       <a href="javascript:void();" class="upload-button btn iq-bg-primary">File Upload</a>
                                       <input class="file-upload" type="file" accept="image/*">
                                    </div>
                                 </div>
                                 <div class="img-extension mt-3">
                                    <div class="d-inline-block align-items-center">
                                       <span>Only</span>
                                       <a href="javascript:void();">.jpg</a>
                                       <a href="javascript:void();">.png</a>
                                       <a href="javascript:void();">.jpeg</a>
                                       <span>allowed</span>
                                    </div>
                                 </div>
                              </div> -->
                              <div class="form-group">
                                 <label>Status:</label>
                                 <select class="form-control" v-model="form.statusId" name="statusId" id="statusId">
                                    <option unselected>Select</option>
                                  
                                 </select>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-9">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">New User Information</h4>
                           </div>
                        </div>
                        <div v-if="message" class="alert alert-danger">{{ message }}</div>
                        <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                        <div class="iq-card-body">
                           <div class="new-user-info">
                              <form @submit.prevent="submitHandler">
                                 <div class="row">
                                    <div class="form-group col-md-6">
                                       <label for="username">First Name:</label>
                                       <input type="text" class="form-control" v-model="form.firstName" name="username" id="username" placeholder="Enter Username">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="displayName">Last Name:</label>
                                       <input type="text" class="form-control" v-model="form.lastName" name="displayName" id="displayName" placeholder="Enter Display Name">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="email">Email Address:</label>
                                       <input type="email" class="form-control" v-model="form.email" name="email" id="email" placeholder="Enter Email Address">
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="phone_no">Phone Number:</label>
                                       <input type="text" class="form-control" v-model="form.msisdn" name="phone_no" id="phone_no" placeholder="Enter Phone Number">
                                    </div>
                                    <div class="form-group col-md-12">
                                       <label for="password">Password:</label>
                                       <input type="password" class="form-control" v-model="form.password" name="password" id="password" placeholder="Enter Password">
                                    </div>
                                    <div class="form-group col-md-12">
                                       <label for="expiryDate">Confirm Password:</label>
                                       <input type="password" class="form-control" v-model="form.confirmPassword" name="expiryDate" id="expiryDate" placeholder="Enter Expiry Date">
                                    </div>
                                 </div>
                                 <hr>
                                 <!-- <h5 class="mb-3">ReadOnly</h5> -->
                                 <!-- <div class="row">
                                    <div class="form-group col-md-6">
                                       <label for="date">Date:</label>
                                       <input type="text" class="form-control" id="date" placeholder="Date" readonly>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <label for="password_date">Password Date:</label>
                                       <input type="text" class="form-control" id="password_date" placeholder="Password Date" readonly>
                                    </div>
                                 </div> -->
                                 <button type="submit" class="btn btn-primary">Add New User</button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
           </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from "axios";

export default {
    data() {
      return{
         form: {
               firstName: "",
               lastName: "",
               email: "",
               phone: "",
               password: "",
               confirmPassword: "",
               organizationId: 1

         },
         message: "",
         successMessage: "",
      }
    },
    components: {
        appComp
    },
    methods: {
      submitHandler() {
            this.successMessage = null;
            this.message = null;

            // Validate form data
            if (
                !this.form.firstName ||
                !this.form.lastName ||
                !this.form.email ||
                !this.form.msisdn ||
                !this.form.password ||
                !this.form.confirmPassword
            ) {
                this.message = "Please fill in all fields.";
                return;
            }

            if (this.form.password !== this.form.confirmPassword) {
                this.message = "Passwords do not match.";
                return;
            }

            // Make AJAX request
            axios
                .post("/profiles", this.form, {
                    headers: {
                        'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                    }
                })
                .then((response) => {
                    // console.log(response.data)
                    if (response.data && response.data.status == 'success' && response.data.code == 200) {
                        this.successMessage = "Your account has been successfully created";

                    }

                })
                .catch((error) => {
                    let errors = error.response.data.data;
                    if (errors.length > 1) {
                        errors.forEach(err => {
                            this.message += ',' + err.message
                        });
                    } else {
                        errors.forEach(err => {
                            this.message = err.message
                        });
                    }
                    // if (Array.isArray(errors)) {
                    //     this.message = errors.join(', ');
                    // } else {
                    //     this.message = errors;
                    // }
                    // console.error(error.response.data.data);
                    // this.message = "An error occurred. Please try again later.";
                }).finally(() => {
                  //   this.loading = false
                });
        },      
    },
    mounted(){
      this.form.displayName = this.form.lastName + " " + this.form.lastName;
    }

}
</script>
<style lang="">
    
</style>