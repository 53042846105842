<template lang="">
    <div>
        <appComp>
            <div class="container-fluid">
               <div class="row">
               
                  <div class="col-lg-12">
                  <fieldset>
                  <div class="iq-card">
                  <div class="iq-card-header d-flex justify-content-between">
                     <div class="iq-header-title">
                        <h4 class="card-title">Clinical Visit Information</h4>
                     </div>
                  </div>
                  <div class="m-4">
                        <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>
                        <!-- <div v-if="message" class="alert alert-danger">{{ message }}</div> -->
                        <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                       </div>
                  <div class="iq-card-body">
                     <form>
                        <div class=" row align-items-center">
                           <div class="form-group col-sm-6">
                              <label for="fname">Organization:</label>
                              <select class="form-control" v-model="form.organizationId" name="organizationId" id="organizationId">
                                 <option disabled>Select</option>
                                 <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{ organization.name}}</option>
                                 
                              </select>
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="uname">Status:</label>
                              <select class="form-control" v-model="form.statusId" name="allergies" id="allergies">
                                 <option disabled value="">Select</option>
                                 <option v-for="status in statuses" :value="status.id" :key="status.id">{{ status.name}}</option>
                              
                              </select>
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="uname">Scheduled Date:</label>
                              <input type="date" class="form-control" id="uname" v-model="form.scheduledDate">
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="cname">Visit Date:</label>
                              <input type="date" class="form-control" id="cname" v-model="form.visitDate">
                           </div>

                        </div>
                        <p>Comments</p>
                        <div class="row align-items-center">
                              <div class="form-group col-sm-6">
                                 <label for="fname">Comment Type:</label>
                                 <select class="form-control" v-model="form.commentTypeId" name="payments" id="payments">
                                    <option disabled value="">Select</option>
                                    <option v-for="comment in commenttype" :value="comment.id" :key="comment.id">{{ comment.name}}</option>
                                 
                                 </select>
                              </div>
                              <div class="form-group col-sm-6">
                                 <label for="uname">Visibility Type:</label>
                                 <select class="form-control" v-model="form.visibilityTypeId" name="payments" id="payments">
                                    <option disabled value="">Select</option>
                                    <option v-for="comment in commentvisibilitytype" :value="comment.id" :key="comment.id">{{ comment.name}}</option>
                                 
                                 </select>
                              </div>
                              <div class="form-group col-sm-6">
                                 <label for="uname">Status:</label>
                                 <select class="form-control" v-model="form.statusId" name="allergies" id="allergies">
                                    <option disabled value="">Select</option>
                                    <option v-for="status in statuses" :value="status.id" :key="status.id">{{ status.name}}</option>
                                 
                                 </select>
                              </div>
                              <div class="form-group col-sm-6">
                                 <label for="lname">richText:</label>
                                 <input type="text" class="form-control" id="lname" />
                              </div>
                              <div class="form-group col-sm-12">
                                    <label for="memo">Comment</label>
                                    <textarea class="form-control" v-model="form.comment" id="exampleFormControlTextarea1" rows="2"></textarea>
                              </div>
                           </div>
                        <!-- <button @click="prev()" type="reset" class="btn btn-dark mr-2">Previous</button> -->
                        <button type="submit" class="btn btn-primary mr-2">Submit</button>

                     </form>
                  </div>
                  </div>
                  </fieldset>

                  </div>
               </div>
            </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import Swal from 'sweetalert2';

export default{
    data(){
      return {
         message: [],
        organizations: [],
        statuses: [],
        commenttype: [],
        commentvisibilitytype: [],
        form: {
         organizationId: '',
         statusId: '',
         scheduledDate: '',
         visitDate: '',
         comments: {

         }
        }
      }
    },
    methods: {
       prev() {
        this.$router.push('/maternal');
       },
       submit(){
         delete axios.defaults.headers.common['X-CG-Authorization'];
         const payload = {
            mother:{
               medical:{
                  comments: this.form.comments,
                  organizationId: this.form.organizationId,
                  statusId: this.form.statusId,
                  scheduledDate: this.form.scheduledDate,
                  visitDate: this.form.visitDate
               }
            },
            children:{
               childId: {
                  medical: {
                     clinicalVisits: {
                        comments: this.form.comments,
                        organizationId: this.form.organizationId,
                        statusId: this.form.statusId,
                        scheduledDate: this.form.scheduledDate,
                        visitDate: this.form.visitDate
                     }
                  }
               }
            }
         };
         axios.patch("/pregnancies/"+this.$route.params.id, payload,{
            headers : {
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
            
         }).then((response) => {
            if(response.data.status == 'success')
               {
                  Swal.fire({
                  title: 'Clinical Visit Added',
                  text: "visit added ",
                  icon: 'success',
                  })
               }
         }).catch((e) => 
         {
            console.log(e.response.data)
            let errors = e.response.data.data;
                    if (errors.length > 1) {
                        errors.forEach(err => {
                           this.message.push(err.message)
                        });
                    } else {
                        errors.forEach(err => {
                           this.message.push(err.message)
                    
                        });
                    }
         })
       },
       async loadLists(){
         delete axios.defaults.headers.common['Authorization'];
         axios.get("/lists", { headers: {
            
                  'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
         }},{
            }).then((response) => {
               this.statuses = response.data.data.status
               this.commenttype = response.data.data.commenttype
               this.commentvisibilitytype = response.data.data.commentvisibilitytype
               console.log( response)

         });
      },
      getOrg() {
         delete axios.defaults.headers.common['X-CG-Authorization'];
         // / get organizations
         axios.get('/organizations', {
            headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token'),
               'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
         }).then((response) => {
            console.log("org", response)
            this.organizations = response.data.data.items
         });
      },
    },
    components: {
        appComp
    },
    mounted(){
      this.loadLists();
      this.getOrg();
      //child id
      const childId = this.$route.params.id;
      //journey id
      const journeyId = this.$route.params.journ;
      console.log("id", childId)
      console.log("journey", journeyId)
    }
    
}
</script>