<template lang="">
    <div>
        <appComp>
           <div class="row">
                  <div class="col-lg-12">
                   <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>

                    <div class="iq-card-body">
                           <p>Users</p>
                           <!-- Start Custom Preloader -->
                           <div v-if="isLoading" class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                            </div>
                            <!-- End Custom Preloader -->                            
                           <table v-else id="identityTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <!-- <th scope="col">#</th> -->
                                    <th scope="col">Display Name</th>
                                    <th scope="col">username</th>
                                    <th scope="col">msisdn</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Status</th>
                                    <!-- <th scope="col">Registration Date</th> -->
                                    <!-- <th scope="col">Gender</th>
                                    <th scope="col">Status</th> -->
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr v-for="identity in identities" :key="identity.id">
                                    <!-- <th scope="row">{{ identity.id }}</th> -->
                                    <td>{{identity.displayName}}</td>
                                    <td>{{identity.username}}</td>
                                    <td>{{identity.msisdn ? identity.msisdn: "Not Assigned" }}</td>
                                    <td>{{identity.email ? identity.email: "Not Assigned" }}</td>
                                    <td>{{identity.type.name}}</td>
                                    <td>{{identity.status.name}}</td>
                                    <!-- <td>{{identity.registrationDate}}</td> -->
                                    <td>
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                                <a @click="navigateTo('/viewuser/'+this.$route.params.id+'/'+identity.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View</a>                                            
                                                <a v-if=" (hasRole('global.admin') ||  hasRole('organization.admin') || hasRole('healthcare.facility.admin'))"  @click="navigateTo('/edituser/'+this.$route.params.id+'/'+identity.id)" class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>                                                                  
                                                <a v-if="identity.status.name == 'Active' && hasRole('global.admin') ||  hasRole('organization.admin') || hasRole('healthcare.facility.admin')"  @click="deleteUser(identity.id)" class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Deactivate</a>
                                                <a v-if="identity.status.name == 'Active' && hasRole('global.admin') ||  hasRole('organization.admin') || hasRole('healthcare.facility.admin')"  @click="resetPassword(identity.email)" class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Reset password</a>                                                                  
                                            </div>
                                        </div>
                                    </td>
                                    
                                    
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                  </div>
           </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from "vuex"
import Swal from 'sweetalert2';

const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;

export default {
    data() {
        return {
            identities: [],
            roles: [],
            isLoading: true, // Add loading state
           // messages 
             message : [],
             successMessage : "",
        }
    },
    computed: {
        ...mapGetters({
            lists: "auth/lists",
            user: "auth/user",
            role: "auth/role",
            current_profile: "auth/profile",
        }),
    },
    methods: {
        hasRole(roleName) {
            return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        navigateTo(location) {
            this.$router.push(location)
        },
      passwordChange(id){
         let data;
         data = {
            "passwordChangeRequired": true
         },
         axios.patch("/identities/"+id, data,{
            headers : {
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
            
         }).then((response) => {
            if(response.data.status == 'success')
               {
                  toast.fire({
                  title: 'User Updated',
                  text: "user updated ",
                  icon: 'success',
                  })
               }
         }).catch((e) => 
         {
            // console.log(e.response.data)
            let errors = e.response.data.data;
                    if (errors.length > 1) {
                        errors.forEach(err => {
                           this.message.push(err.message)
                        });
                    } else {
                        errors.forEach(err => {
                           this.message.push(err.message)
                    
                        });
                    }
         })
      },
        deleteUser(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    //send request to the server
                    delete axios.defaults.headers.common['X-CG-Authorization'];

                    axios.delete('/organizations/' + this.$route.params.id + '/identities/' + id, {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                        },
                    }).then(() => {
                        toast.fire(
                            'Deleted!',
                            'User has been deactivated.',
                            'success'
                        )
                        this.getData();
                    }).catch(() => {
                        Swal.fire(
                            'Failed!',
                            'There was something wrong.',
                            'warning'
                        )
                    });
                } else if (result.isDenied) {
                    // console.log('cancelled')
                }

            })
        }, 
        resetPassword(email){
            this.successMessage = null;
            this.message = null;
            //loading 
            this.loading = true


            // delete axios.defaults.headers.common['X-CG-Authorization'];
            axios.post('/otp',{
                username : email,
                channelTypeId : 8
            }, {
                headers : {
                    'Authorization' : "Bearer "+localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

                }
            }).then((response) => {
                console.log(response.data)
                    if (response.data && response.data.status == 'success' && response.data.code == 200) {
                        // this.successMessage = "OTP has been sent succesfully";
                        toast.fire(
                           'Success!',
                           'OTP sent!',
                           'success'
                        )
                 
                    }
            })  .catch((error) => {
                let errors = error.response.data.data;
                console.log(error.response.data.data)
                if (errors.length > 2) {
                    errors.forEach(err => {
                        if(err.message != "null")
                        {
                            this.message += err.message + ", "
                        }
                    });
                } else {
                    errors.forEach(err => {
                        this.message = err.message
                    });
                }
                            
            }).finally(() => {
                this.loading = false
            });
  

            // axios.post('/otp',
            // {
            //     username : this.username,
            //     channelTypeId : this.channelType
            // }).then((response) => {
            //         console.log(response.data)
            //         if (response.data && response.data.status == 'success' && response.data.code == 200) {
            //             this.successMessage = "Your OTP has been sent succesfully to "+ this.username;
            //             // setTimeout(() => {
            //             //     this.$router.push({ name: 'login' })
            //             // }, 1200);
            //         }

            // })
            // .catch((error) => {
            //     let errors = error.response.data.data;
            //     console.log(error.response.data.data)
            //     if (errors.length > 2) {
            //         errors.forEach(err => {
            //             if(err.message != "null")
            //             {
            //                 this.message += err.message + ", "
            //             }
            //         });
            //     } else {
            //         errors.forEach(err => {
            //             this.message = err.message
            //         });
            //     }
            
            // }).finally(() => {
            //     this.loading = false
            // });
        }, 
        getData() {
            // console.log(axios);
            this.isLoading = true; // Set isLoading to true before making the API call
            delete axios.defaults.headers.common['X-CG-Authorization'];
            try {                  
            axios.get('/organizations/' + this.$route.params.id + '/identities', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
            }).then((response) => {
                this.identities = response.data.data.items
                setTimeout(() => {
                    $("#identityTable").DataTable();
                }, 10);
                // Set isLoading to false once data is loaded
                this.isLoading = false;
                // console.log("users data", response);
            });
            } catch (error) {
                // console.error("Error fetching data:", error);
                this.isLoading = false; // Make sure isLoading is set to false in case of an error
            }            
        }
    },
    //methods go here
    components: {
        appComp
    },
    mounted() {
        this.getData();
        this.getRoles();
    }

}
</script>
<style lang="">
    
</style>