<template>
    <div class="modal fade" id="mpesaTopupModal" tabindex="-1" role="dialog" aria-labelledby="newPaymentModalLabel" style="display: none;" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="newPaymentModalLabel">New M-Pesa Topup</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">

                        <div class="row align-items-center">
                          <div v-if="form.paymentTypeId !== 1" class="form-group col-sm-12">
                                    <label for="memo">Recipient Wallet ID<span class="text-danger"> (Be sure before submitting)</span></label>
                                    <input type="number" @change="checkWalletId" v-model="form.creditWalletId" class="form-control" placeholder="Recipient Wallet ID Eg. 3255" id="uname">
                              </div>
                              <div class="form-group col-sm-12">
                              <!-- Display user details or error message -->
                            <div v-if="userDetails" class="alert alert-success" role="alert">
                              {{ userDetails }}
                            </div>
                            <div v-if="errorMessage" class="alert alert-danger" role="alert">
                              {{ errorMessage }}
                            </div>
                          </div>
                              <div class="form-group col-sm-6">
                                 <label for="fname">Payment Type:</label>
                                 <select class="form-control" v-model="form.paymentTypeId" name="payments" id="payments">
                                    <option disabled value="">Select</option>
                                    <option v-for="payment in paymenttype" :value="payment.id" :key="payment.id">{{ payment.name}}</option>
                                 
                                 </select>
                              </div>
                              <div class="form-group col-sm-6">
                                 <label for="uname">Transaction Type:</label>
                                 <select class="form-control" v-model="form.transactionTypeId" name="payments" id="payments">
                                    <option disabled value="">Select</option>
                                    <option v-for="transaction in transactiontype" :value="transaction.id" :key="transaction.id">{{ transaction.name}}</option>
                                 
                                 </select>
                              </div>
                              <div class="form-group col-sm-6">
                                    <label for="memo">Amount(KES)</label>
                                    <input type="number" v-model="form.amount" class="form-control" id="uname">
                              </div>
                              <div class="form-group col-sm-6">
                                    <label for="memo">Memo</label>
                                    <input type="text" v-model="form.memo" class="form-control" id="uname">
                              </div>

                           </div>
                           
                          
                     
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button @click="submit()" type="button" class="btn btn-primary">Submit</button>
                </div>
            </form>
                </div>
            </div>
        </div>
  </template>
  
  <script>
  import axios from "axios";
  import "jquery/dist/jquery.min.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import $ from "jquery";
  import { mapGetters } from "vuex";
  import Swal from 'sweetalert2';

  export default {
    data() {
      return {
      paymenttype: [],
      transactiontype: [],
      organizations: [],
      profiles: [],
      // Existing data properties...
      userDetails: null,
      errorMessage: '',
      //Form Items
      form: {
          amount: '',
          paymentTypeId: '',
          transactionTypeId: '',
          memo: '',
          creditWalletId: '',
      },
      isProfilesLoaded: false,
      };
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
        role: "auth/role",
        current_profile: "auth/profile",
      }),
    },
    //methods go here
    methods: {
      checkWalletId() {
        const allProfiles = JSON.parse(localStorage.getItem('allProfiles'));
        const foundProfile = allProfiles.find(profile => profile.wallet.id === this.form.creditWalletId);

        if (foundProfile) {
          // Set user details for display
          this.userDetails = `Display Name: ${foundProfile.displayName}, Locator ID: ${foundProfile.locatorId}, Phone: ${foundProfile.msisdn}`;
          this.errorMessage = ''; // Clear any previous error message
        } else {
          // Clear previous user details and set error message
          this.userDetails = null;
          this.errorMessage = 'Sorry! User with that wallet ID does not exist! Try Again.';
        }
      },
      toggleDropdown(event) {
      event.preventDefault(); // Prevent the default behavior
      this.isDropdownOpen = !this.isDropdownOpen;
    },
      getData() {

        const pregnancyId = this.$route.query.pregnancyId;
        const childId = this.$route.params.id;
  
        // console.log("Monyancha pregnancy id", pregnancyId, childId);
  
        delete axios.defaults.headers.common["X-CG-Authorization"];
        // / get organizations
        axios
          .get("/pregnancies/" + pregnancyId, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
            },
          })
          .then((response) => {
              const childrenArray = response.data.data?.items[0].children;
              // console.log("Children Array", childrenArray);
  
              const childIdToFind = childId;
              const foundChild = childrenArray.find(child => child.id == childIdToFind);
  
              if (foundChild) {
                  this.clinicalVisits = foundChild.medical.clinicalVisits;
              } else {
                  // console.error("Child not found with the provided childId.", childIdToFind);
              }
  
              // console.log("Monyancha Clinical Visits", this.clinicalVisits);
          });
      },

      submit() {

        // Format scheduledDate to "YYYY-MM-DD HH:mm:ss"
        const date = new Date(Date.now() - 50000).toISOString().slice(0, 19).replace('T', ' ');


        // console.log('Monyancha date', formatconstedScheduledDate);

        //Get Wallet Id from local storage
        const walletId = localStorage.getItem('walletId');

        console.log("Monyancha wallet id", walletId)

        // Construct the data to be sent

        // Construct the data to be sent
        let dataToSend = {
          creditWalletId: this.form.creditWalletId || walletId,
          gateway: "wallet",
          transaction: {
            amount: this.form.amount,
            date: date,
            id: Date.now(), // Replace with your transaction ID generation logic
            memo: this.form.memo,
            typeId: this.form.transactionTypeId,
          },
          typeId: this.form.paymentTypeId
        };

        // If creditWalletId is not provided in the form, add debitWalletId
        if (this.form.creditWalletId) {
          dataToSend.debitWalletId = walletId;
        }

        axios
        .post(`/payments`, dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then(() => {
          // Handle the response here if needed
          // console.log("API response:", response);
          // Load items on the main component page
          this.getData();
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Payment Successful.',
          });
        //   this.$router.replace(`/childvisits/${childId}?pregnancyId=${pregnancyId}`); // Refresh the route
        })
        .catch((error) => {
          // Handle errors here
          console.error("API error:", error);
          // Show error message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
        });
    },

        async loadLists(){
          delete axios.defaults.headers.common['Authorization'];
          axios.get("/lists", { headers: {
                  'X-CG-Authorization' : `Bearer ${localStorage.getItem('token')}`,
                  'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            
          }},{
            }).then((response) => {
                this.paymenttype = response.data.data.paymenttypes
                this.transactiontype = response.data.data.transactiontypes
                // console.log( response)

          });
      },
      getOrg() {
          delete axios.defaults.headers.common['X-CG-Authorization'];
          // / get organizations
          axios.get('/organizations', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
          }).then((response) => {
            console.log("organization monyancha", response)
            this.organizations = response.data.data.items
          });
      },
      hasRole(roleName) {
        return this.roles.some((role) => role.id.split(".role.")[1] === roleName);
      },
      getRoles() {
        // this.roles = this.user.profiles[this.current_profile].organization.roles;
        this.roles =
          this.user.profiles[this.current_profile]?.organization?.roles ?? [];
      },
      navigateTO(location) {
        this.$router.push(location);
      },
    },
    mounted() {
      this.getRoles();
      this.loadLists();
      this.getOrg();
      this.getData();
      delete axios.defaults.headers.common["X-CG-Authorization"];
      axios
        .get("/organizations", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
          },
        })
        .then((response) => {
          this.organizations = response.data.data.items;
          setTimeout(() => {
            $("#orgTable").DataTable();
          }, 10);
        });
      // console.log(this.organizations);
    },
  };
  </script>
  <style scoped>
  /* Your custom styles here */
  .searchable-select {
    position: relative;
    display: inline-block;
    background-color: white;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid white;
  }
  
  .search-input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid white;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 5px;
    cursor: pointer;
  }
  
  </style>
  