import axios from "axios";
import CryptoJS from 'crypto-js';

axios.defaults.baseURL = "https://api.sandbox.cugi.co.ke";
axios.defaults.headers.common = {
    // 'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
    'X-CG-Authorization': 'Basic YW5vbnltb3VzLm1hbWFAYXBpLmN1Z2kuY28ua2U6QmFNKjkwQE5pVjFFVEhVMSpuem5sMDlvckFBZ0B2TyFOZDYk',
    'X-CG-Version': 'CugiMama-20230318',
};


export default ({
    namespaced: true,
    state: {
        token: null,
        user: null,
        lists: null,
        profile : 0,
        role : 'user',
    },
    getters: {
        authenticated(state) {
            return state.token && state.user;
        },
        user(state) {
            return state.user;
        },
        profile(state){
            return state.profile;
        },
        role(state){
            return state.role;
        },
        lists(state) {
            return state.lists;
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, data) {
            state.user = data;
            localStorage.setItem('user', JSON.stringify(data));
        },
        SET_PROFILE(state,profile){
            state.profile = profile
        },
        SET_ROLE(state,role){
            state.role = role
        },
        SET_LISTS(state, data) {
            state.lists = data;
        }
    },
    actions: {
        loadData({ commit }) {
            const savedData = JSON.parse(localStorage.getItem('user'));
            if (savedData) {
                commit('SET_USER', savedData);
            }
        },
        async signIn({ commit }, credentials) {
            try {
                const response = await axios.post("/authenticate", credentials, {
                    headers : {
                        'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                    }
                    // headers: {
                    //     // 'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                    //     // 'X-CG-Authorization': 'Basic YW5vbnltb3VzLm1hbWFAYXBpLmN1Z2kuY28ua2U6QmFNKjkwQE5pVjFFVEhVMSpuem5sMDlvckFBZ0B2TyFOZDYk',
                    //     // 'X-CG-Version': 'CugiMama-20230318',
                    //     // 'Content-Type': 'application/json',
                    // },
                });
                if (response.data && response.data.status === 'success') {
                    commit('SET_TOKEN', response.data.data.session.token)
                    commit('SET_USER', response.data.data);
                    
                    localStorage.setItem("vite_vbfx", CryptoJS.AES.encrypt(JSON.stringify(response.data.data), "cugimama").toString())
                    
                    localStorage.setItem("user", JSON.stringify(response.data.data));
                    localStorage.setItem("profileId", response.data.data?.profiles[0]?.id);
                    return { authenticated: true, user : response.data };
                } else {
                    commit('SET_TOKEN', null)
                    commit('SET_USER', null);
                    return { authenticated: false };
                }
            } catch (error) {
                console.error(error);
                return { authenticated: false };
            }
        },
        async me() {
            // console.log('hello')

        },
        async getLists({ commit }) {
            // console.log('hi')
            axios.get("/lists", {
                headers : {
                        'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                    }
            }).then((response) => {
                commit('SET_LISTS', response.data.data)
            });
            // console.log(response)

        },
        updateProfile({ commit }, profile) {
        commit('SET_PROFILE', profile);
        commit('SET_ROLE', "user");
        },
        updateRole({ commit }, role) {
        commit('SET_ROLE', role);
        
        },
        saveUser({ commit }) {
            const encryptedData = localStorage.getItem('vite_vbfx');
          
            if (encryptedData) {
              try {
                const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, 'cugimama');
                const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
          
                if (decryptedData) {
                  commit('SET_USER', decryptedData);
                }
              } catch (error) {
                console.error('Error parsing decrypted data:', error);
              }
            }
          }
    },
})

