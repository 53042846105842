<template lang="">
    <div>
      <appComp>
           <div class="row">
                  <div class="col-lg-9">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Reset Password</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="new-user-info">
                              <div class="m-4">
                                 <div v-for="mes in message" :key="mes" class="alert alert-danger">{{ mes }}</div>
                                 <!-- <div v-if="message" class="alert alert-danger">{{ message }}</div> -->
                                 <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                              </div>
                            <form @submit.prevent="handleSubmit()" class="mt-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Username</label>
                                    <input 
                                    v-model="username" type="text" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter account username/email/phone">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">OTP</label>
                                    <input 
                                    v-model="otp" type="text" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter OTP">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">New Password</label>
                                    <input 
                                    v-model="password" @input="validatePassword" type="password" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter new password">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Confirm Password</label>
                                    <input 
                                    v-model="confirm_password" @input="validatePassword" type="password" class="form-control mb-0" id="exampleInputEmail1" placeholder="Confirm password">
                                </div>

                                <div class="d-inline-block w-100">
                                      <button
                                        type="submit"
                                        class="btn btn-primary float-right"
                                        :disabled="loading || passwordsDoNotMatch"
                                        >
                                        <span v-if="loading" class="spinner-border spinner-border-sm mr-2"></span>
                                        Submit
                                        </button>
                                </div>

                            </form>
                           </div>
                        </div>
                     </div>
                  </div>
           </div>          
      </appComp>  
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';

const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

export default {

    components: {
        appComp
    },
    data(){
        return {
         form: {
            displayName: '',
            email: '',
            password: '',
            // passwordChangeRequired: true
            // expiryDate: '',
         },
         username: '',
         otp: '',
         password: '',
         confirm_password: '',
         email: '',
         // messages 
         message : [],
         successMessage : "",
        }
    },
   computed: {
      ...mapGetters({
         lists: 'auth/lists',
         user: 'auth/user',
         role: 'auth/role',
         current_profile: 'auth/profile'
      })
   },
   methods: {
        // validatePassword() {
        // if (this.password !== this.confirm_password) {
        //     this.message = "Passwords do not match";
        // } else {
        //     this.message = null;
        // }
        // },    
      handleSubmit()
        {
            this.successMessage = null;
            this.message = null;
            //loading 
            this.loading = true

            // Validate form data
            if (
                !this.username || !this.otp || !this.password
            ) {
                this.message = "Please fill in all fields.";
                this.loading = false
                return;
            }

            // delete axios.defaults.headers.common['X-CG-Authorization'];
            axios.patch('/identities',{
                // username : this.username,
                otp : this.otp,
                password : this.password
            }, {
                headers : {
                    // 'Authorization' : "Bearer "+localStorage.getItem('token'),
                    'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),

                }
            }).then((response) => {
                console.log(response.data)
                    if (response.data && response.data.status == 'success' && response.data.code == 200) {
                        // this.successMessage = "Your password has been reset succesfully";
                        toast.fire(
                       'Success!',
                       'Password updated!',
                       'success'
                       )
                    }
            })  .catch((error) => {
                let errors = error.response.data.data;
                console.log(error.response.data.data)
                if (errors.length > 2) {
                    errors.forEach(err => {
                        if(err.message != "null")
                        {
                            this.message += err.message + ", "
                        }
                    });
                } else {
                    errors.forEach(err => {
                        this.message = err.message
                    });
                }
                            
            }).finally(() => {
                this.loading = false
            });
  

            // axios.post('/otp',
            // {
            //     username : this.username,
            //     channelTypeId : this.channelType
            // }).then((response) => {
            //         console.log(response.data)
            //         if (response.data && response.data.status == 'success' && response.data.code == 200) {
            //             this.successMessage = "Your OTP has been sent succesfully to "+ this.username;
            //             // setTimeout(() => {
            //             //     this.$router.push({ name: 'login' })
            //             // }, 1200);
            //         }

            // })
            // .catch((error) => {
            //     let errors = error.response.data.data;
            //     console.log(error.response.data.data)
            //     if (errors.length > 2) {
            //         errors.forEach(err => {
            //             if(err.message != "null")
            //             {
            //                 this.message += err.message + ", "
            //             }
            //         });
            //     } else {
            //         errors.forEach(err => {
            //             this.message = err.message
            //         });
            //     }
            
            // }).finally(() => {
            //     this.loading = false
            // });
    




        }
   },    
   mounted(){
    this.username = this.user.identity.username;
    // this.email = this.user.identity.email;
    console.log(this.user)

   }
}
</script>
<style lang="">
    
</style>