<template>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'App',

  methods: {
    ...mapActions({
      getLists: 'auth/getLists'
    })
  },
  computed: {
    ...mapGetters({
      lists: 'auth/lists',
    }),
  },
  mounted() {
    this.getLists()
  }



}
</script>


