<template lang="">
    <div>
        <appComp>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-body p-0">
                           <div class="iq-edit-list">
                              <ul class="iq-edit-profile d-flex nav nav-pills">
                                 <li class="col-md-3 p-0">
                                    <a class="nav-link active" data-toggle="pill" href="#personal-information">
                                    Child Information
                                    </a>
                                 </li>
                                 
                               
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                  <fieldset v-if="step == 1">
                  <div class="iq-card">
                  <div class="iq-card-header d-flex justify-content-between">
                     <div class="iq-header-title">
                        <h4 class="card-title">Personal Information</h4>
                     </div>
                  </div>
                  <div class="iq-card-body">
                     <form>
                        <div class="form-group row align-items-center">
                           <div class="col-md-12">
                              <div class="profile-img-edit">
                                 <img class="profile-pic" src="@/assets/images/user/11.png" alt="profile-pic">
                                 <!-- <div class="p-image">
                                    <i class="ri-pencil-line upload-button"></i>
                                    <input class="file-upload" type="file" accept="image/*">
                                 </div> -->
                              </div>
                           </div>
                        </div>
                        <div class=" row align-items-center">
                           <div class="form-group col-sm-6">
                              <label for="lname">Name:</label>
                              <input type="text" class="form-control" id="lname" value="Jets">
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="lname">Gender:</label>
                              <input type="text" class="form-control" id="lname" value="Jets">
                           </div>
                           <div class="form-group col-sm-6">
                              <label for="dob">Comments:</label>
                              <input type="text" class="form-control" id="uname" value="">
                           </div>


                        </div>
                        <button @click="next()" type="submit" class="btn btn-primary mr-2">Next</button>
                        <button v-show="form.status == 1" @click="submit()" type="submit" class="btn btn-primary mr-2">Save & Proceed</button>

                     </form>
                  </div>
                  </div>
                  </fieldset>

                  <fieldset v-if="step == 2">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Medical Chart</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <form>
                              <div class="form-group">
                                 <label for="cpass">Allergies:</label>
                                 <input type="text" class="form-control" id="cpass" value="">
                              </div>
                              <div class="form-group">
                                 <label for="npass">History:</label>
                                 <input type="text" class="form-control" id="npass" value="">
                              </div>
                              <div class="form-group">
                                 <label for="vpass">Immunizations:</label>
                                 <input type="text" class="form-control" id="vpass" value="">
                              </div>
                              <div class="form-group">
                                 <label for="vpass">Medications:</label>
                                 <input type="text" class="form-control" id="vpass" value="">
                              </div>
                              <button @click="prev()" type="reset" class="btn btn-dark mr-2">Previous</button>
                              <button @click="submit()" type="submit" class="btn btn-primary mr-2">Save & Proceed</button>
                           </form>
                        </div>
                     </div>
                  </fieldset>   

                  </div>
               </div>
            </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
export default{
    data(){
      return {
        step: 1, 
        form: {
         status: ''
        }
      }
    },
    methods: {
       prev() {
          this.step--;
       },
       next() {
          this.step++;
       },
       submit(){
         this.$router.push('/clinicalvisits');
       }
    },
    components: {
        appComp
    },
}
</script>