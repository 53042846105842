<template lang="">
    <div>
        <appComp>
           <div class="row">
                  <div class="col-lg-12">
                    <div class="iq-card-body">
                           <p>Organizations</p>
                           <!-- Start Custom Preloader -->
                           <div v-if="isLoading" class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                </div>
                            </div>
                            <!-- End Custom Preloader -->                           
                           <table v-else id="orgTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Health Facility Type</th>
                                    <th scope="col">Contact Person</th>
                                    <th scope="col">Email Person</th>
                                    <!-- <th scope="col">Type</th> -->
                                    <th scope="col">Address</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                <tr  v-for="organization in organizations" :key="organization.id">
                                    <th scope="row">{{organization.id}}</th>
                                    <td>{{organization.name}}</td>
                                    <td>{{organization.type.name}}</td>
                                    <td>{{organization.heathFacilityType.name ?? 'N/A'}}</td>
                                    <td>{{organization.contactPerson}}</td>
                                    <td>{{organization.email}}</td>
                                    <!-- <td v-for="org in organization.heathFacilityType" :key="org.id">{{org.name}}</td> -->
                                    <td>{{organization.address}}</td>
                                    <td>
                                        <div class="row justify-space-between">
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                                                <a @click="navigateTO('/vieworganization/'+organization.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View</a>
                                                <a @click="navigateTO('/profiles/'+organization.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Profiles</a>
                                            <a @click="navigateTO('/users/'+organization.id)" class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View Identities</a>
                                            <a v-show="(hasRole('global.admin') || hasRole('organization.admin')) " @click="navigateTO('/createorganizationbranch/'+organization.id)" class="dropdown-item" href="#"><i class="ri-add-fill mr-2"></i>Add Branch</a>                                            
                                            <a  v-if="hasRole('global.admin')" @click="navigateTO('/editorganization/'+organization.id)" class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>
                                            <a  v-if="hasRole('global.admin')" @click="deleteOrganization(organization.id)" class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Delete</a>
                        
                                            
                                            </div>
                                        </div>
                                        <!-- <span></span>
                                        <button @click="deleteOrganization(organization.id)" class="btn-sm btn btn-danger ml-2">
                                            Delete
                                        </button> -->
                                        </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                  </div>
           </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import Swal from 'sweetalert2';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';
const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;

export default {
    data() {
        return {
            organizations: [],
            roles: [],
            isLoading: true, // Add loading state
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            role: 'auth/role',
            current_profile: 'auth/profile'
        }),
    },
    //methods go here
    methods: {


        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        navigateTO(location) {
            this.$router.push(location)
        },


        editOrganization() {
            axios.put('/organization/' + this.form.id)
                .then(() => {

                })
                .catch(() => {
                })
        },
        deleteOrganization(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    //send request to the server
                    delete axios.defaults.headers.common['X-CG-Authorization'];

                    axios.delete('/organizations/' + id, {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                        },
                    }).then(() => {
                        toast.fire(
                            'Deleted!',
                            'Organization has been deleted.',
                            'success'
                        )
                    }).catch(() => {
                        this.$Progress.fail();
                        Swal.fire(
                            'Failed!',
                            'There was something wrong.',
                            'warning'
                        )
                    });
                } else if (result.isDenied) {
                    //console.log('cancelled')
                }

            })
        },
    },
    components: {
        appComp
    },
    mounted() {

        this.getRoles();
        this.isLoading = true; // Set isLoading to true before making the API call
        delete axios.defaults.headers.common['X-CG-Authorization'];
        try {        
        axios.get('/organizations', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            this.organizations = response.data.data.items
            // console.log("orgs", this.organizations);

            setTimeout(() => {
                $("#orgTable").DataTable();
            }, 10);
            // Set isLoading to false once data is loaded
            this.isLoading = false;

        });
        } catch (error) {
            // console.error("Error fetching data:", error);
            this.isLoading = false; // Make sure isLoading is set to false in case of an error
        }

        //     axios.get("/organizations", {
        //        }).then((response) => {
        //           this.organizations = response.data.data.items

        //    });
    }

}
</script>
<style lang="">
    
</style>