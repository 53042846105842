

import Vuex from 'vuex'
// import createApp from 'vue'
import auth from "./auth"


export default new Vuex.Store({
    state: {

    },
    mutations: {
        setSelectedComments(state, comments) {
            state.selectedComments = comments;
          },
    },
    actions: {},
    modules: {
        auth
    }
})







// import axios from "axios";

// const options = {
//     method: 'GET',
//     url: 'https://api.sandbox.cugi.co.ke/lists/',
//     headers: {
//         'Accept-Charset': 'utf-8',
//         'X-CG-Date': 'Wed, 19 Apr 2023 12:24:22 GMT',
//         'X-CG-Authorization': 'Basic YW5vbnltb3VzLm1hbWFAYXBpLmN1Z2kuY28ua2U6QmFNKjkwQE5pVjFFVEhVMSpuem5sMDlvckFBZ0B2TyFOZDYk',
//         'X-CG-Version': 'CugiMama-20230318',
//         'Content-Type': 'application/json',
//         Accept: 'application/json'
//     },
//     data: { password: 'PhhX9Qr9ST@G8Kd7', username: 'root.mama@api.cugi.co.ke' }
// };

// axios.request(options).then(function (response) {
//     console.log(response.data);
// }).catch(function (error) {
//     console.error(error);
// });