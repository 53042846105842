<template lang="">
    <div>
        <appComp>
         <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-body p-0">
                           <div class="iq-edit-list">
                              <ul class="iq-edit-profile d-flex nav nav-pills">
                                 <li class="col-md-3 p-0">
                                    <a @click="setActiveTab(1)" class="nav-link" :class="{ 'active': activeTab === 1 }" data-toggle="pill" href="#personal-information">
                                    Pregnancy Information
                                    </a>
                                 </li>
                                 <li class="col-md-3 p-0">
                                    <a @click="setActiveTab(2)" class="nav-link" :class="{ 'active': activeTab === 2 }" data-toggle="pill" href="#chang-pwd">
                                    Mother Medicals
                                    </a>
                                 </li>
                                 <li class="col-md-3 p-0">
                                    <a @click="setActiveTab(3)" class="nav-link" :class="{ 'active': activeTab === 3 }" data-toggle="pill" href="#emailandsms">
                                    Father Medicals
                                    </a>
                                 </li>                                                                 
                                 <!-- <li class="col-md-3 p-0">
                                    <a @click="setActiveTab(4)" class="nav-link"  :class="{ 'active': activeTab === 4 }" data-toggle="pill" href="#success">
                                    Children
                                    </a>
                                 </li> -->
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <form @submit.prevent="">
                  <div class="col-lg-12">
                     <div class="iq-edit-list-data">
                        <div class="tab-content">
                           <div class="tab-pane fade " :class="{ 'active show': activeTab === 1 }" id="personal-information" role="tabpanel">
                              
                              <div class="iq-card">
                                 <div class="iq-card-header d-flex justify-content-between">
                                    <div class="iq-header-title">
                                       <h4 class="card-title">Pregnancy Information</h4>
                                    </div>
                                 </div>
                                 <div class="iq-card-body">
                                 <GeneralWizard @handle-submit="setActiveTab"/>

                                 
                                 </div>
                              </div>
                              
                           </div>
                           <div class="tab-pane fade"  :class="{ 'active show': activeTab === 2 }" id="chang-pwd" role="tabpanel">
                              <div class="iq-card">
                                 <div class="iq-card-header d-flex justify-content-between">
                                    <div class="iq-header-title">
                                       <h4 class="card-title">Mother Medicals</h4>
                                    </div>
                                 </div>
                                 <div class="iq-card-body">
                                    <!-- Mother wizard -->

                                    <MotherWizard @handle-submit="setActiveTab" />
                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane fade" :class="{ 'active show': activeTab === 3 }" id="emailandsms" role="tabpanel">
                              <div class="iq-card">
                                 <div class="iq-card-header d-flex justify-content-between">
                                    <div class="iq-header-title">
                                       <h4 class="card-title">Father Medicals</h4>
                                    </div>
                                 </div>
                                 <div class="iq-card-body">
                                   <!-- fathers wizard -->
                                    <FatherWizard  @handle-submit="setActiveTab"/>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
                  </form>
               </div>
            </div>
        </appComp>
    </div>
</template>
<script>
import appComp from '@/components/appComp';
import GeneralWizard from './wizards/GeneralWizard.vue';
import MotherWizard from './wizards/MotherWizard.vue';
import FatherWizard from './wizards/FatherWizard.vue';



export default {



   data() {
    return {
      activeTab: 1, 
    };
  },


components : {
 appComp, GeneralWizard, MotherWizard, FatherWizard
   
},

  methods: {
    setActiveTab(tabNumber) {
      // 
      this.activeTab = tabNumber;
    },
  },
  mounted() {
   this.activeTab = 1;
  }


}

</script>