<template lang="">
    <transition name="fade">
     <div>
         <appComp>
             <div class="row">
                   <!-- <div class="col-sm-12">
                      <div class="row">
                         <div class="col-md-6 col-lg-3">
                            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                               <div class="iq-card-body">
                                  <div class="iq-progress-bar progress-bar-vertical iq-bg-primary">
                                        <span class="bg-primary" data-percent="70"></span>
                                    </div>
                                  <span class="line-height-4">10 feb, 2020</span>
                                  <h4 class="mb-2 mt-2">Hypertensive Crisis</h4>
                                  <p class="mb-0 text-secondary line-height">Ongoing treatment</p>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-6 col-lg-3">
                            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                               <div class="iq-card-body">
                                  <div class="iq-progress-bar progress-bar-vertical iq-bg-danger">
                                        <span class="bg-danger" data-percent="50"></span>
                                    </div>
                                  <span class="line-height-4">12 Jan, 2020</span>
                                  <h4 class="mb-2 mt-2">Osteoporosis</h4>
                                  <p class="mb-0 text-secondary line-height">Incurable</p>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-6 col-lg-3">
                            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                               <div class="iq-card-body">
                                  <div class="iq-progress-bar progress-bar-vertical iq-bg-warning">
                                        <span class="bg-warning" data-percent="80"></span>
                                    </div>
                                  <span class="line-height-4">15 feb, 2020</span>
                                  <h4 class="mb-2 mt-2">Hypertensive Crisis</h4>
                                  <p class="mb-0 text-secondary line-height">Examination</p>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-6 col-lg-3">
                            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                               <div class="iq-card-body P-0 rounded" style="background: url(images/page-img/38.jpg) no-repeat scroll center center; background-size: contain; min-height: 146px;">
                               </div>
                            </div>
                         </div>
                      </div>
                   </div> -->
                   <div class="col-lg-4">
                      <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                         <div class="iq-card-body">
                            <div class="user-details-block">
                               <div class="user-profile text-center">
                                  <img src="@/assets/images/user/11.png" alt="profile-img" class="avatar-130 img-fluid">
                               </div>
                               <div class="text-center mt-3">
                                  <h4><b>{{profileInfo.displayName}}</b></h4>
                                  <p >currently signed in as </p>
                                  <a href="#" class="btn btn-primary">{{profileRole}}</a>
                                  <!-- <a href="#" class="btn btn-primary"></a> -->
                                  <hr>
                                  <ul
                                     class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
                                  >
                                     <!-- <li class="text-center">
                                        <a @click="navigateTO('/childvisits/' + childInfo.id + '?pregnancyId=' + (pregnancyId || ''))" class="btn btn-outline-success float-center text-success">Clinic Visits</a>
                                     </li> -->
                                     <!-- <li class="text-center">
                                        <button type="submit" @click="editProfile()" class="btn btn-primary float-center">Edit Profile</button>
                                     </li> -->
                                     <!-- <li class="text-center">
                                        <a @click="viewPreg()" class="btn btn-outline-danger float-center text-danger">Pregnancies</a>
                                     </li> -->
 
                                  </ul>
                                  <!-- <router-link to="/updateprofile/1/2/2"><a href="#" class="btn btn-primary"> Edit </a></router-link> -->
                               </div>
                               <hr>
                               <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0">
                                  <li class="text-center">
                                     <h3 class="counter">{{filteredpregnancies.length}}</h3>
                                     <span>Pregnancies</span>
                                   </li>
                                   <!-- <li class="text-center">
                                     <h3 class="counter">3.9</h3>
                                     <span>Profiles</span>
                                   </li> -->
                               </ul>
                            </div>
                         </div>
                      </div>
                   </div>
                   <!-- <div class="col-lg-8">
                      <div class="iq-card">
                         <div class="iq-card-header d-flex justify-content-between">
                         <div class="iq-header-title">
                            <h4 class="card-title">Profile Information</h4>
                         </div>
                         </div>
                         <div class="iq-card-body">
                         <div class="about-info m-0 p-0">
                            <div class="row">
                               <div class="col-12 row">
                               <div class="col-6 row">
                                  <div class="col-6">
                                     <h6 class="font-bold">Organization :</h6>
                                  </div>
                                  <div class="col-6 text-start">
                                     <p class="font-12">{{ profileOrganization ?? "N/A" }}</p>
                                  </div>
                               </div>
                               <div class="col-6 row">
                                  <div class="col-6">
                                     <h6 class="font-bold">Display Name :</h6>
                                  </div>
                                  <div class="col-6 text-start">
                                     <p class="font-12">{{ profileInfo.displayName ?? "N/A" }}</p>
                                  </div>
                               </div>
                               </div>
                               <div class="col-12 row">
                               <div class="col-6 row">
                                  <div class="col-6">
                                     <h6 class="font-bold">Phone :</h6>
                                  </div>
                                  <div class="col-6 text-start">
                                     <p class="font-12">{{ user.identity.msisdn ?? "N/A" }}</p>
                                  </div>
                               </div>
                               <div class="col-6 row">
                                  <div class="col-6">
                                     <h6 class="font-bold">Role :</h6>
                                  </div>
                                  <div class="col-6 text-start">
                                     <p class="font-12">{{ profileRole ?? "N/A" }}</p>
                                  </div>
                               </div>
                               </div>
 
                            </div>
                         </div>
                         </div>
                      </div>
                   </div>   -->
                   <div class="col-lg-8">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Organization Information</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="about-info m-0 p-0">
                              <div class="row">
                                 <div class="col-4">Name:</div>
                                 <div class="col-8">{{organization.name ?? "N/A"}}</div>
                                 <div class="col-4">Contact Person:</div>
                                 <div class="col-8">{{organization.contactPerson ?? "N/A"}}</div>
                                 <div class="col-4">Phone:</div>
                                 <div class="col-8">{{organization.msisdn ?? "N/A"}}</div>
                                 <div class="col-4">Email:</div>
                                 <div class="col-8"><a href="#"> {{organization.email ?? "N/A"}} </a></div>
                                 <div class="col-4">Country:</div>
                                 <div class="col-8"><a href="#">{{country ?? "N/A"}}</a></div>
                                 <div class="col-4">Location:</div>
                                 <div class="col-8"><a href="#">{{location ?? "N/A"}}</a></div>     
                                 <div class="col-4">Region:</div>
                                 <div class="col-8"><a href="#">{{region ?? "N/A"}}</a></div> 
                                 <div class="col-4">Town:</div>
                                 <div class="col-8"><a href="#">{{town ?? "N/A"}}</a></div> 
                                 <div v-if="healthfacilitytype !== null" class="col-4">Health Facility Type:</div>
                                 <div v-if="healthfacilitytype !== null" class="col-8"><a href="#">{{healthfacilitytype ?? "N/A"}}</a></div> 
                                 <div class="col-4">Status:</div>
                                 <div class="col-8"><a href="#">{{status ?? "N/A"}}</a></div>
                                 <div class="col-4">Type:</div>
                                 <div class="col-8"><a href="#">{{type ?? "N/A"}}</a></div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Profile Information</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="about-info m-0 p-0">
                              <div class="row">
                                 <div class="col-4">Organization:</div>
                                 <div class="col-8">{{ profileOrganization ?? "N/A" }}</div>
                                 <div class="col-4">Display Name:</div>
                                 <div class="col-8">{{ profileInfo.displayName ?? "N/A" }}</div>
                                 <div class="col-4">Phone:</div>
                                 <div class="col-8">{{ user.identity.msisdn ?? "N/A" }}</div>
                                 <div class="col-4">Role:</div>
                                 <div class="col-8"><a href="#"> {{ profileRole ?? "N/A" }} </a></div>
                              </div>
                           </div>
                        </div>
                     </div>
                   </div>                                  
            </div>
                  
            <div class="row">
                     <div class="col-lg-6">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-header d-flex justify-content-between">
                              <div class="iq-header-title">
                                 <h4 v-if="hasRole('organization.admin')" class="card-title">Organization Pregnancies </h4>
                                 <h4 v-if="hasRole('healthcare.facility.admin') || hasRole('healthcare.facility.user')" class="card-title">Health Facility Pregnancies </h4>
                              </div>
                              <div class="iq-card-header-toolbar d-flex align-items-center">
                                 <div class="dropdown">
                                    <span class="dropdown-toggle text-primary" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="ri-more-fill"></i>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                       <a class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View All</a>
                                       <!--<a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                                       <a class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>
                                       <a class="dropdown-item" href="#"><i class="ri-printer-fill mr-2"></i>Print</a>
                                       <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>-->
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!-- Start Custom Preloader -->
                           <div v-if="isLoading" class="text-center mt-2">
                              <div class="spinner-border text-primary" role="status">
                              </div>
                           </div>
                           <!-- End Custom Preloader -->
                           <div v-else class="iq-card-body">
                              <div class="table-responsive">
                                 <table id="pregTable" class="table mb-0 table-borderless">
                                    <thead>
                                       <tr>
                                          <th scope="col">EDD</th>
                                          <th scope="col">Registrant Type </th>
                                          <th scope="col">No. of Children</th>
                                          <th scope="col">Action</th>

                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                          <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                          <td>{{pregnancy.registrant.type['name']}}</td>
                                          <td>{{pregnancy.totalChildren}}</td>
                                          <td>
                                             <button @click="navigateTO('/viewpregnancy/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-header d-flex justify-content-between">
                              <div class="iq-header-title">
                                 <h4 class="card-title">Mother Appointments</h4>
                              </div>
                           </div>
                        <!-- Start Custom Preloader -->
                        <div v-if="isLoading" class="text-center mt-2">
                           <div class="spinner-border text-primary" role="status">
                           </div>
                        </div>
                        <!-- End Custom Preloader -->
                        <div v-else class="iq-card-body">
                           <div class="table-responsive">
                              <table id="appointTable"  class="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th scope="col">EDD</th>
                                       <th scope="col">No. of Visits</th>
                                       <th>Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                       <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                       <td>{{pregnancy.mother.medical.clinicalVisits.length}}</td>
                                       <td>
                                          <button @click="navigateTO('/viewMotherClinicalVisits/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>   
                        </div>
                     </div>
            </div>

            <div class="row">
               <div class="col-lg-6">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h4 class="card-title">Children </h4>
                        </div>
                        <div class="iq-card-header-toolbar d-flex align-items-center">
                           <div class="dropdown">
                              <span class="dropdown-toggle text-primary" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="ri-more-fill"></i>
                              </span>
                              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                 <a class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View All</a>
                                 <!--<a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                                 <a class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>
                                 <a class="dropdown-item" href="#"><i class="ri-printer-fill mr-2"></i>Print</a>
                                 <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>-->
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- Start Custom Preloader -->
                     <div v-if="isLoading" class="text-center mt-2">
                        <div class="spinner-border text-primary" role="status">
                        </div>
                     </div>
                     <!-- End Custom Preloader -->
                     <div v-else class="iq-card-body">
                        <div class="table-responsive">
                           <table id="childrenTable" class="table mb-0 table-borderless">
                              <thead>
                                 <tr>
                                    <th scope="col">EDD</th>
                                    <th scope="col">Registrant Type </th>
                                    <th scope="col">No. of Children</th>
                                    <th scope="col">Action</th>

                                 </tr>
                              </thead>
                              <tbody>
                                 <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                    <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                    <td>{{pregnancy.registrant.type['name']}}</td>
                                    <td>{{pregnancy.totalChildren}}</td>
                                    <td>
                                       <button @click="navigateTO('/childrenList/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h4 class="card-title">Children Appointments</h4>
                        </div>
                     </div>
                  <!-- Start Custom Preloader -->
                  <div v-if="isLoading" class="text-center mt-2">
                     <div class="spinner-border text-primary" role="status">
                     </div>
                  </div>
                  <!-- End Custom Preloader -->
                  <div v-else class="iq-card-body">
                     <div class="table-responsive">
                        <table id="childAppointTable"  class="table mb-0 table-borderless">
                           <thead>
                              <tr>
                                 <th scope="col">EDD</th>
                                 <th scope="col">No. of Children</th>
                                 <th>Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="pregnancy in filteredpregnancies" :key="pregnancy.id">
                                 <td>{{pregnancy.expectedDeliveryDate ?? "N/A"}}</td>
                                 <td>{{pregnancy.totalChildren}}</td>
                                 <td>
                                    <button @click="navigateTO('/viewChildrenClinicalVisits/'+pregnancy.id)"  type="button" class="btn btn-danger" name="button">More </button>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>   
                  </div>
               </div>
            </div>

         </appComp>
     </div>
    </transition>
 </template>
 <script>
 import { mapActions } from 'vuex';
 import appComp from '@/components/appComp';
 import axios from 'axios';
 import "jquery/dist/jquery.min.js";
 import "datatables.net-dt/js/dataTables.dataTables";
 import "datatables.net-dt/css/jquery.dataTables.min.css";
 import $ from "jquery";
 import { mapGetters } from 'vuex';
 
 axios.defaults.baseURL = "https://api.sandbox.cugi.co.ke";
 
 axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
 
 export default {
    data() {
       return {
         profileId: [],
         orgId: [],
         profileInfo: [],
         profileOrganization: [],
         profileRoles: [],
         profileRole: [],
         filteredpregnancies: [],
         pregnancies: [],
         roles: [],
         organization: [],
         role: localStorage.getItem("role"),
         isLoading: true, // Add loading state
       }
    },
    components: {
       appComp
    },
    methods: {
      hasRole(roleName) {
         return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
      },
      getRoles() {
         // this.roles = this.user.profiles[this.current_profile].organization.roles;
         this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
      },      
       ...mapActions({
          signIn: 'auth/signIn'
       }),
       editProfile(){
          this.$router.push('/updateprofile/1/2/2"')
       },
       viewPreg(){
          this.$router.push('/profilepregnancies')
       },
       navigateTO(location) {
         this.$router.push(location)
       },
       getData() {
          // console.log(axios);
          this.isLoading = true; // Set isLoading to true before making the API call

          delete axios.defaults.headers.common['X-CG-Authorization'];
          axios.get('/pregnancies', {
                headers: {
                   'Authorization': 'Bearer ' + localStorage.getItem('token'),
                   'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
                },
          }).then((response) => {
                this.pregnancies = response.data.data.items
                this.filteredpregnancies = this.pregnancies.filter(item => item.registrant.organization.id === this.orgId);
                // console.log(this.pregnancies)

                  setTimeout(() => {
                     $("#pregTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#orgTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#appointTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#childAppointTable").DataTable();
                  }, 10);
                  setTimeout(() => {
                     $("#childrenTable").DataTable();
                  }, 10);                
               // Set isLoading to false once data is loaded
               this.isLoading = false;

               //  console.log("data", this.pregnancies);
          });
       },
       getOrg() {
            delete axios.defaults.headers.common["Authorization"];
            this.orgId = this.user.profiles[this.current_profile].organization.id;

            axios
                .get(
                    "/organizations/" + this.orgId,
                    {
                        headers: {
                            "X-CG-Authorization": `Bearer ${localStorage.getItem("token")}`,
                            "X-CG-Date": new Date(Date.now() - 50000).toGMTString(),
                        },
                    },
                    {}
                )
                .then((response) => {
                    this.organizationId = response.data.data.items[0].id;
                    this.organization = response.data.data.items[0];
                    this.country = response.data.data.items[0].country.name;
                    this.region = response.data.data.items[0].region.name;
                    this.town = response.data.data.items[0].town.name;
                    this.type = response.data.data.items[0].type.name;
                    this.status = response.data.data.items[0].status.name;
                    this.healthfacilitytype = response.data.data.items[0].heathFacilityType.name;
                    // console.log(response);
                    //   this.organization = JSON.parse(JSON.stringify(this.organization))
                    // console.log("data", this.organization);
                });
                
       },       
 
 
    },
    computed: {
       ...mapGetters({
          user: 'auth/user',
          current_role: "auth/role",
          current_profile: 'auth/profile'
       }),
       filteredPregnancies() {
         return this.pregnancies.filter(item => item.registrant.organization.id === this.orgId);
      },
    },
    mounted() {
       this.getData();
       this.getOrg();
       this.getRoles();
       this.profileId = this.user.profiles[this.current_profile].id;
       this.orgId = this.user.profiles[this.current_profile].organization.id;
       this.profileInfo = this.user.profiles[this.current_profile];
       this.profileOrganization = this.user.profiles[this.current_profile].organization.name;
       this.profileRoles = this.user.profiles[this.current_profile].organization.roles;
       this.profileRole = this.profileRoles[0].name
       this.profileId = this.user.profiles[this.current_profile].id;
       // console.log(this.orgId)

    },
 
 
 
 }
 </script>
 <style lang="">
     
 </style>