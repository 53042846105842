<template lang="">
    <div>
        
        <appComp>
            <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#newPaymentModal"><i class="ri-add-fill"></i> New Payment</button>
            </div>
             <!-- Start Custom Preloader -->
            <div v-if="isLoading" class="text-center">
                <div class="spinner-border text-primary" role="status">
                </div>
            </div>
            <div v-else>
            <!-- cards -->
            <div class="col-lg-12">
                  <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <div class="iq-card">
                           <div class="iq-card-body iq-bg-warning rounded-4">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon bg-warning"><i class="ri-user-fill"></i></div>
                                 <div class="text-end">
                                    <h4 class="mb-0">Wallet ID: {{ walletId ?? '-'}}</h4>
                                    <h5 class="">{{ walletName }}</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card">
                           <div class="iq-card-body iq-bg-primary rounded-4">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon bg-primary"><i class="ri-calendar-fill"></i></div>
                                 <div class="text-end">
                                    <h4 class="mb-0">{{ updatedOn ?? '-'}}</h4>
                                    <h5 class="">Last Updated On</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card">
                           <div class="iq-card-body iq-bg-info rounded-4">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon bg-info"><i class="ri-clipboard-fill"></i></div>
                                 <div class="text-end">
                                    <h4 class="mb-0">{{ transactions ?? 0}} Transactions</h4>
                                    <h5 class="">Total Count</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                    </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card">
                           <div class="iq-card-body iq-bg-danger rounded-4">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon bg-danger"><i class="ri-currency-fill"></i></div>
                                 <div class="text-end">
                                    <h2 class="mb-0">Ksh. <span class="counter">{{ balance ?? 0}}</span></h2>
                                    <h5 class="">Total Balance (KES)</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card">
                           <div class="iq-card-body iq-bg-success rounded-4">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon bg-success"><i class="ri-wallet-3-fill"></i></div>
                                 <div class="text-end">
                                    <h2 class="mb-0">Ksh. <span class="counter">{{ credits ?? 0}}</span></h2>
                                    <h5 class="">Total Credited (KES)</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card">
                           <div class="iq-card-body iq-bg-warning rounded-4">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon bg-warning"><i class="ri-bank-card-fill"></i></div>
                                 <div class="text-end">
                                    <h2 class="mb-0">Ksh. <span class="counter">{{ debits ?? 0}}</span></h2>
                                    <h5 class="">Total Debited (KES)</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                    
                     
                    
                  </div>
               </div>
               <!-- end cards -->
                  <div class="iq-card col-lg-12">
                    <div class="iq-card-body">
                           <p>Payments List</p>
                           <table  id="childrenTable" class="table table-hover">
                              <thead>
                                 <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Counter Party</th>
                                    <th scope="col">Credit(KES)</th>
                                    <th scope="col">Debit(KES)</th>
                                    <th scope="col">Gateway</th>                                   
                                    <th>Date</th>
                                 </tr>
                              </thead>
                              <tbody>

                                <tr v-for="payment in payments" :key="payment.id" >
                                    <th scope="row">{{payment?.type?.name ?? '-'}}</th>
                                    <td>{{payment?.transaction?.counterparty ?? '-'}}</td>
                                    <td>Ksh. {{payment?.transaction?.creditAmount ?? '-'}}</td>
                                    <td>Ksh. {{payment?.transaction?.debitAmount ?? '-'}}</td>
                                    <td>{{payment?.transaction?.type?.name ?? '-'}}</td>
                                    <td>{{payment?.transaction?.date ?? '-'}}</td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                  </div>
                </div> 
        </appComp>
        <AdminPaymentModal />
    </div>
</template>



<script>
import appComp from '@/components/appComp';
import axios from 'axios';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { mapGetters } from 'vuex';
import AdminPaymentModal from "@/components/reusable/AdminPaymentModal.vue";

export default {
    data() {
        return {
            children: [],
            roles: [],
            profileLoaded: false,
            isWalletLoaded: false,
            credits: 0,
            debits: 0,
            balance: 0,
            updatedOn: null,
            transactions: 0,
            payments: [],
            walletId: null,
            walletType: null,
            walletName: null,
            isLoading: true, // Add loading state
            pregnancyId: null, // Initialize pregnancyId in the data
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            role: 'auth/role',
            current_profile: 'auth/profile'
        }),
    },
    //methods go here
    methods: {
      getData()
      {

        this.pregnancyId = this.$route.params.id;

        // console.log("Monyancha Children", this.children)
        this.isLoading = true; // Set isLoading to true before making the API call

        delete axios.defaults.headers.common['X-CG-Authorization'];
        //  get organizations
        try {
         axios.get('/pregnancies/' + this.pregnancyId , {
            headers : {
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            this.children = response.data.data?.items[0].children
            setTimeout(() => {
                $("#childrenTable").DataTable();
            }, 10);
            // Set isLoading to false once data is loaded
            this.isLoading = false;

            // console.log("Monyancha Children", this.children)
        });
        } catch (error) {
            // console.error("Error fetching data:", error);
            this.isLoading = false; // Make sure isLoading is set to false in case of an error
        }
      },

      //Get Transactions
      getTransactions() {
        this.isLoading = true;
         if (!this.isWalletLoaded) {
            // Wait for wallet data to be loaded before calling this method
            setTimeout(() => {
                this.getTransactions();
            }, 100);
            return;
        }

          delete axios.defaults.headers.common['X-CG-Authorization'];
          // / get profiles
          axios.get('/wallets/' + this.walletId + '/payments', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
          }).then((response) => {
            // console.log("wallet payments monyancha 1", response)
            const transactions = response.data.data.items
            // console.log("wallet payments monyancha 2", transactions)

            //Load the stats
            this.transactions = transactions?.length;
            this.payments = transactions;
            this.isLoading = false;

          });
      },


      //Get Wallets
      getWallet() {
        this.isLoading = true;
         if (!this.isWalletLoaded) {
            // Wait for wallet data to be loaded before calling this method
            setTimeout(() => {
                this.getWallet();
            }, 100);
            return;
        }

          delete axios.defaults.headers.common['X-CG-Authorization'];
          // / get profiles
          axios.get('/wallets/' + this.walletId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
          }).then((response) => {
            // console.log("wallet monyancha 1", response)
            const wallet = response.data.data
            // console.log("wallet monyancha 2", wallet)

            //Load the stats
            this.debits = wallet?.debits;
            this.credits = wallet?.credits;
            this.balance = wallet?.balance?.amount;
            this.updatedOn = wallet?.balance?.date;
            this.walletName = wallet?.name;
            this.walletType = wallet?.type?.name;
            this.isLoading = false;
          });
      },


      getProfiles() {
        this.isLoading = true;
          delete axios.defaults.headers.common['X-CG-Authorization'];
          // / get profiles
          axios.get('/organizations/' + 1 + '/profiles', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
          }).then((response) => {
            // console.log("profiles monyancha", response)
            const profiles = response.data.data.items
            // console.log("profiles monyancha 222", profiles)
            //Check for profile id from local storage
            const profileId = localStorage.getItem("profileId");
            const profile = profiles.find(p => p.id == profileId);

            localStorage.setItem("userProfile", JSON.stringify(profile))
            localStorage.setItem("walletId", profile?.wallet?.id)

            this.walletId = profile?.wallet?.id;
            this.isWalletLoaded = true; // Set the flag when wallet data is loaded

            this.profileLoaded = true;
            this.isLoading = false;
          });
      },

        hasRole(roleName) {
            return this.roles.some((role) => role.id.split('.role.')[1] === roleName);
        },
        getRoles() {
            // this.roles = this.user.profiles[this.current_profile].organization.roles;
            this.roles = this.user.profiles[this.current_profile]?.organization?.roles ?? [];
        },
        navigateTO(location) {
            this.$router.push(location)
        },

    },
    components: {
        appComp,
        AdminPaymentModal
    },
    mounted() {
        this.getData();
        this.getRoles();
        this.getProfiles();
        this.getWallet();
        this.getTransactions();
        delete axios.defaults.headers.common['X-CG-Authorization'];
        axios.get('/organizations', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-CG-Date': new Date(Date.now() - 50000).toGMTString(),
            },
        }).then((response) => {
            this.organizations = response.data.data.items
            setTimeout(() => {
                $("#orgTable").DataTable();
            }, 10);
        });
        // console.log(this.organizations);

    }

}
</script>

